import React, { Component } from 'react'
import config from '../config'
import axios from 'axios';
import '../css/CourseClassify.css'

export class CourseClassify2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            number: "",
            chapters: null,
        }
    }

    componentDidMount() {
        var _number = window.U.UF.C.queryString("number") || '';
        var _this = this;
        if (_number) {
            this.setState({
                number: _number
            })
            _this.getCourse(_number)
        }
    }
    getCourse = (number) => {
        axios.get(`${config.env.edu}getUnitByNumber/${number}`).then(res => {
            console.log(res)
            let _courseinfo = res.data[0]
            setTimeout(() => {
                this.setState({
                    chapters: _courseinfo,//课程名称
                })
            }, 0);
        }).catch(err => console.log(err));
    }

    gotoTutor(number) {
        window.location.href = `${config.env.basename}/courseManage?number=${number}`
        // window.open("/courseManage?number=" + r.value[1][n].number);
    }

    render() {
        const { chapters } = this.state
        return (
            <div className="cClass_body">
                <div className="cClass_title"><img src={require("../assets/img/Eraser__Curriculum.svg")} /><span>请选择要查看的单元</span></div>
                <div className="cClass_content">
                    {chapters && chapters.map((v, i) => (
                        <div className="cClass_box" key={i} onClick={() => { this.gotoTutor(v.number)}}>
                            {/* <div className="cClass_title"><img src={v.poster} /><span>{v.title}</span></div>
                        <div className="cClass_content">
                           
                        </div> */}
                            <div className="cClass_img"><img src={v.poster} /></div>
                            <div className="cClass_zi"><span>{`第${i+1}单元：${v.title}`}</span></div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default CourseClassify2
