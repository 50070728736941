import React from 'react'
import styled from 'styled-components'
//image
import success from '../assets/img/Result__success2.svg'
//material-ui
import { Button } from '@material-ui/core'
//store
import { connect } from 'react-redux'
//config
import config from '../config'

import {
  setStep,
} from '../actions/newupload';

const Wrapper = styled.div`
  display: grid;
  grid-area: ${props => props.areaname};
  grid-template-rows:100px 100px 50px 100px 50px;
  grid-template-areas:
  '. . . .'
  'icon icon icon icon'
  'title title title title'
  'content content content content'
  '. previewBtn previewBtn .';
  grid-gap:20px;
`;
const Icon = styled.img`
  width:150px;
  height:150px;
  place-self:center;
  grid-area:icon;
`;
const Title = styled.h1`
  grid-area:title;
  place-self:center;
`;
const Content = styled.p`
  font-size:24px;
  grid-area:content;
  text-align:center;
`;
const Highlight = styled.span`
  text-decoration:underline;
  color:#2a97ff;
`;

const mapStateToProps = (state) => {
  return {
    courseId: state.newupload.course.courseId
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    handleSet(activeStep) {
      dispatch(setStep(activeStep))
    }
  }
}

class Result extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    props.handleSet(3)
  }
  componentDidMount = () => {

    document.title = "课程上传成功"
  }
  redirectToCourse = () => {
    window.location.href = `${config.env.basename}/course/${this.props.match.params.courseId}`
  }
  render() {
    let courseId = this.props.match.params.courseId
    const { language } = this.props
    return (
      <Wrapper>
        <Icon src={success} width='100%' height='100%' />
        <Title>{language.upload_success}</Title>
        <Content>{language.course_number}<br />
          <Highlight>{courseId}</Highlight>
        </Content>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={this.redirectToCourse}
          css={`
            grid-area:previewBtn;
            width:30%;
            place-self:center;
            `}
        >
          {language.preview_course}
        </Button>
      </Wrapper>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Result);
