import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components'
import { connect } from 'react-redux';
import {
    nextStep,
    lastStep
  } from '../actions/upload';

const mapStateToProps = (state) =>{
    return{
      ...state.upload,
      activeStep: state.steppers.activeStep,
    }
  }

  const mapDispatchToProps = (dispatch)=>{
    return{
      handleNext(activeStep){
        dispatch(nextStep(activeStep))
      },
      handleBack(activeStep){
        dispatch(lastStep(activeStep))
      },
    }
  }

const Wrapper = styled.div`
    grid-area: stepper;
    width:100%;
`;

const Styles = makeStyles(theme => ({
    root: {
        width: '60%'
    },
    backButton: {
        marginRight: theme.spacing * 2,
    },
    instructions: {
        marginTop: theme.spacing *1,
        marginBottom: theme.spacing * 1,
    },
}));
class Newsteppers extends React.Component {
    state = {
    };

    render() {
        const {steps} = this.props;
        return (
            <Wrapper>
            <div className={Styles.root}>
                <Stepper activeStep={this.props.activeStep} alternativeLabel>
                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {this.props.activeStep === steps.length ? (
                        <div>
                            <Typography className={Styles.instructions}>全部步骤完成,课程上传成功</Typography>
                        </div>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                </div>
            </div>
            </Wrapper>
        );
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(Newsteppers);