import React from 'react'
import styled from 'styled-components'
import { Button, Modal } from '@material-ui/core'
import CoverUploadPreview from './CoverUploadPreview'
import DemoUploadPreview from './DemoUploadPreview'

import Subtitle from './Subtitle'
import TitleInput from './TitleInput';
import IntroInput from './IntroInput';
//router
import { Redirect } from 'react-router-dom'
import axios from 'axios'
//config
import config from '../config'
//redux
import { connect } from 'react-redux';
//actions
import {
  setStep,
  updateCourseInfo,
  updateCourseCover,
  updateCourseDemo,
  updateChapters,
  updateCoursenumber
} from '../actions/newupload';


const Wrapper = styled.div`
  text-align:left;
  margin-top:50px;
  width:80%;
  justify-self:center;
  display:grid;
  grid-template-columns: 10% 39% 2% 39% 10%;
  grid-template-rows:50px 300px 160px 60px 100px;
  grid-template-areas:
  '. title title . .'
  '. content content content .'
  '. coverUpload . demoUpload .'
  '. divider divider divider .'
  '. home . nextStep .';
  @media screen and (max-width:768px){
    grid-template-rows:50px 200px 200px 200px 80px 50px;
    grid-template-areas:
    'title . . .'
    '. content content .'
    '. coverUpload coverUpload .'
    '. covertitle postertitle .'    
    '. demoUpload demoUpload .'
    '. home nextStep .';
  }
`;
const Divider = styled.hr`
  align-self: center;
  grid-area:divider;
  height: 1px;
  border: none;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0;
`;

const title = `font-size:16px;font-weight:bold;text-align:left;margin-top: 20px;margin-bottom:10px;`
const titles = `font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128)`

const mapStateToProps = (state) => {
  return {
    activeStep: state.steppers.activeStep,
    title: state.newupload.course.title,
    brief: state.newupload.course.brief,
    cover: state.newupload.course.cover,
    demo: state.newupload.course.demo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleSet(activeStep) {
      dispatch(setStep(activeStep))
    },
    updateCourseInfo(title, brief) {
      dispatch(updateCourseInfo(title, brief))
    },
    updateCourseCover(cover) {
      dispatch(updateCourseCover(cover))
    },
    updateCourseDemo(demo) {
      dispatch(updateCourseDemo(demo))
    },
    updateChapters(chapters) {
      dispatch(updateChapters(chapters))
    },
    updateCoursenumber(num) {
      dispatch(updateCoursenumber(num))
    }
  }
}

class CourseInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
      brief: props.brief,
      cover: props.cover,
      demo: props.demo,
      titleShow: false,
      briefShow: false,
      loading: true
    };
    props.handleSet(0)
  }

  componentDidMount() {
    var _number = window.U.UF.C.queryString("number") || '';
    window.localStorage['number'] = JSON.stringify(_number)
    if (_number) {
      axios.get(`${config.env.edu}course/${_number}`).then(res => {
        if (res.data[0] && res.data[0][0]) {
          window.localStorage['uploadid'] = res.data[0][0].courseId;
          var _courseinfo = res.data[0][0];
          this.props.updateCourseInfo(_courseinfo.title, _courseinfo.brief);
          let _poster = {
            title: decodeURIComponent(_courseinfo.poster.substr(_courseinfo.poster.lastIndexOf("/")).slice(1)),
            url: _courseinfo.poster,
            style: { backgroundPosition: '-5px -131px' }
          }

          let _dome = {
            title: decodeURIComponent(_courseinfo.demo.substr(_courseinfo.demo.lastIndexOf("/")).slice(1)),
            url: _courseinfo.demo,
            style: { backgroundPosition: '-5px -131px' }
          }
          let arr = JSON.parse(_courseinfo.chapters).map((x, i) => {
            // 背景图
            x.student.bg = [{
              url: x.student.bg,
              title: decodeURIComponent(x.student.bg.substr(x.student.bg.lastIndexOf("/")).slice(1)),
              style: { backgroundPosition: '-5px -131px' }
            }]
            x.teacher.bg = [{
              url: x.teacher.bg,
              title: decodeURIComponent(x.teacher.bg.substr(x.teacher.bg.lastIndexOf("/")).slice(1)),
              style: { backgroundPosition: '-5px -131px' }
            }]
            // pdf文件
            x.student.slideUrl = x.student.slideUrl.map(v => {
              let obj = {
                url: v,
                title: decodeURIComponent(v.substr(v.lastIndexOf("/")).slice(1)),
                style: { backgroundPosition: '-5px -131px' }
              }
              return obj;
            })
            x.teacher.slideUrl = x.teacher.slideUrl.map(v => {
              let obj = {
                url: v,
                title: decodeURIComponent(v.substr(v.lastIndexOf("/")).slice(1)),
                style: { backgroundPosition: '-5px -131px' }
              }
              return obj;
            })
            // 视频
            x.student.clipUrl = x.student.clipUrl.map(v => {
              let obj = {
                url: v.title ? v.url : v,
                title: v.title || decodeURIComponent(v.substr(v.lastIndexOf("/")).slice(1)),
                style: { backgroundPosition: '-5px -131px' }
              }
              return obj;
            })
            x.teacher.clipUrl = x.teacher.clipUrl.map(v => {
              let obj = {
                url: v.url,
                title: v.title || decodeURIComponent(v.substr(v.lastIndexOf("/")).slice(1)),
                style: { backgroundPosition: '-5px -131px' }
              }
              return obj;
            })
            // 附件
            x.student.attachmentUrl = x.student.attachmentUrl.map(v => {
              let obj = {
                url: v.url,
                title: v.title,
                style: { backgroundPosition: '-5px -131px' }
              }
              return obj;
            })
            x.teacher.attachmentUrl = x.teacher.attachmentUrl.map(v => {
              let obj = {
                url: v.url,
                title: v.title,
                style: { backgroundPosition: '-5px -131px' }
              }
              return obj;
            })
            x.id = i;
            return x;
          })
          this.props.updateCourseCover([_poster]);
          if (_courseinfo.demo) {
            this.props.updateCourseDemo([_dome]);
          }
          this.props.updateChapters(arr);
          this.props.updateCoursenumber(_courseinfo.number)
          this.setState({
            brief: _courseinfo.brief,
            title: _courseinfo.title,
            cover: [_poster],
            demo: [_dome],
            loading: false
          })
        }
        this.preread()
        this.props.handleSet(0)
      }).catch(err => console.log(err));
    }
    else {
      this.preread()
      this.props.handleSet(0)
      this.setState({
        loading: false
      })
    }
  }

  preread = () => {
    this.coursebrief.preread(this.props.brief);
    this.coursetitle.preread(this.props.title);
  }

  getCoverVal = (val) => {
    this.setState({
      cover: val.length !== 0 ? val : this.state.cover
    })
  }
  getDemoVal = (val) => {
    this.setState({
      demo: val.length !== 0 ? val : this.state.demo
    })
  }
  getTitleVal = (val) => {
    // if (val.length > 20) {
    //   this.setState({
    //     titleShow: true
    //   })
    // } else {
    this.setState({
      title: val,
      titleShow: false
    })
    // }
  }
  getIntroVal = (val) => {
    if (val.length > 1000) {
      this.setState({
        briefShow: true
      })
    } else {
      this.setState({
        brief: val,
        briefShow: false
      })
    }
  }
  saveCourseInfo = () => {
    let { title, brief, cover, demo } = this.state;
    if (title && brief && cover.length > 0) { // && demo.length > 0
      this.props.updateCourseInfo(title, brief)
      this.props.updateCourseCover(cover)
      this.props.updateCourseDemo(demo)
      let obj = {
        title, brief, cover, demo
      }
      window.localStorage['obj'] = JSON.stringify(obj)
      this.props.history.push('/upload/chapter/name')
    } else {
      window.alert(this.props.language.complete_information)
    }
  }
  render() {
    const { briefShow, titleShow } = this.state
    const { language } = this.props
    return (
      <Wrapper>
        <Subtitle
          areaname='title'
          name={language.basic_information}
        />
        <div css={`grid-area:content;`}>
          <div>
            <div css={title}><span css={`color:red`}>*</span>{language.course_name}: </div>
            <TitleInput
              ref={(coursetitle) => { this.coursetitle = coursetitle; }}
              text={language.course_name}
              title={language.course_name}
              getTitleVal={v => this.getTitleVal(v)}
            />
          </div>
          <div>
            <div css={title}><span css={`color:red`}>*</span>{language.course_describe}: {briefShow ? <span style={{ fontSize: 12, float: 'right', color: 'red' }}>{language.course_describe_prompt}
            </span> : <span css={titles}>{language.course_describe_prompt}</span>}</div>
            <IntroInput
              ref={(coursebrief) => { this.coursebrief = coursebrief; }}
              intro={language.course_describe_prompt}
              rows="4"
              introId='course__intro'
              getIntroVal={v => this.getIntroVal(v)}
            />
          </div>
        </div>
        <div css={`grid-area:coverUpload`}>
          <div css={`height:33px`}>
            <div css={`font-size:16px;font-weight:bold;float:left;`}><span css={`color:red`}>*</span>{language.course_cover}:</div>
            <div css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128)`}>
              <p>PNG, GIF, JPEG</p>
            </div>
          </div>
          <CoverUploadPreview
            ref={(coursecover) => { this.coursecover = coursecover; }}
            getCoverVal={this.getCoverVal}
            language={language}
          />
        </div>
        <div css={`grid-area:demoUpload`}>
          <div css={`height:33px`}>
            {/* <span css={`color:red`}>*</span> */}
            <div css={`font-size:16px;font-weight:bold;float:left;`}>{language.course_preview_video}:</div>
            <div css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128)`}>
              <p>MP4, M4V, MOV, AVI</p>
            </div>
          </div>
          <DemoUploadPreview
            ref={(coursedemo) => { this.coursedemo = coursedemo; }}
            getDemoVal={this.getDemoVal}
            language={language}
          />
        </div>
        <Divider></Divider>
        <Button
          variant="contained"
          color="primary"
          size="large"
          css={`
            // width:20%;
            grid-area:home;
            place-self:center;
            `}
          onClick={() => {
            this.props.history.push('/')
          }}
        >
          {language.return_home}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          css={`
            // width:20%;
            grid-area:nextStep;
            place-self:center;
            `}
          onClick={this.saveCourseInfo}
        >
          {language.next}
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.loading}
          onClose={() => {
            this.setState({
              loading: true
            })
          }}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
            <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{language.loadings}</p>
          </div>
        </Modal>
      </Wrapper>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseInfo);
