import React, { Component } from 'react'
import axios from 'axios';
import config from '../config'
import '../css/studentClass.css'
import { TablePagination, Modal, Fade } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

export class studentClass extends Component {
    state = {
        userId: '',
        classname: '',
        rows: [
        ],  // 班级数据
        rowsPerPage: 10,
        page: 0,
        addClassModal: false,
        search: "", //查询
        count: 0,  // 查询的总数量
    }

    componentDidMount() {
        console.log(this.props)
        this.getData()
    }

    getData = () => {
        // http://api.edu.cocorobo.cn/edu/profile/select_userstudycourse/a77e9404-efec-11e9-96f9-028edca3b798/1/12/
        const { page } = this.state
        const { userId } = this.props
        axios.defaults.withCredentials = true;
        axios.get(`${config.env.edu}profile/select_userstudycourse/${userId}/${page + 1}/12`).then(res => {
            console.log(res.data[0]);
            var resA = res.data[0]
            setTimeout(() => {
                this.setState({
                    rows: resA,
                    count: resA[0] ? resA[0].num : 0,
                })
            }, 0);
        })
    }


    pageOnChange = (e, page) => {
        console.log(page)
        this.setState({
            page: page
        }, () => {
            this.getData();
        })

    }

    gotoCourse = (n) =>{
        window.open("/course/" + n);
    }


    render() {
        const { rows, page, addClassModal, classname, rowsPerPage, count } = this.state;

        return (
            <div className="AddStudent">
                <div className="pb_head student">
                    课程管理<span className="hp_My">&nbsp;&nbsp;Curriculum</span>
                    <p>{count}个课程</p>
                </div>
                <div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {rows && rows.map((v, i) => (
                            <div className="sc_panel">
                                <div className="sc_panel_img"><img src={v.poster} /></div>
                                <div className="sc_panel_name" title={v.title}>{v.title}</div>
                                <div className="sc_panel_name">编号:{v.number}</div>
                                <div className="sc_panel_name" style={{ color: "#a5a5a5" }}>{v.time}</div>
                                <div className="sc_panel_btn">
                                    <div onClick={() => { this.gotoCourse(v.number)}}>前往</div>
                                </div>
                            </div>
                        ))}
                        {rows.length == 0 && <div style={{ textAlign: 'center',padding:50,width:"100%" }}>
                            <img src={require('../assets/img/manage/null.png')} />
                        </div>}
                    </div>
                    <div>{count > 0 ? <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        rowsPerPage={12}
                        rowsPerPageOptions={[12]}
                        component="div"
                        onChangePage={this.pageOnChange}
                    /> : ""}</div>
                </div>
            </div>
        )
    }
}


export default studentClass
