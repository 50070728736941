import React, { Component } from 'react'
import axios from 'axios';
import config from '../config'
import '../css/classManage.css'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Modal, Table, Paper, TableHead, TableRow, TableBody, TableCell, TablePagination, Fade } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F4F6",
        color: '#000',
        fontSize: 18,
        fontWeight: "600"
    },
    body: {
        fontSize: 18,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: "#f1f1f1",
        },
    },
}))(TableRow);

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}


const tableStyle = {
    border: '1px solid #eee'
}
const table = {
    minWidth: 700,
    padding: "0 30px"
}
export class classManage extends Component {
    state = {
        userId: '',
        classname: '',
        rows: [
        ],  // 班级数据
        rowsPerPage: 10,
        page: 0,
        addClassModal: false,
        search: "", //查询
        count: 0,  // 查询的总数量
    }

    // 添加学生
    addClass = () => {
        this.setState({
            addClassModal: true
        })
    }

    componentDidMount() {
        console.log(this.props)
        this.getData()
    }

    getData = () => {
        var userid = this.props.userId;
        var content = this.state.search ? this.state.search : " ";
        axios.get(`${config.env.edu}selectClass/${userid}/${content}/${this.state.page  + 1}/${this.state.rowsPerPage}`).then(res => {
            console.log(res)
            if (res.data[0].length > 0) {
                this.setState({
                    rows: res.data[0],
                    count: res.data[0][0].num
                })
            }
        })
    }

    // 删除
    del = (e, id) => {
        var cid = id;
        var _this = this
        window.U.UF.UI.confirm("确定删除此班级吗?", function () {
            axios.get(`${config.env.edu}delClass/${cid}/`).then(v => {
                if (v.data === 1) {
                    _this.getData()
                    window.U.Alert('删除成功')
                } else {
                    window.U.Alert('删除失败')
                }
            })
        }, function () {
            return;
        })
    }

    // 提交
    submit = () => {
        var userid = this.props.userId;
        var content = this.state.classname;
        axios.get(`${config.env.edu}addClass/${content}/${userid}/`).then(res => {
            if (res.status === 200) {
                this.setState({
                    addClassModal: false
                }, () => {
                    this.getData()
                })
                window.U.Alert('添加成功')
                this.state.classname = "";
            } else {
                window.U.Alert('添加失败')
            }
        }).catch(err => {
            window.U.Alert('添加失败')
        })
    }

    handleChangePage = (event, newPage) => {
        console.log(newPage)
        this.setState({
            page: newPage
        }, () => {
            this.getData()
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: this.state.page,
            rowsPerPage: +event.target.value
        }, () => {
            this.getData()
        })
    }

    render() {
        const { rows, page,addClassModal,classname, rowsPerPage, count } = this.state;

        return (
            <div className="AddStudent">
                <div className="pb_head student">
                    班级管理<span className="hp_My">&nbsp;&nbsp;</span>
                    {/* <span onClick={this.downLoad} style={{ float: 'right', fontSize: 14, marginTop: 15, cursor: "pointer", textDecoration: 'underline', color: "#2268BC" }}>xls 上传样例</span>
                    <span className="downloadSpan" >批量添加</span>
                    <input type="file" placeholder="批量添加" accept=".xlsx" onChange={e => this.importExcel(e)} /> */}
                    <button onClick={this.addClass}>添加班级</button>
                </div>
                <div className="search">
                    <input placeholder="请输入班级名称" onChange={e => { this.setState({ search: e.target.value }) }} /> <button onClick={this.getData}>查询</button>
                </div>
                <div style={table} component={Paper}>
                    {rows.length > 0 ? <Table style={tableStyle} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">班级管理</StyledTableCell>
                                <StyledTableCell align="center">操作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.classname}>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.classname}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <span class="delete" style={{ color: '#78ACEE', cursor: 'pointer' }} onClick={(e) => this.del(e, row.id)}>删除</span>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table> :
                        <div style={{ textAlign: 'center', }}>
                            <img src={require('../assets/img/notcontent.png')} />
                        </div>}
                    {rows.length > 0 && <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelRowsPerPage="每页显示条数"
                    />}
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addClassModal}
                    onClose={() => {
                        this.setState({
                            addClassModal: false
                        })
                    }}
                >
                    <Fade in={addClassModal}>
                        <div className="addClassModal">
                            <div>
                                <h2>添加班级</h2>
                                <span onClick={() => { this.setState({ addClassModal: false }) }}>X</span>
                            </div>
                            <ul>
                                <li>
                                    <span class="addName">班级名称</span>：
                                    <input className="inputs" value={classname} placeholder='请输入班级名'  onChange={e => { this.setState({ classname: e.target.value }) }} /> &nbsp;
                                </li>
                                {/* <li>
                                    <span>&nbsp;性&nbsp;别</span>：
                                    <label onClick={this.radio}>
                                        <input type="radio" name='set' value="1" />&nbsp;&nbsp;男
                                        <input type="radio" name='set' value="2" style={{ marginLeft: 30 }} />&nbsp;&nbsp;女
                                    </label>
                                </li>
                                <li>
                                    <span>手机号</span>：
                                    <input className="inputs" placeholder='请输入手机号' onChange={e => { this.setState({ phone: e.target.value }) }} />
                                </li>
                                <li>
                                    <span>&nbsp;邮&nbsp;箱</span>：
                                    <input className="inputs" placeholder='请输入邮箱' onChange={e => { this.setState({ email: e.target.value }) }} />
                                </li> */}
                            </ul>
                            <div className="submit">
                                <button style={{ background: '#F23A3A' }} onClick={this.submit}>确定</button>
                                <button style={{ background: '#AAA' }} onClick={() => { this.setState({ addClassModal: false }) }}>取消</button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}


export default classManage
