/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import '../css/login.css';
import { RGCaptcha, reset } from 'react-geetest-captcha';
import axios from 'axios';
import qs from 'qs';
import UserAgreement from '../components/UserAgreement'
import PrivacyAgreement from '../components/PrivacyAgreement'
import config from '../config'
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { Popover, CircularProgress, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getSchoolLists, geetestData, getWeixinCode } from '../http/api'

const CAPTCHA_NAME = 'demoCaptcha';
const mapDispatchToProps = (dispatch) => ({
  openRoomModal(state) {
    dispatch(toggleRoom(state));
  },
  toggleLogin(state) {
    dispatch(toggleLogin(state));
  },
  openUserDropdown(state) {
    dispatch(toggleUserDropdown(state));
  },
});
const mapStateToProps = state => {
  const { profile } = state.user;
  return {
    loginModalIsOpen: state.modals.loginModalIsOpen,
    account: profile && profile.account,
    name: profile && profile.name
  };
};
const regEmail = new RegExp("^[A-Za-z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$");
const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);
// const useStyles = makeStyles({
//   progress: {
//     margin: theme.spacing(2),
//   },
// });

@withRouter
class Registered extends React.Component {
  // const [completed, setCompleted] = useState(0)
  constructor(props) {
    super(props)
    this.state = {
      login: false,
      schoolShow: false,  // 學校列表顯示
      schoolNum:'', //  学校邮箱编号
      list: [
      ],
      schoolName: '',   // 學校名稱
      value: '',  // 學校
      identityValue: 1,   // 選擇的身份
      identityShow: false,   // 身份選擇下拉列表
      registereEmail: '',    // 用戶註冊郵箱
      registerePassWord: '',    // 用戶註冊密碼
      progress: 0,         // 进度条
      title: '',
      progressShow: false,
      geetest_challenge: '',
      geetest_validate: '',
      geetest_seccode: '',
      isread: false,
      open: false,
      open2: false,
      type: "",
    }
  }

  componentDidMount() {
    var _type = window.U.UF.C.queryString("type") || '';
    if (_type == 1 || _type == 2) {
      // window.location.href = "https://fangzhen.cocorobo.cn"
      this.setState({
        type: _type
      })
    }
  }

  // url 取值
  getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }

  geetestRegister() {
    reset(CAPTCHA_NAME);
  }

  geetest = () => {
    return geetestData().then((res) => {
      var captcha = (res && res.data) || {};
      return captcha;
    })
      .catch((err) => {
        console.log(err);
      })
    // return axios.get(`${config.env.api}geetest/pc-geetest/register`)
    //   .then((res) => {
    //     var captcha = (res && res.data) || {};
    //     return captcha;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
  }

  progress = (n) => {
    let num = this.state.progress + n;
    num = num > 100 ? 0 : num
    this.setState({
      progress: num
    })
  }

  // 點擊彈出身份列表
  identityShow = () => {
    const { identityShow } = this.state
    this.setState({
      identityShow: !identityShow
    })
  }

  // 註冊
  registered = () => {
    const { language } = this.props
    // if (this.state.geetest_challenge && this.state.geetest_validate && this.state.geetest_seccode) {
      const { schoolId, registereEmail, registerePassWord, value, progress, isread,schoolNum } = this.state
      let school = schoolId
      let a = schoolNum?('@' +schoolNum):'@cocorobo.cc'
      let email = ''
      let str = registereEmail.replace(/(^\s*)|(\s*$)/g, "")
      if (!regEmail.test(str)) {
        email = str + a
      }else{
        email = str
      }
      // !regpass.test(registerePassWord)
      if (registerePassWord.length < 6) {
        window.U.Alert(language.password_regular);
        return;
      }
      if (!isread) {
        window.U.Alert("请阅读并同意《用户注册协议》");
        return;
      }
      if (!school) {
        school = value
      }
      this.setState({
        progressShow: true
      })
      let aa = setInterval(() => {
        this.progress(10)
      }, 100);
      let params = {
        username: email,
        password: registerePassWord,
        googleId: '',
        identity: 1,
        college: school,
        geetest_challenge: this.state.geetest_challenge,
        geetest_validate: this.state.geetest_validate,
        geetest_seccode: this.state.geetest_seccode
      }
      var _that = this;
      console.log(params)
      axios.post(`${config.env.api}api/user`, qs.stringify(params)).then(res => {
        _that.geetestRegister();
        _that.setState({
          progressShow: false,
          progress: 100
        })
        clearInterval(aa)
        if (res.data[0][0].type === 1) {
          window.U.Alert(`<p>${language.registered_successful}</p><br/><img src='/img/loading.gif' />`, 1000);
          setTimeout(() => {
            _that.setState({
              progress: 0
            })
            _that.props.history.push('/login')
          }, 1000);
        } else if (res.data[0][0].type === 0) {
          window.U.Alert(language.registered_fail1);
        } else {
          window.U.Alert(language.registered_fail);
        }
      }).catch(err => {
        _that.geetestRegister();
        _that.setState({
          progressShow: false
        }, () => {
          clearInterval(aa);
          if (err && err.response.data) {
            window.U.Alert(err.response.data);
          } else {
            window.U.Alert(language.registered_fail);
          }
        })
      })
    // }
    // else {
    //   window.U.Alert(`<p>${language.click_validation}</p>`, 1000);
    // }
  }

  enter = (e) => {
    if (e.keyCode === 13) {
      this.registered()
    }
  }

  // 註冊密碼驗證
  registerePassWord = e => {
    // !regpass.test(e.target.value)
    if (e.target.value.length < 6) {
      window.U.Alert(this.props.language.password_regular);
    }
    // else {
    //   this.setState({
    //     registerePassWord: e.target.value
    //   })
    // }
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  };

  handleClose2 = () => {
    this.setState({
      open2: false
    })
  };
  
  // 根據輸入的值動態獲取學校列表
  changeList = e => {
    // 根據輸入的值動態獲取學校列表
    // e.target.value = e.target.value.replace(/[^\d]/g,'')
    console.log('e.target.value',e.target.value)
    this.setState({
      schoolName:e.target.value
    })
    if(e.target.value){
      this.getSchoolList(e.target.value)
    }else{
      this.setState({
        value: '',
      })
    }
  }

  // 獲取學校列表
  getSchoolList = e => {
    getSchoolLists({ mode: e }).then(res => {
      console.log(res.data[0])
      if(res.data[0].length>0){
        this.setState({
          value: res.data[0][0].name,
          schoolShow: false,
          schoolId: res.data[0][0].id,
          schoolNum:res.data[0][0].mail,
        })
      }else{
        this.setState({
          value: '该学校编号不存在',
          schoolShow: false,
          schoolId: '',
          schoolNum:'',
        })
      }
    })
}

  render() {
    const { login, progressShow, progress, registerePassWord, registereEmail, isread, open, open2,schoolName,value } = this.state
    const { language } = this.props
    return (
      <div className="login" onClick={(e) => {
        this.setState({
          schoolShow: false,
          identityShow: false
        })
      }}>
        <div className="contain" onKeyDown={this.enter} style={{ position: "absolute", top: "50%", marginTop: (login ? -218 : -232) + "px", left: 'calc(50% - 200px)' }}>
            <div style={{ padding: '0 20px', marginTop: '25px' }}>
              <div className="userLogin" onClick={(e) => {
                e.stopPropagation()
              }}>
                <img src={require("../assets/img/schoo.png")} style={{ width: '23px', paddingRight: 3 }} />
                <input type='text' className='schoolNumsty'
                  style={{width:150}} placeholder='学校编号（选填）'
                  onChange={this.changeList} value={schoolName} />
                  <span style={{marginLeft:15,display: 'block',width:'100%',whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',
                position: 'relative',top: 5}}>{value}</span>
              </div>
              <div className="userLogin">
                <img src={require("../assets/img/email.png")} />
                <input placeholder={language.electronic_email} onChange={e => {
                  this.setState({
                    registereEmail: e.target.value
                  })
                }} value={registereEmail} />
              </div>
              <div className="userLogin">
                <img src={require("../assets/img/password.png")} />
                <input type="password" placeholder={language.user_passworld_registered} onBlur={this.registerePassWord} onChange={e => {
                  this.setState({
                    registerePassWord: e.target.value
                  })
                }} value={registerePassWord} />
              </div>
              {/* <div className="identity" onClick={(e) => {
                e.stopPropagation()
              }}>
                <span>{language.identity_choice}：</span>
                <select name="" style={{ fontSize: 12, width: 75, outline: 'none' }} onClick={e => { this.setState({ identityValue: e.target.value }) }}>
                  <option value="0">{language.identity_teacher}</option>
                  <option value="1">{language.identity_student}</option>
                </select>
              </div> */}
              <div css={`display:flex;align-items: center;margin-top:10px`}>
                <input type="checkbox" css={`cursor:pointer;margin-right:5px`} onClick={() => { this.setState({ isread: !isread }) }} checked={isread} />
                <span>已经阅读并
                  <span css={`color:#2a97ff;cursor:pointer`} onClick={() => { this.setState({ open: true }) }}>《用户注册协议》</span>和<span css={`color:#2a97ff;cursor:pointer`} onClick={() => { this.setState({ open2: true }) }}>《隐私协议》</span></span>
              </div>
            </div>

              <div style={{padding: '20px'}}>
                <div className="loginUser">
                  <button onClick={this.registered}>{language.registered}</button>
                </div>
                <div className="guge">
                    <Link to="/login">
                        <button>{language.login}</button>
                    </Link>
                </div>
              </div>
        </div>
        <Popover
          // id={id}
          open={progressShow}
          anchorOrigin={{
            vertical: 200,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: '20px 30px', fontSize: 20 }}>
            <CircularProgress style={{ display: 'block', margin: '0 auto' }} variant="static" value={progress} />
          </div>
        </Popover>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="700px"
        >
          <DialogTitle id="alert-dialog-title" css={`background:#3D67BC;padding: 15px 20px 15px !important;`}>
            <div css={`color:#fff;font-size: 18px;`}>用户注册协议</div>
          </DialogTitle>
          <DialogContent style={{ background: "#fff" }}>
            <div css={`width:700px`}>
              <UserAgreement></UserAgreement>
            </div>
          </DialogContent>
          <DialogActions style={{ background: "#fff", padding: "8px 4px", margin: "0" }}>
            <div className="btnBox">
              <div></div>
              <div>
                <button className="info_btn" onClick={this.handleClose}>
                  关闭
                </button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={this.handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="700px"
        >
          <DialogTitle id="alert-dialog-title" css={`background:#3D67BC;padding: 15px 20px 15px !important;`}>
            <div css={`color:#fff;font-size: 18px;`}>隐私协议</div>
          </DialogTitle>
          <DialogContent style={{ background: "#fff" }}>
            <div css={`width:700px`}>
              <PrivacyAgreement></PrivacyAgreement>
            </div>
          </DialogContent>
          <DialogActions style={{ background: "#fff", padding: "8px 4px", margin: "0" }}>
            <div className="btnBox">
              <div></div>
              <div>
                <button className="info_btn" onClick={this.handleClose2}>
                  关闭
                </button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registered);
