import React from 'react'
import styled from 'styled-components'
//components
import Eraser from '../components/Eraser'
// import ChapterCard from '../components/ChapterCard'
import Button from '../components/Button'
//assets
import curriculum from '../assets/img/Eraser__Curriculum.svg'
//router
import { Link } from 'react-router-dom'
import { get } from 'axios';
import { connect } from 'react-redux';
import { setCourse } from '../redux/Actions'
//config
import config from '../config'
import Modal from '@material-ui/core/Modal';
import '../css/chapterSlect.css';
import qs from 'qs';
import axios from 'axios';
const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
const mapStateToProps = state => ({
  courses: state.courses,
  chapterTree: state.courses.chapters || [], // state.courses.chapters ||
});

function mapDispatchToProps(dispatch) {
  return {
    changeCourseData(data) {
      dispatch(setCourse(data));
    }
  }
}

class ChapterSelect extends React.Component {
  state = {
    loading: true,
    userId: '',
    show: false
  }

  componentDidMount = async () => {
    let arr = document.cookie.match(reg) && document.cookie.match(reg)[2];
    var _this = this
    if (!!!arr) {
      _this.props.history.push('/login');
    }
    else {
      axios.defaults.withCredentials = true;
      axios.get(`${config.env.server}getcookieuserid`).then(async res => {
        if (res.data[0][0]) {
          // if (res.data[0][0].identity === 1 && res.data[0][0].identity === 0) {
          //   _this.props.history.push('/login')
          //   return;
          // }
          let uuid = res.data[0][0].userid
          axios.post(`${config.env.edu}profile/usercourseJuri`, qs.stringify({ courseid: `${_this.props.match.params.courseId}`, userid: res.data[0][0].userid })).then(async res => {
            if (res.data[0][0].jurisdiction == 1) {
              await _this.setState({
                loading: false
              });
              if (!_this.props.chapterList) {
                const url = `${config.env.edu}chapter/${_this.props.match.params.courseId}`; //`${config.env.server}/curriculum/id/${this.props.match.params.courseId}`;
                await get(url)
                  .then(res => {
                    res.data[0][0].chapters = JSON.parse(res.data[0][0].chapters);
                    _this.props.changeCourseData(res.data[0][0])
                  }).catch(err => console.error(err));
              }
            }
            else {
              // _this.props.history.push('/401');
              _this.props.history.push(`/examine/${this.props.match.params.courseId}/${uuid}`)
            }
          }).catch(err => {
            // _this.props.history.push('/401');
            _this.props.history.push(`/examine/${this.props.match.params.courseId}/${uuid}`)
          })
          _this.setState({
            userId: res.data[0][0].userid,
          });
        }
        else {
          _this.props.history.push('/login');
        }
      }).catch(err => {
        _this.props.history.push('/login');
        //window.alert(err);
      })
    }

  }

  // 老师学生切换
  clickShow = (e) => {
    if (JSON.parse(window.localStorage.identity) === 0 || JSON.parse(window.localStorage.identity) === 2) {
      window.$(".Cselect")[0].className = "qiehuanBtn";
      if (e == 1) {
        window.$(".qiehuanBtn")[0].className = "qiehuanBtn Cselect";
        window.$('.allFlex')[0].style.display = "flex";
        window.$('.allFlex')[1].style.display = "none";
      } else {
        window.$(".qiehuanBtn")[1].className = "qiehuanBtn Cselect";
        window.$('.allFlex')[0].style.display = "none";
        window.$('.allFlex')[1].style.display = "flex";
      }
    }

  }

  render() {
    const keys = this.props.chapterTree; //this.props.chapterTree ||
    const { language } = this.props
    const identity = window.localStorage.identity && (JSON.parse(window.localStorage.identity) === 0 || JSON.parse(window.localStorage.identity) === 2)
    return (
      <div className="chapter_select">
        <Eraser
          img={curriculum}
          name={language.course}
          areaname='title'
        />
        {<div style={{ padding: "15px 0px" }}>
          {(this.state.userId) && <span className="qiehuanBtn Cselect" onClick={() => this.clickShow('1')}>{language.identity_teacher}</span>}
          <span className="qiehuanBtn" onClick={() => this.clickShow('2')}>{language.identity_student}</span>
        </div>}
        {(this.state.userId) && <div className="teacher">
          <div className="allFlex" style={{ display: "none" }}>
            {keys.map((val, index) => (
              <a className="chapter_a" href={`${config.env.basename}/tutor/chapter/${this.props.match.params.courseId}/${index}`}
                key={`course_${index}`}
                onClick={() => { window.sessionStorage.setItem("permissions", "teacher") }}>
                <img width='310' height="180" src={val.teacher.bg} title={val.title} style={{ objectFit: 'cover' }} />
                <div style={{ width: 310, padding: "16px 0", margin: '0 auto' }}>
                  <div className="chapter_title">
                    {val.title}
                  </div>
                  <div className="chapter_info" >
                    {val.teacher.intro}
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>}
        <div className="student">
          <div className="allFlex">
            {keys.map((val, index) => (
              <a className="chapter_a" href={`${config.env.basename}/tutor/chapter/${this.props.match.params.courseId}/${index}`}
                key={`course_${index}`}
                onClick={() => { window.sessionStorage.setItem("permissions", "student") }}>
                <img width='310' height="180" src={val.student.bg} title={val.title} style={{ objectFit: 'cover' }} />
                <div style={{ width: 310, padding: "16px 0", margin: '0 auto' }}>
                  <div className="chapter_title">
                    {val.title}
                  </div>
                  <div className="chapter_info" >
                    {val.student.intro}
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
        <Link to={`/`}
          css={`
            display:block;
            width:100%;
            text-align:center;
            text-decoration:none;
            margin-bottom:50px;
            @media screen and (max-width:1023px){
              width:50%;
            }
          `}>
          <Button
            name={language.return_prev_page}
            secondary
            css={`
              margin:0 auto;
              padding:10px 20px;
            `}
          />
        </Link>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.loading}
          onClose={() => {
            this.setState({
              loading: true
            })
          }}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
            <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{language.loadings}</p>
          </div>
        </Modal>
      </div>
    )
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(ChapterSelect);
