import React from 'react'
import '../assets/css/uploadPreview.css';
import icon from '../assets/img/icon.png'
import styled from 'styled-components'
import FileButton from './FileButton'
import DelButton from './DelButton'
import Overviewitem from './OverviewItem'
import PropTypes from 'prop-types'
import _ from '../utils'

//plugin axios
import axios from 'axios'
import config from '../config'

//redux
import { connect } from 'react-redux'
//redux-actions
import { initChapters, updateCourseAddCount } from '../actions/newupload/index'

const Chapter = styled.div.attrs({
  id: `chapter_`,
})`
text-align: left;
background-color: #f2f2f2;
width: 100%;
height: 67px;
padding: 0px 15px 0px 15px;
border-radius: 8px;
overflow: hidden;
font-size: 16px;
`;
const mapStateToProps = (state) => {
  return {
    cover: state.newupload.course.cover,
    demo: state.newupload.course.demo,
    count: state.newupload.course.count,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    initChapters(initchapters, prechaptertitles, prekeys, length) {
      dispatch(initChapters(initchapters, prechaptertitles, prekeys, length));
    },
    updateCourseAddCount(count) {
      dispatch(updateCourseAddCount(count));
    },
  }
}

class ChapterBgUploadPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: []
    };

  }
  componentWillReceiveProps(nextProps) {
    var _list = nextProps.preread;
    if (!_list instanceof Array) {
      _list = [_list];
    }
    if (this.props.preread !== _list) {
      this.setState({
        file: _list
      })
    }
  }
  componentDidMount() {
    var _list = this.props.preread;
    if (!_list instanceof Array) {
      _list = [_list];
    }
    this.setState({
      file: _list
    })

    const script = document.createElement('script');
    script.type = "text/javascript";
    script.src = "https://sdk.amazonaws.com/js/aws-sdk-2.235.1.min.js";
    script.async = "async";
    script.onload = function () {
      var credentials = {
        accessKeyId: 'AKIATLPEDU37QV5CHLMH',
        secretAccessKey: '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'
      };  //秘钥形式的登录上传
      window.AWS.config.update(credentials);
      window.AWS.config.region = 'cn-northwest-1';   //设置区域
    }
    document.body.appendChild(script);

  }
  add = function (e) {
    var el = e.currentTarget
    el.getElementsByTagName("input")[0].click();
  }
  upload = function (e) {
    var input = e.currentTarget;
    if (input.files.length !== 0) {
      // 判断上传文件大小
      // if (input.files.size / 1024 / 1024 > 10) {
      //   window.alert(this.props.language.upload_img_10);
      //   return false;
      // }
      let obj = {
        click: function () { },
        title: input.files[0].name,
        url: "",
        style: { backgroundSize: '242%', backgroundPosition: '-105px -76px' } // 
      }
      this.state.file.push(obj)
      this.setState({
        file: this.state.file
      })
      let count = this.props.count
      count++;
      this.props.updateCourseAddCount(count);

      // create bucket instance
      var bucket = new window.AWS.S3({ params: { Bucket: 'ccrb' } });  //选择桶
      var file = input.files[0];
      var _this = this;

      if (file) {
        var _uploadid = (window.localStorage['uploadid'] || "") + (this.props.number ? this.props.number + "/" : "") + "/";
        var params = { Key: _uploadid + file.name, ContentType: file.type, Body: file, 'Access-Control-Allow-Credentials': '*', 'ACL': 'public-read' }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = { partSize: 2048 * 1024 * 1024, queueSize: 2, leavePartsOnError: true };
        obj.bucket = bucket;
        obj.params = params;
        bucket.upload(params, options).
          on('httpUploadProgress', function (evt) {
            obj.width = parseInt((evt.loaded * 80) / evt.total);
            _this.setState({
              file: _this.state.file
            })
            // console.log("Uploaded : " + parseInt((evt.loaded * 100) / evt.total) + '%');
          }).send(function (err, data) {
            obj.width = 100;
            if (err) {
              obj.title = _this.props.language.upload_fail;
              obj.style = { backgroundSize: `253%`, backgroundPosition: '-78px -79px', display: "block" };
              _this.setState({
                file: _this.state.file
              })
              _this.chapterbgDebounced(_this.state.file);
            } else if (obj.delete !== true) {
              obj.click = function () { window.open(data.Location); };
              obj.style = { backgroundPosition: '-5px -131px', display: "block" };
              obj.title = file.name;
              obj.url = data.Location;
              _this.setState({
                file: _this.state.file
              })
              _this.chapterbgDebounced(_this.state.file);

              let counts = _this.props.count
              counts--;
              _this.props.updateCourseAddCount(counts);
            }
          });
        input.value = "";
      }

    }
  }
  move = (e, j) => {
    window.U.UF.EV.stopBubble();
    var el = e.currentTarget;
    var _st = el.parentNode.parentNode.scrollTop;
    el.style.zIndex = 999;
    var _pel = document.getElementById("chapter_2");
    var _x, _y, _l, _t, _drag;
    var _event = e;  //var _event = event || window.event;这句话就是定义了一个变量来获取事件对象，因为不同的浏览器获取事件对象的方法有点不太一样，IE下是window.event，标准下是event，为了兼容所以写了event || window.event.
    _drag = el; //赋值被拖拽的对象元素
    _x = _event.clientX; //记录元素的left值距离屏幕的差距
    _y = _event.clientY; //记录元素的top值距离屏幕的差距
    _l = _drag.offsetLeft; //元素的左偏移
    _t = _drag.offsetTop; //元素的右偏移
    var self = this;
    document.body.onmousemove = function (event) {
      var _event = event || window.event, //var _event = event || window.event;这句话就是定义了一个变量来获取事件对象，因为不同的浏览器获取事件对象的方法有点不太一样，IE下是window.event，标准下是event，为了兼容所以写了event || window.event.
        _newx = _event.clientX - _x,
        _newy = _event.clientY - _y,
        _mst = el.parentNode.parentNode.scrollTop - _st,
        _left = _newx + _l, // 移动后鼠标的水平距离减去元素未移动时的水平距离，等到元素水平移动的距离。
        _top = _newy + _t; // 移动后鼠标的垂直距离减去元素未移动时的垂直距离，等到元素垂直移动的距离。 
      el.style.top = _top + _mst + 'px'; //变被拖拽的对象元素的垂直位置
    }
    document.body.onmouseup = function (event) {
      var _child = _pel.children,
        _event = event || window.event,
        _mst = el.parentNode.parentNode.scrollTop - _st,
        _newx = _event.clientX - _x,
        _newy = _event.clientY - _y + _mst;
      // _i = (_i >= (_child.length - 1)) ? _child.length - 1 : _i;
      el.style.zIndex = 1;
      el.style.top = 0;
      document.body.onmousemove = null;
      document.body.onmouseup = null;
      if (_newx === 0 && _newy === 0) {
        return false;
      }
      else {
        var _i = Math.ceil(_newy / 60);
        for (var j = 0; j < _child.length - 1; j++) {
          if (_child[j] === el.parentNode) {
            _i += j;
            break;
          }
        }
        let list = self.state.file;
        let temp = list.splice(j, 1)[0];
        list.splice(_i > j ? _i - 1 : _i, 0, temp);
        for (var i = 0; i < list.length; i++) {
          if (list[i] == null) {
            list[i].splice(i, 1)
            i--;
          }
        }
        self.state.file = list;
        self.setState({
          file: list
        })
        self.chapterclipDebounced(list)
      }

    }
  }
  del = (e, j, obj) => {
    let filelist = this.state.file
    if (this.props.count !== 0 && (obj && obj.delete)) {
      let count = this.props.count
      count--;
      this.props.updateCourseAddCount(count)
    }
    if (obj && obj.bucket) {
      obj.delete = true;
      obj.bucket.deleteObject(obj.params, function () { });
    }
    filelist.splice(j, 1)
    this.setState({
      file: filelist
    })
    this.chapterbgDebounced(filelist)
  }
  onMouseDown = (e) => {
    e.stopPropagation()
  }
  chapterbgDebounced = _.debounce(this.props.getChapterBgVal || function () { }, 500);
  render() {
    let { file } = this.state;
    return (
      <Chapter className='chapter_'>
        {(file || []).map((x, j) => (
          <React.Fragment key={j}>
            <div className="chapter_upload" onClick={(e) => file[j] && file[j].url ? window.open(file[j].url) : function () { }}>
              <div className="chapter_upload_move">
                <div className="chapter_upload_t" style={{ width: file[j].width + '%' }}></div>
                <div className="chapter_upload_o">
                  <div className="chapter_upload_l">
                    <div className="chapter_upload_l_i">
                    </div>
                  </div>
                  <div className="chapter_upload_ic">
                    <div className={file[j].style.backgroundPosition !== "-5px -131px" ? "chapter_upload_ic_l1" : "chapter_upload_ic_l"}>
                      <div style={file[j].style.backgroundPosition !== "-5px -131px" ? { backgroundPosition: '0 0' } : file[j].style}>
                      </div>
                    </div>
                    <div className="chapter_upload_ic_r" onMouseDown={(e) => this.onMouseDown(e)} onClick={(e) => this.del(e, j, file[j])}>
                      <div>
                      </div>
                    </div>
                  </div>
                  <div className="chapter_upload_n">
                    {x.title}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
        )}
        <div className="chapter_add" onClick={(e) => this.add(e)}>
          <div className="chapter_add_l">
            +
            </div>
          <div className="chapter_add_r">
            {this.props.language.add_files}
            <span style={{ fontSize: 12, color: '#ccc' }}>({this.props.language.img_10})</span>
          </div>
          <input type="file"
            accept="image/png,image/gif,image/jpeg"
            className="chapter_add_input"
            onChange={(e) => this.upload(e)} />
        </div>
      </Chapter>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterBgUploadPreview);
