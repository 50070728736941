import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import { TextField, Checkbox } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChapterattachmentUrlUploadPreview from './ChapterattachmentUrlUploadPreview'
import ChapterSlideUploadPreview from './ChapterSlideUploadPreview'
import ChapterBgUploadPreview from './ChapterBgUploadPreview'
import ChapterClipUploadPreview from './ChapterClipUploadPreview'
import { Button } from '@material-ui/core'
// Actions
import {
    updateChapters

} from '../actions/newupload'
const mapStateToProps = (state) => {
    return {
        initchapters: state.newupload.chapter.initchapters,
        chapters: state.newupload.chapter.chapters
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateChapters(chapters) {
            dispatch(updateChapters(chapters))
        }
    }
}


const Wrapper = styled.div`
    grid-area:chapteredit;
    width:100%;
    
`;
const Title = styled.div`
height: 44px;
color: #fff;
border-radius: 5px;
padding: 10px;
background: #3D67BC;
font-weight: bold;
text-align: left;
font-size: 18px;
`;
const SubTitle = styled.div`
    margin: 15px 0;
    font-size: 17px;
    font-weight: bold;
    text-align: left;
`;
const TypeTitle = styled.div`
float: left;
margin: 8px 0 0 0;
height:31px;
font-size: 14px;
font-weight: bold;
text-align: left;
border-width: 2px;
border-style: solid;
border-color: #3D67BC;
color: #3D67BC;
padding: 3px 6px;

`;
const Divider = styled.hr`
//   margin: 390px 250px 30px 250px;
  align-self: center;
  grid-area:divider;
  height: 1px;
  border: none;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;
class Chapteredit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chaptersinfo: [],
            number: 0
        }
    }
    componentWillUnmount() {
        this.props.updateChapters(this.state.chaptersinfo)
    }

    componentDidMount() {
        this.setState({
            chaptersinfo: JSON.parse(window.localStorage.arr).map(x => {
                x.teacher.show = false;
                x.student.show = false;
                return x
            })
        })

    }
    updateTeachersIntro = (e, i) => {
        let id = i + 1;
        const { chaptersinfo } = this.state;
        let value = e.target.value;
        let list = chaptersinfo
        if (value.length > 2000) {
            for (let i = 0; i < list.length; i++) {
                let obj = list[i]
                if (obj.id === id && obj.checked) {
                    obj.teacher.show = true;
                    obj.student.show = true;
                } else if (obj.id === id && !obj.checked) {
                    obj.teacher.show = true;
                    obj.student.show = false;
                }
                this.setState({
                    chaptersinfo: list
                })
            }
            this.props.updateChapters(this.state.chaptersinfo)
        }
        else {
            for (let i = 0; i < list.length; i++) {
                let obj = list[i]
                if (obj.id === id && obj.checked) {
                    obj.teacher.intro = value;
                    obj.student.intro = value
                    obj.teacher.show = false;
                    obj.student.show = false;
                } else if (obj.id === id && !obj.checked) {
                    obj.teacher.intro = value;
                    obj.teacher.show = false;
                }
                this.setState({
                    chaptersinfo: list
                })
            }
            this.props.updateChapters(this.state.chaptersinfo)
        }
    }

    updateStudendsIntro = (e, i) => {
        let id = i + 1;
        const { chaptersinfo } = this.state;
        let value = e.target.value;
        let list = chaptersinfo
        if (value.length > 2000) {
            for (let i = 0; i < list.length; i++) {
                let obj = list[i]
                if (obj.id === id && obj.checked) {
                    obj.teacher.show = true;
                    obj.student.show = true;
                } else if (obj.id === id && !obj.checked) {
                    obj.student.show = true;
                    obj.teacher.show = false;
                }
                this.setState({
                    chaptersinfo: list
                })
            }
            this.props.updateChapters(this.state.chaptersinfo)
        } else {
            for (let i = 0; i < list.length; i++) {
                let obj = list[i]
                if (obj.id === id && obj.checked) {
                    obj.teacher.intro = value;
                    obj.student.intro = value
                    obj.teacher.show = false;
                    obj.student.show = false;
                } else if (obj.id === id && !obj.checked) {
                    obj.student.intro = value;
                    obj.student.show = false;
                }
                this.setState({
                    chaptersinfo: list
                })
            }
            this.props.updateChapters(this.state.chaptersinfo)
        }

    }

    handleChange = (event, i) => {
        let id = i + 1;
        let { chaptersinfo } = this.state;
        let value = event.target.checked;
        let list = chaptersinfo
        for (let i = 0; i < list.length; i++) {
            let obj = list[i]
            if (obj.id === id) {
                obj.checked = value
            }
            this.setState({
                chaptersinfo: list
            })
        }
        if (list[i].checked === true) {
            [
                list[i].student.bg,
                list[i].student.intro,
                list[i].student.clipUrl,
                list[i].student.slideUrl,
                list[i].student.attachmentUrl,
                list[i].student.show
            ] = [list[i].teacher.bg,
            list[i].teacher.intro,
            list[i].teacher.clipUrl,
            list[i].teacher.slideUrl,
            list[i].teacher.attachmentUrl,
            list[i].teacher.show
                ]
        } else {
            list[i].student.bg = [];
            list[i].student.intro = '';
            list[i].student.clipUrl = [];
            list[i].student.slideUrl = [];
            list[i].student.attachmentUrl = [];
            list[i].student.show = false;
        }
        this.setState({
            chaptersinfo: list
        })
        this.props.updateChapters(list)
    }
    updateTeacherchapterBg = (val, i) => {
        let id = i + 1;
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        for (let i = 0; i < list.length; i++) {
            let obj = list[i]
            if (obj.id === id && obj.checked) {
                obj.teacher.bg = val
                obj.student.bg = val
            } else if (obj.id === id && !obj.checked) {
                obj.teacher.bg = val
            }
            this.setState({
                chaptersinfo: list
            })
        }
        this.props.updateChapters(this.state.chaptersinfo)
    }
    updateStudentchapterBg = (val, i) => {
        let id = i + 1;
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        for (let i = 0; i < list.length; i++) {
            let obj = list[i]
            if (obj.id === id && obj.checked) {
                obj.teacher.bg = val
                obj.student.bg = val
            } else if (obj.id === id && !obj.checked) {
                obj.student.bg = val
            }
            this.setState({
                chaptersinfo: list
            })
        }
        this.props.updateChapters(this.state.chaptersinfo)
    }

    updateTeacherchapterClip = (val, i) => {
        let id = i + 1;
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        for (let i = 0; i < list.length; i++) {
            let obj = list[i]
            if (obj.id === id && obj.checked) {
                obj.teacher.clipUrl = val
                obj.student.clipUrl = val
            } else if (obj.id === id && !obj.checked) {
                obj.teacher.clipUrl = val
            }
            this.setState({
                chaptersinfo: list
            })
        }
        this.props.updateChapters(this.state.chaptersinfo)
    }
    updateStudentchapterClip = (val, i) => {
        let id = i + 1;
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        for (let i = 0; i < list.length; i++) {
            let obj = list[i]
            if (obj.id === id && obj.checked) {
                obj.teacher.clipUrl = val
                obj.student.clipUrl = val
            } else if (obj.id === id && !obj.checked) {
                obj.student.clipUrl = val
            }
            this.setState({
                chaptersinfo: list
            })
        }
        this.props.updateChapters(this.state.chaptersinfo)
    }

    updateTeacherchapterSlide = (val, i) => {
        let id = i + 1;
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        for (let i = 0; i < list.length; i++) {
            let obj = list[i]
            if (obj.id === id && obj.checked) {
                obj.teacher.slideUrl = val
                obj.student.slideUrl = val
            } else if (obj.id === id && !obj.checked) {
                obj.teacher.slideUrl = val
            }
            this.setState({
                chaptersinfo: list
            })
        }
        this.props.updateChapters(this.state.chaptersinfo)
    }

    updateStudentchapterSlide = (val, i) => {
        let id = i + 1;
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        for (let i = 0; i < list.length; i++) {
            let obj = list[i]
            if (obj.id === id && obj.checked) {
                obj.teacher.slideUrl = val
                obj.student.slideUrl = val
            } else if (obj.id === id && !obj.checked) {
                obj.student.slideUrl = val
            }
            this.setState({
                chaptersinfo: list
            })
        }
        this.props.updateChapters(this.state.chaptersinfo)
    }
    updateTeacherchapterAttachment = (val, i) => {
        let id = i + 1;
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        for (let i = 0; i < list.length; i++) {
            let obj = list[i]
            if (obj.id === id && obj.checked) {
                obj.teacher.attachmentUrl = val
                obj.student.attachmentUrl = val
            } else if (obj.id === id && !obj.checked) {
                obj.teacher.attachmentUrl = val
            }
            this.setState({
                chaptersinfo: list
            })
        }
        this.props.updateChapters(this.state.chaptersinfo)
    }
    updateStudentchapterAttachment = (val, i) => {
        let id = i + 1;
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        for (let i = 0; i < list.length; i++) {
            let obj = list[i]
            if (obj.id === id && obj.checked) {
                obj.teacher.attachmentUrl = val
                obj.student.attachmentUrl = val
            } else if (obj.id === id && !obj.checked) {
                obj.student.attachmentUrl = val
            }
            this.setState({
                chaptersinfo: list
            })
        }
        this.props.updateChapters(this.state.chaptersinfo)
    }

    chapterBgRefs = {}
    getChapterbgRef(key) {
        if (!this.chapterBgRefs[key])
            this.chapterBgRefs[key] = React.createRef();
        return this.chapterBgRefs[key]
    }

    render() {
        const { language } = this.props
        const { number, chaptersinfo } = this.state
        return (
            <Wrapper>
                {/*ItemList()*/}
                {this.props.history.location.pathname === "/upload/chapter/edit" && this.state.chaptersinfo.map((x, i) => (
                    <React.Fragment key={i}>
                        {i == this.props.number && <div>
                            <div css={'height: 44px;'} id={x.title}>
                                <Title>{`第${x.id}章: ${x.title}`}</Title>
                            </div>
                            <TypeTitle>{language.teacher_content}</TypeTitle>
                            <div css={`clear:both;`}>
                                <div css={`width:48%;float:left;`}>
                                    <SubTitle>
                                        <span css={`color:red`}>*</span>{language.chapter_back_img}
                                        <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>PNG, GIF, JPEG</span>
                                    </SubTitle>
                                    <div css={`height:114px;margin-top:22px;`}>
                                        <ChapterBgUploadPreview
                                            number={this.props.number}
                                            preread={x.teacher.bg}
                                            getChapterBgVal={(value) => this.updateTeacherchapterBg(value, i)}
                                            language={language}
                                        ></ChapterBgUploadPreview>
                                    </div>
                                </div>
                                <div css={`width:48%;float:right;margin-top:6px;`}>
                                    <SubTitle>
                                        <span css={`color:red`}>*</span>{language.chapter_PDF}
                                        <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>PDF</span>
                                    </SubTitle>
                                    <div css={`height:135px;width:100%;float:left`}>
                                        <ChapterSlideUploadPreview
                                            number={this.props.number}
                                            preread={x.teacher.slideUrl}
                                            format='application/pdf'
                                            language={language}
                                            getChapterSlideVal={(value) => this.updateTeacherchapterSlide(value, i)}
                                        />
                                    </div>
                                </div>
                                <div css={`width:48%;float:left`}>
                                    <SubTitle><span css={`color:red`}>*</span>{language.chapter_describe}: {x.teacher.show ? <span style={{ fontSize: 12, float: 'right', color: 'red' }}>{language.chapter_describe_prompts}</span> : <span style={{ fontSize: 12, float: 'right', color: 'rgb(128,128,128)' }}>{language.chapter_describe_prompt}</span>}</SubTitle>
                                    <TextField
                                        value={x.teacher.intro}
                                        onChange={(e) => this.updateTeachersIntro(e, i)}
                                        row={4}
                                        // label="此處填寫章節描述"
                                        placeholder={language.chapter_describe_prompt}
                                        variant="outlined"
                                        fullWidth={true}
                                        multiline={true}
                                        margin='dense'
                                        rows={4}
                                    ></TextField>
                                </div>
                                <div css={`width:48%;float:right;margin-top:-20px;`}>
                                    <SubTitle>
                                        {language.chapter_video}:<span style={{ color: "red", fontSize: "12px" }}>(*{language.chapter_video_prompt})</span>
                                        <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>MP4, M4V, MOV, AVI</span>
                                    </SubTitle>
                                    <div css={`height:135px;`}>
                                        <ChapterClipUploadPreview
                                            number={this.props.number}
                                            preread={x.teacher.clipUrl}
                                            language={language}
                                            identify={i}
                                            getChapterClipVal={(value) => this.updateTeacherchapterClip(value, i)}
                                        />
                                    </div>
                                </div>
                                <div css={`width:48%;overflow:hidden;`}>
                                    <SubTitle>
                                        {language.chapter_attachment}:<span style={{ color: "red", fontSize: "12px" }}>(*{language.chapter_video_prompt})</span>
                                        <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>{language.all_files}</span>
                                    </SubTitle>
                                    <div css={`height:135px;`}>
                                        <ChapterattachmentUrlUploadPreview
                                            number={this.props.number}
                                            preread={x.teacher.attachmentUrl}
                                            getChapterAttachmentUrlVal={(value) => this.updateTeacherchapterAttachment(value, i)}
                                            language={language}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <TypeTitle>{language.student_content}</TypeTitle>
                                    <div css={`margin-left: 100px;text-align:left;margin-left:100px;`}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) => this.handleChange(e, i)}
                                                    checked={x.checked}
                                                    value='checked'
                                                    color="primary"
                                                />}
                                            label={language.synchronous_content}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div css={`width:48%;float:left;`}>
                                        <SubTitle>
                                            <span css={`color:red`}>*</span>{language.chapter_back_img}
                                            <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>PNG, GIF, JPEG</span>
                                        </SubTitle>
                                        <div css={`height:114px;margin-top:22px;`}>
                                            <ChapterBgUploadPreview
                                                number={this.props.number}
                                                preread={x.student.bg}
                                                language={language}
                                                getChapterBgVal={(value) => this.updateStudentchapterBg(value, i)}
                                            ></ChapterBgUploadPreview>
                                        </div>
                                    </div>
                                    <div css={`width:48%;float:right;margin-top:6px;`}>
                                        <SubTitle>
                                            <span css={`color:red`}>*</span>{language.chapter_PDF}
                                            <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>PDF</span>
                                        </SubTitle>
                                        <div css={`height:135px;float:left;width:100%`}>
                                            <ChapterSlideUploadPreview
                                                number={this.props.number}
                                                preread={x.student.slideUrl}
                                                format='application/pdf'
                                                language={language}
                                                getChapterSlideVal={(value) => this.updateStudentchapterSlide(value, i)}
                                            />
                                        </div>
                                    </div>
                                    <div css={`width:48%;float:left`}>
                                        <SubTitle><span css={`color:red`}>*</span>{language.chapter_describe}:{x.student.show ? <span style={{ fontSize: 12, float: 'right', color: 'red' }}>{language.chapter_describe_prompts}</span> : <span style={{ fontSize: 12, float: 'right', color: 'rgb(128,128,128)' }}>{language.chapter_describe_prompt}</span>}</SubTitle>
                                        <TextField
                                            value={x.student.intro}
                                            onChange={(e) => this.updateStudendsIntro(e, i)}
                                            row={4}
                                            label={language.chapter_describe_prompt}
                                            variant="outlined"
                                            fullWidth={true}
                                            multiline={true}
                                            margin='dense'
                                            rows={4}
                                        ></TextField>
                                    </div>

                                    <div css={`width:48%;float:right;margin-top:-20px;`}>
                                        <SubTitle>
                                            {language.chapter_video}:<span style={{ color: "red", fontSize: "12px" }}>(*{language.chapter_video_prompt})</span>
                                            <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>MP4, M4V, MOV, AVI</span>
                                        </SubTitle>
                                        <div css={`height:135px`}>
                                            <ChapterClipUploadPreview
                                                number={this.props.number}
                                                preread={x.student.clipUrl}
                                                identify={i}
                                                getChapterClipVal={(value) => this.updateStudentchapterClip(value, i)}
                                                language={language}
                                            />
                                        </div>
                                    </div>
                                    <div css={`width:48%;overflow:hidden;`}>
                                        <SubTitle>
                                            {language.chapter_attachment}:<span style={{ color: "red", fontSize: "12px" }}>(*{language.chapter_video_prompt})</span>
                                            <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>{language.all_files}</span>
                                        </SubTitle>
                                        <div css={`height:135px;}`}>
                                            <ChapterattachmentUrlUploadPreview
                                                number={this.props.number}
                                                preread={x.student.attachmentUrl}
                                                language={language}
                                                getChapterAttachmentUrlVal={(value) => this.updateStudentchapterAttachment(value, i)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: "space-around", marginTop: 30 }}>
                                        {(this.props.number > 0) && <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={() => {
                                                if (this.props.number > 0) {
                                                    // this.setState({
                                                    //     number: this.state.number - 1
                                                    // })
                                                    this.props.getNumber(this.props.number - 1)
                                                }
                                            }}
                                        >
                                            上一章
                                        </Button>}
                                        {(this.props.number < chaptersinfo.length - 1) && <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={() => {
                                                // this.setState({
                                                //     number: this.state.number + 1
                                                // })
                                                this.props.getNumber(this.props.number + 1)
                                            }}
                                        >
                                            下一章
                                    </Button>}
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {/*<div>
                            <Divider></Divider>
                        </div>
                        */}
                    </React.Fragment>
                ))

                }
                {this.props.history.location.pathname === "/upload/chapter/check" && this.state.chaptersinfo.map((x, i) => (
                    <React.Fragment key={i}>
                        <div>
                            <div css={'height: 44px;'} id={x.title}>
                                <Title>{`第${x.id}章: ${x.title}`}</Title>
                            </div>
                            <TypeTitle>{language.teacher_content}</TypeTitle>
                            <div css={`clear:both;`}>
                                <div css={`width:48%;float:left;`}>
                                    <SubTitle>
                                        <span css={`color:red`}>*</span>{language.chapter_back_img}
                                        <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>PNG, GIF, JPEG</span>
                                    </SubTitle>
                                    <div css={`height:114px;margin-top:22px;`}>
                                        <ChapterBgUploadPreview
                                            number={this.props.number}
                                            preread={x.teacher.bg}
                                            getChapterBgVal={(value) => this.updateTeacherchapterBg(value, i)}
                                            language={language}
                                        ></ChapterBgUploadPreview>
                                    </div>
                                </div>
                                <div css={`width:48%;float:right;margin-top:6px;`}>
                                    <SubTitle>
                                        <span css={`color:red`}>*</span>{language.chapter_PDF}
                                        <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>PDF</span>
                                    </SubTitle>
                                    <div css={`height:135px;width:100%;float:left`}>
                                        <ChapterSlideUploadPreview
                                            number={this.props.number}
                                            preread={x.teacher.slideUrl}
                                            format='application/pdf'
                                            language={language}
                                            getChapterSlideVal={(value) => this.updateTeacherchapterSlide(value, i)}
                                        />
                                    </div>
                                </div>
                                <div css={`width:48%;float:left`}>
                                    <SubTitle><span css={`color:red`}>*</span>{language.chapter_describe}: {x.teacher.show ? <span style={{ fontSize: 12, float: 'right', color: 'red' }}>{language.chapter_describe_prompts}</span> : <span style={{ fontSize: 12, float: 'right', color: 'rgb(128,128,128)' }}>{language.chapter_describe_prompt}</span>}</SubTitle>
                                    <TextField
                                        value={x.teacher.intro}
                                        onChange={(e) => this.updateTeachersIntro(e, i)}
                                        row={4}
                                        // label="此處填寫章節描述"
                                        placeholder={language.chapter_describe_prompt}
                                        variant="outlined"
                                        fullWidth={true}
                                        multiline={true}
                                        margin='dense'
                                        rows={4}
                                    ></TextField>
                                </div>
                                <div css={`width:48%;float:right;margin-top:-20px;`}>
                                    <SubTitle>
                                        {language.chapter_video}:<span style={{ color: "red", fontSize: "12px" }}>(*{language.chapter_video_prompt})</span>
                                        <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>MP4, M4V, MOV, AVI</span>
                                    </SubTitle>
                                    <div css={`height:135px;`}>
                                        <ChapterClipUploadPreview
                                            number={this.props.number}
                                            preread={x.teacher.clipUrl}
                                            identify={i}
                                            language={language}
                                            getChapterClipVal={(value) => this.updateTeacherchapterClip(value, i)}
                                        />
                                    </div>
                                </div>
                                <div css={`width:48%;overflow:hidden;`}>
                                    <SubTitle>
                                        {language.chapter_attachment}:<span style={{ color: "red", fontSize: "12px" }}>(*{language.chapter_video_prompt})</span>
                                        <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>{language.all_files}</span>
                                    </SubTitle>
                                    <div css={`height:135px;`}>
                                        <ChapterattachmentUrlUploadPreview
                                            number={this.props.number}
                                            preread={x.teacher.attachmentUrl}
                                            getChapterAttachmentUrlVal={(value) => this.updateTeacherchapterAttachment(value, i)}
                                            language={language}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <TypeTitle>{language.student_content}</TypeTitle>
                                    <div css={`margin-left: 100px;text-align:left;margin-left:100px;`}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) => this.handleChange(e, i)}
                                                    checked={x.checked}
                                                    value='checked'
                                                    color="primary"
                                                />}
                                            label={language.synchronous_content}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div css={`width:48%;float:left;`}>
                                        <SubTitle>
                                            <span css={`color:red`}>*</span>{language.chapter_back_img}
                                            <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>PNG, GIF, JPEG</span>
                                        </SubTitle>
                                        <div css={`height:114px;margin-top:22px;`}>
                                            <ChapterBgUploadPreview
                                                number={this.props.number}
                                                preread={x.student.bg}
                                                language={language}
                                                getChapterBgVal={(value) => this.updateStudentchapterBg(value, i)}
                                            ></ChapterBgUploadPreview>
                                        </div>
                                    </div>
                                    <div css={`width:48%;float:right;margin-top:6px;`}>
                                        <SubTitle>
                                            <span css={`color:red`}>*</span>{language.chapter_PDF}
                                            <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>PDF</span>
                                        </SubTitle>
                                        <div css={`height:135px;float:left;width:100%`}>
                                            <ChapterSlideUploadPreview
                                                number={this.props.number}
                                                preread={x.student.slideUrl}
                                                format='application/pdf'
                                                language={language}
                                                getChapterSlideVal={(value) => this.updateStudentchapterSlide(value, i)}
                                            />
                                        </div>
                                    </div>
                                    <div css={`width:48%;float:left`}>
                                        <SubTitle><span css={`color:red`}>*</span>{language.chapter_describe}:{x.student.show ? <span style={{ fontSize: 12, float: 'right', color: 'red' }}>{language.chapter_describe_prompts}</span> : <span style={{ fontSize: 12, float: 'right', color: 'rgb(128,128,128)' }}>{language.chapter_describe_prompt}</span>}</SubTitle>
                                        <TextField
                                            value={x.student.intro}
                                            onChange={(e) => this.updateStudendsIntro(e, i)}
                                            row={4}
                                            label={language.chapter_describe_prompt}
                                            variant="outlined"
                                            fullWidth={true}
                                            multiline={true}
                                            margin='dense'
                                            rows={4}
                                        ></TextField>
                                    </div>

                                    <div css={`width:48%;float:right;margin-top:-20px;`}>
                                        <SubTitle>
                                            {language.chapter_video}:<span style={{ color: "red", fontSize: "12px" }}>(*{language.chapter_video_prompt})</span>
                                            <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>MP4, M4V, MOV, AVI</span>
                                        </SubTitle>
                                        <div css={`height:135px`}>
                                            <ChapterClipUploadPreview
                                                number={this.props.number}
                                                preread={x.student.clipUrl}
                                                identify={i}
                                                getChapterClipVal={(value) => this.updateStudentchapterClip(value, i)}
                                                language={language}
                                            />
                                        </div>
                                    </div>
                                    <div css={`width:48%;overflow:hidden;`}>
                                        <SubTitle>
                                            {language.chapter_attachment}:<span style={{ color: "red", fontSize: "12px" }}>(*{language.chapter_video_prompt})</span>
                                            <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>{language.all_files}</span>
                                        </SubTitle>
                                        <div css={`height:135px;}`}>
                                            <ChapterattachmentUrlUploadPreview
                                                number={this.props.number}
                                                preread={x.student.attachmentUrl}
                                                language={language}
                                                getChapterAttachmentUrlVal={(value) => this.updateStudentchapterAttachment(value, i)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div>
                            <Divider></Divider>
                        </div>
                        */}
                    </React.Fragment>
                ))
                }
            </Wrapper>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Chapteredit);