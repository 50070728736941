import React, { Component } from 'react'
import axios from 'axios';
import config from '../config'
import '../css/studentState.css'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Modal, Table, Paper, TableHead, TableRow, TableBody, TableCell, TablePagination, Fade } from '@material-ui/core';
import color from '@material-ui/core/colors/amber';
import ReactEcharts from "echarts-for-react";
import * as echarts from 'echarts';

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const mytextStyle = {
    color: "#333",                //文字颜色
    fontStyle: "normal",         //italic斜体  oblique倾斜
    fontWeight: "normal",        //文字粗细bold   bolder   lighter  100 | 200 | 300 | 400...
    // fontFamily:"sans-serif",   //字体系列
    fontSize: 12,                //字体大小
};

export class studentState extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            people: [],
            courseId: "",
            addClassModal: false,
            pid: [],
            score: [],
            pChapters: null,
            tname: { },
            ppeople: "",
            courseNow: [],
            ut: "",
            count: 0,
            homework: []
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.courseId != nextProps.courseId) {
            console.log(this.props)
            this.setState({
                courseId: nextProps.courseId
            }, () => {
                this.getData()
            })
        }
    }
    getData = () => {
        const { courseId } = this.state
        axios.defaults.withCredentials = true;
        axios.get(`${config.env.edu}getCourseState2/${courseId}`).then(res => {
            let reS = res.data[0]
            if (reS.length > 0) {
                this.setState({
                    tname: { tname: reS[0].tname, teachername: reS[0].teachername }
                })
                let _rows = []
                let _people = []
                let _pid = []
                let _courseNow = []
                console.log(reS)
                for (let i = 0; i < reS.length; i++) {
                    _people.push(reS[i].alias ? reS[i].alias : reS[i].username)
                    _pid.push(reS[i].stuId)
                    var state = JSON.parse(reS[i].state)
                    var _json2 = []
                    var _isread = []
                    for (let cj = 0; cj < state.length; cj++) {
                        for (let k = 0; k < state[cj].chapterData.length; k++) {
                            state[cj].chapterData[k].cd = state[cj].chapterid
                            _json2.push(state[cj].chapterData[k])
                            if (state[cj].chapterData[k].isread) {
                                _isread.push(state[cj].chapterData[k])
                            }
                        }
                    }
                    _courseNow.push(`【${_isread[_isread.length - 1].typeName}】${_isread[_isread.length - 1].minutiaName}`)
                    _rows.push(_json2)
                }
                this.setState({
                    people: _people,
                    pid: _pid,
                    rows: _rows,
                    courseNow: _courseNow
                })
                console.log(_people)
                console.log(_rows)
            }
            this.props.set(reS[0] ? reS[0].num : 0)
        })
    }

    get = (uid, pChapters, ppeople) => {
        const { courseId } = this.state
        axios.get(`${config.env.edu}getSummaryById2/${courseId}/${uid}`).then(res => {
            console.log(res)
            if (res.data[0].length && res.data[0][0].score) {
                var score = JSON.parse(res.data[0][0].score)
                var ut = ""
                if (score) {
                    score.testScore = this.sum(score.testScore) / score.testScore.length
                    score.sum = score.sum * 20
                    score.code = score.code * 20
                    ut = res.data[0][0].updatetime
                }
                this.setState({
                    score: score ? [score] : [],
                    addClassModal: true,
                    pChapters: pChapters,
                    ppeople: ppeople,
                    ut: ut,
                })
            } else {
                var homework = []
                for (var x in pChapters) {
                    if (pChapters[x].typeName == "编程" || pChapters[x].typeName == "测验") {
                        pChapters[x].is = 1
                        homework.push(pChapters[x])
                    }
                }
                if (res.data[1]) {
                    var r1 = res.data[1]
                    for (var i in homework) {
                        for (var c in r1) {
                            if (homework[i].typeName == "测验" && homework[i].id == r1[c].chapterid) {
                                homework[i].is = 2
                                homework[i].time = r1[c].time
                            }
                        }
                    }
                }
                if (res.data[2]) {
                    var r1 = res.data[2]
                    for (var i in homework) {
                        for (var c in r1) {
                            if (homework[i].typeName == "编程" && homework[i].id == r1[c].chapterid) {
                                homework[i].is = 2
                                homework[i].time = r1[c].time
                            }
                        }
                    }
                }
                this.setState({
                    score: [],
                    addClassModal: true,
                    pChapters: pChapters,
                    ppeople: ppeople,
                    homework: homework
                })
            }
        })
    }


    /**
    * @description 配置图表
    * @returns 
    * @memberof EchartsRadar
    */
    getOption() {
        const { score } = this.state
        return {
            title: {
                text: ''
            },
            //点击提示标签
            // tooltip: {},
            radar: {
                //雷达图绘制类型，支持 'polygon' 和 'circle' [ default: 'polygon' ]
                shape: 'polygon',
                splitNumber: 4,
                center: ['50%', '60%'],
                radius: '65%',
                //指示器名称和指示器轴的距离。[ default: 15 ]
                nameGap: 30,
                triggerEvent: true,
                name: {
                    textStyle: {
                        color: '#999',
                        backgroundColor: 'transparent',
                        // borderRadius: 3,
                        // padding: [3, 5]
                    },
                    formatter: function (value, indicator) {
                        value = value.replace(/\S{5}/g, function (match) {
                            return match + '\n'
                        })
                        // value = value + '\n' + indicator.value;
                        return '{a|' + value + '}' + '\n' + '{b|' + indicator.value + "分" + '}'
                        // return `{a|${value}(${indicator.value})分}`
                    },
                    //富文本编辑 修改文字展示样式
                    rich: {
                        a: {
                            color: "#999",
                            fontSize: 12,
                            align: "center",
                            width: "200px"
                        },
                        b: {
                            color: "#333",
                            fontSize: 17,
                            align: "center"
                        }
                    }
                },
                // 设置雷达图中间射线的颜色
                axisLine: {
                    lineStyle: {
                        color: '#ddd',
                    },
                },
                indicator: [
                    { "name": "测验答题分", "value": Math.round(score[0].testScore), "max": 100 },
                    { "name": "编程能力分", "value": score[0].code, "max": 100 },
                    { "name": "项目实践分", "value": score[0].sum, "max": 100 },
                ],
                //雷达图背景的颜色，在这儿随便设置了一个颜色，完全不透明度为0，就实现了透明背景
                splitArea: {
                    show: false,
                    areaStyle: {
                        color: 'rgba(255,0,0,0)', // 图表背景的颜色
                    },
                }
            },
            series: [{
                name: '分数',
                type: 'radar',
                //显示雷达图选中背景
                areaStyle: { normal: { } },
                data: [
                    {
                        value: [Math.round(score[0].testScore), score[0].code, score[0].sum],
                        // 设置区域边框和区域的颜色
                        itemStyle: {
                            normal: {
                                //雷达图背景渐变设置
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0.5,
                                    color: 'rgba(48,107, 231, 1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(73,168, 255, 0.7)'
                                }]),
                                //去除刻度
                                opacity: 0,
                                //雷达图边线样式
                                lineStyle: {
                                    width: 0,
                                    color: '#306BE7',
                                },
                            },
                        },
                    }
                ]
            }]
        };
    }

    /**
        * @description 雷达图选中区域点击事件和外部显示标签点击事件
        * @param {any} param 
        * @param {any} echarts 
        * @memberof EchartsRadar
        */
    onChartClick(param, echarts) {
        console.log(param)
    }
    /**
     * @description 点击legend事件
     * @param {any} param 
     * @param {any} echarts 
     * @memberof EchartsRadar
     */
    onChartLegendselectchanged(param, echarts) {
        console.log(param)
    }

    sum = (arr) => {
        var s = 0;
        arr.forEach(function (val, idx, arr) {
            s += val;
        }, 0);

        return s;
    };

    render() {
        const { people, rows, addClassModal, pid, score, pChapters, tname, ppeople, courseNow, ut, count, homework } = this.state
        let onEvents = {
            'click': this.onChartClick.bind(this),
            'legendselectchanged': this.onChartLegendselectchanged.bind(this)
        }
        return (
            <div className="ss_container" style={{ padding: "10px", display: "flex", backgroundColor: "rgb(246 246 246)", flexWrap: "wrap", alignContent: "flex-start", maxHeight: "555px" }}>
                {
                    rows && rows.length > 0 ? rows.map((x, i) => (
                        // <div className="ss_box" key={i}>
                        //     <div className="ss_nameBox">
                        //         <span className="ss_name">{people[i]}</span>
                        //         <span className="ss_name" style={{ color: "#2e3071", marginTop: "5px", cursor: "pointer", fontSize: "16px" }} onClick={() => { this.get(pid[i], x,people[i]) }}>查看详情</span>
                        //     </div>
                        //     <div className="ss_state_box">
                        //         {
                        //             x.map((k, index) => (
                        //                 <div className="ss_state_poi" key={i + '-' + index}>
                        //                     <div className="ss_line_box">
                        //                         {x.length == index + 1 ? '' : <div className="ss_line"></div>}
                        //                         {k.isread ? <img src={require('../assets/img/manage/learned.png')} className="ss_line_dian" /> : <img src={require('../assets/img/manage/learn.png')} className="ss_line_dian" />}
                        //                     </div>
                        //                     <div className="ss_font">
                        //                         <span title={k.minutiaName}>{k.minutiaName}</span>
                        //                     </div>
                        //                 </div>
                        //             ))
                        //         }
                        //     </div>
                        // </div>
                        <div className="ss_state_panel">
                            <div className="ss_state_panel_title" title={people[i]}>{people[i]}</div>
                            <div className="ss_state_panel_plan">目前进度</div>
                            <div className="ss_state_panel_planName" title={courseNow[i]}>{courseNow[i]}</div>
                            <div className="ss_state_panel_button" onClick={() => { this.get(pid[i], x, people[i]) }}>查看详情</div>
                        </div>
                    )) : <div style={{ display: 'flex', width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                        <img src={require('../assets/img/manage/null.png')} />
                    </div>
                }
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addClassModal}
                    onClose={() => {
                        this.setState({
                            addClassModal: false
                        })
                    }}
                >
                    <Fade in={addClassModal}>
                        <div className="sumModel">
                            <div className="title">
                                <h2>综合评分查看</h2>
                                <span onClick={() => { this.setState({ addClassModal: false }) }}><img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <div className="score_box">
                                {
                                    <div className="ss_score_controduce">
                                        <div className="ss_score_detail">
                                            <div className="ss_info_box">
                                                <div className="c_flex ss_stu_info">
                                                    <div className="sstitle">
                                                        <img style={{ width: '80px' }} src={require("../assets/img/toux.png")} />
                                                        <span>{ppeople}</span>
                                                    </div>
                                                    <div className="ssScore">
                                                        <span>{score[0] ? Math.round((score[0].testScore + score[0].sum + score[0].code) / 3) : "--"}</span>
                                                        <div>目前平均分</div>
                                                    </div>
                                                </div>
                                                <div className="ss_radar">
                                                    {score[0] ? <ReactEcharts
                                                        option={this.getOption()}
                                                        notMerge={true}
                                                        lazyUpdate={true}
                                                        onEvents={onEvents}
                                                        style={{ width: '100%', height: '100%' }}
                                                    /> : <div style={{ color: "#7c7c7c" }}>目前老师暂未评分哦</div>}
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ margin: "5px 0 0", color: "#a1a1a1" }}>学习进度</div>
                                                <div className="ss_state_box" style={{ height: "85px" }}>
                                                    {
                                                        pChapters && pChapters.map((k, index) => (
                                                            <div className="ss_state_poi" key={index}>
                                                                <div className="ss_line_box">
                                                                    {pChapters.length == index + 1 ? '' : <div className="ss_line"></div>}
                                                                    {k.isread ? <img src={require('../assets/img/manage/learned.png')} className="ss_line_dian" /> : <img src={require('../assets/img/manage/learn.png')} className="ss_line_dian" />}
                                                                </div>
                                                                <div className="ss_font" style={{ color: "#202020" }}>
                                                                    <span title={k.minutiaName}>{k.minutiaName}</span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {!score[0] && homework && <div className="c_flex ss_score_teacher">
                                            <div style={{ color: "#a1a1a1", fontSize: "16px" }}>作业提交情况</div>
                                            <div className="ss_score_homework_box">
                                                {homework && homework.map((v, i) => (
                                                    <div className={"ss_score_homework_panel " + (v.is == 1 ? "" :"sssh_is_box")}>
                                                        <span title={v.minutiaName}>{`【${v.typeName}】${v.minutiaName}`}</span>
                                                        <span className="ss_score_homework_is">{v.is == 1 ? "" : (<div><img src={require("../assets/img/manage/homes.png")} /><span>已提交</span></div>)}</span>
                                                        <span className={"ss_score_homework_none " + (v.is == 1 ? "" : "sssh_is")}>{v.is == 1 ? "未提交" : v.time}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>}
                                        {score[0] && <div className="c_flex ss_score_teacher">
                                            <div style={{ fontSize: "17px" }}>评价</div>
                                            <div style={{ marginTop: "10px" }}>
                                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "5px" }}>
                                                    <img src={require("../assets/img/manage/ttou.png")} style={{ width: "30px" }} />
                                                    <span style={{ marginLeft: "10px", fontSize: "16px" }}>{tname.tname ? tname.tname + "老师" : tname.teachername}</span>
                                                    <span style={{ marginLeft: "10px", fontSize: "13px", color: "#999" }}>{ut}</span>
                                                </div>
                                                <span style={{ marginLeft: "40px", maxHeight: "100px", overflow: "auto", fontSize: "14px" }}>{score[0] && (score[0].remarks ? score[0].remarks : "暂无评价")}</span>
                                            </div>
                                        </div>}
                                    </div>
                                }
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}


export default studentState
