import React, { Component } from 'react'
import axios from 'axios';
import '../css/studentTest.css'
import qs from 'qs';
import config from '../config'
import { TablePagination, Modal, Fade } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

export class studentTest extends Component {
    state = {
        rows: [],
        page: 0,
        SearchTitle: "",
        count: 0,
        open: false,
        delId: "",
        checkModal: false,
        checkJson: { },
        checktitle: "",
        checkedAnswer: [],
        testArray: [],
        testid: "",
        cid:"",
    }

    componentDidMount() {
        console.log(this.props)
        this.getData()
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            cid:nextProps.courseId
        },()=>{
            this.getData();
        })
    }

    getData = () => {
        const { page, SearchTitle, testid, cid } = this.state
        const { userId } = this.props
        var a = SearchTitle == "" ? " " : SearchTitle
        let params = {
            uid: userId,
            courseid: cid,
            page: page + 1
        }
        axios.defaults.withCredentials = true;
        // /${courseId}/${a}/${page + 1} config.env.edu
        var a = "http://localhost:7001/edu/"
        axios.post(`${config.env.edu}getTestById2`, qs.stringify(params)).then(res => {
            console.log(res)
            // if (res.data[0].length > 0) {
            var resA = res.data[0].map((x, i) => {
                var a = JSON.parse(x.chapters)
                for (let j = 0; j < a.length; j++) {
                    for (let k = 0; k < a[j].chapterData.length; k++) {
                        if (a[j].chapterData[k].typeName == "测验" && a[j].chapterData[k].id == x.chapterid) {
                            x.radio = a[j].chapterData[k]
                            console.log(x.radio)
                            break;
                        }
                    }
                }
                return x;
            })
            setTimeout(() => {
                this.setState({
                    rows: resA,
                    count: resA[0] ? resA[0].num : 0
                })
            }, 0);

            // }

        })
    }

    deleteData = () => {
        const { delId } = this.state
        axios.post(`${config.env.edu}deleteTest`, `mode=${delId}`).then(res => {
            if (res.status === 200) {
                window.U.Alert('删除成功')
                this.handleClose();
                this.getData()
            } else {
                this.handleClose();
                window.U.Alert('删除失败')
            }
        })
    }


    pageOnChange = (e, page) => {
        console.log(page)
        this.setState({
            page: page
        }, () => {
            this.getData();
        })

    }
    updateSTitle = (e) => {
        this.setState({
            SearchTitle: e.target.value
        })
    }

    openDel = (id) => {
        let _this = this
        window.U.UF.UI.confirm("确定删除此模板吗?", function () {
            _this.setState({
                // open: true,
                delId: id
            },()=>{
                _this.deleteData();
            })
        }, function () {
            return;
        })
    }

    openTest = (x) => {
        x.radio.checkedAnswer = JSON.parse(x.answer)
        this.setState({
            checkModal: true,
            checkJson: x.radio,
            checktitle: x.title,
            checkedAnswer: JSON.parse(x.answer),
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }
    search = () => {
        this.setState({
            page: 1,
        })
        this.getData()
    }

    setType = (e) => {
        var value = e.target.value;
        this.setState({
            testid: value
        }, () => {
            this.getData()
        })

    }

    searchM = (value) => {
        this.setState({
            testid: value
        }, () => {
            this.getData()
        })
    }

    searchS = (value) => {
        this.setState({
            SearchTitle: value
        }, () => {
            this.getData()
        })
    }


    render() {
        const { page, count, rows, SearchTitle, open, checkModal, checkJson, checktitle, checkedAnswer, testArray, testid  } = this.state;

        return (
            <div className="admin_table_block" >
                {/* <div className="pb_headT flex">
                    <div>
                        测试管理
                    </div>
                    <div className="admin_course_input">
                        <div className="admin_course_searchT admin_user_btnT" onClick={this.search}>搜索</div><input className="user_input"
                            placeholder="请输入课程标题或提交者"
                            style={{ float: "right", width: "200px", height: "40px", margin: "2px 12px 0px 0px", fontSize: "16px", textIndent: ".5em" }} value={SearchTitle} onChange={v => this.updateSTitle(v)} />
                    </div>
                </div> */}
                {/* <div className="search" style={{ padding: "0px 40px" }}>
                    <input placeholder="请输入章节测试标题" onChange={e => { this.setState({ SearchTitle: e.target.value }) }} /> <button style={{ cursor: "pointer" }} onClick={() => { this.getData(); }}>查询</button>
                </div> */}
                {/* <div style={{ padding: "0px 40px", display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
                     <div className="search sr_search" style={{ padding: "unset" }}>
                        <div className="sr_select">
                            <span>分数筛选：</span>
                            <select onChange={e => { this.setType(e) }} value={testid}>
                                <option value="">全部测验</option>
                                {
                                    testArray.length > 0 && testArray.map((v, i) => (
                                        <option value={v.id} key={i}>{v.minutiaName}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div>
                            <input placeholder="请输入姓名..." value={SearchTitle} style={{ height: "33px" }} onChange={e => { this.setState({ SearchTitle: e.target.value }) }} />
                            <button style={{ cursor: "pointer" }} onClick={() => { this.getData(); }}>查询</button>
                        </div>
                    </div>
                    <div style={{ fontSize: 15, color: "#797979" }}>
                        有{count2}人提交了作业 共{count}份
                    </div>
                </div> */}
                {rows.length > 0 ? <div className="hs_pub_tbT tableT" style={{ borderTop: "1px solid #ebebeb", margin:"10px 0px 0px 34px"}}>
                    <ul className="hs_dp_headT user_headT">
                        <li>
                            <div>课程标题</div>
                            <div>单元标题</div>
                            <div>章节测试标题</div>
                            {/* <div>提交者</div> */}
                            <div>提交时间</div>
                            <div>操作</div>
                        </li>
                    </ul>
                    <ul className="hs_dp_tbodyT user_tbodyT" style={{ height: "500px" }}>
                        {rows && rows.map((x, i) => (
                            <li key={i}>
                                <div>{x.title}</div>
                                <div>{x.ctitle}</div>
                                {/* onClick={() => { this.searchM(x.chapterid) }} */}
                                <div css={`cursor:pointer`} >{x.radio.minutiaName}</div>
                                {/* <div css={`cursor:pointer`} onClick={() => { this.searchS(x.alias ? x.alias : x.username) }}>{x.alias ? x.alias : x.username}</div> */}
                                <div>{x.time}</div>
                                <div><span className="hs_pub_updateBtnT" onClick={() => {
                                    this.openTest(x)
                                }}>查看</span><span className="hs_pub_deleteBtnT" onClick={() => { this.openDel(x.id) }}>删除</span></div>
                            </li>
                        ))}
                    </ul>
                </div> : <div style={{ textAlign: 'center', }}>
                    <img src={require('../assets/img/manage/null.png')} />
                </div>}
                <div>{count > 0 ? <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    rowsPerPage={10}
                    rowsPerPageOptions={[10]}
                    component="div"
                    onChangePage={this.pageOnChange}
                /> : ""}</div>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"是否删除"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" css={`width:500px`}>
                            是否删除此用户测验？
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.deleteData} color="primary">
                            确定
                        </Button>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            取消
                        </Button>
                    </DialogActions>
                </Dialog>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={checkModal}
                    onClose={() => {
                        this.setState({
                            checkModal: false
                        })
                    }}
                >
                    <Fade in={checkModal}>
                        <div className="checkModal">
                            <div className="title">
                                <h2>创建章节测试</h2>
                                <span onClick={() => { this.setState({ checkModal: false }) }}><img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <div style={{ height: "calc(100% - 156px)" }}>
                                <div className="checkTitle"><h2>{checktitle}</h2><h2>{checkJson.minutiaName}</h2></div>
                                <div className="checkContent">
                                    {
                                        checkJson.contentData && checkJson.contentData.map((x, i) => (
                                            <div className="checkBody" key={i}>
                                                <div className="checkBodyTitle">{i + 1}.{!x.type ? <span style={{ color: 'rgb(126, 126, 126)', fontSize: "14px" }}>【多选题】</span> : x.type === true ? <span style={{ color: 'rgb(126, 126, 126)', fontSize: "14px" }}>【单选题】</span> : <span style={{ color: 'rgb(126, 126, 126)', fontSize: "14px" }}>【填空题】</span>}{x.titleName}</div>
                                                {checkedAnswer && x.type === 1 ? <div style={{ margin: "10px 0" }}><span>我的答题：</span><span className={(x.answer.join("") == checkedAnswer[i]) ? "rightA" : "wrongA"}>{checkedAnswer[i]}</span></div> : <div div className="checkBodyC">
                                                    <div><input type="radio" disabled={checkedAnswer[i] && checkedAnswer[i].indexOf("A") == -1} className="my_sex" checked={checkedAnswer[i] && checkedAnswer[i].indexOf("A") != -1} /><span className={(x.answer.join("") == checkedAnswer[i]) ? "" : "cc"}><span></span></span>A.{x.radio[0]}</div>
                                                    <div><input type="radio" disabled={checkedAnswer[i] && checkedAnswer[i].indexOf("B") == -1} className="my_sex" checked={checkedAnswer[i] && checkedAnswer[i].indexOf("B") != -1} /><span className={(x.answer.join("") == checkedAnswer[i]) ? "" : "cc"}><span></span></span>B.{x.radio[1]}</div>
                                                    <div><input type="radio" disabled={checkedAnswer[i] && checkedAnswer[i].indexOf("C") == -1} className="my_sex" checked={checkedAnswer[i] && checkedAnswer[i].indexOf("C") != -1} /><span className={(x.answer.join("") == checkedAnswer[i]) ? "" : "cc"}><span></span></span>C.{x.radio[2]}</div>
                                                    <div><input type="radio" disabled={checkedAnswer[i] && checkedAnswer[i].indexOf("D") == -1} className="my_sex" checked={checkedAnswer[i] && checkedAnswer[i].indexOf("D") != -1} /><span className={(x.answer.join("") == checkedAnswer[i]) ? "" : "cc"}><span></span></span>D.{x.radio[3]}</div>
                                                </div>
                                                }
                                                <div><span>正确答案：</span><span>{x.answer}</span></div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="submit">
                                <button style={{ background: '#fff' }} onClick={() => { this.setState({ checkModal: false }) }}>关闭</button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}


export default studentTest
