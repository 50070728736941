import axios from "axios";
import qs from 'qs';

// 携带cookie
axios.defaults.withCredentials = true;
// 配置超时时间
axios.defaults.timeout = 100000;
// 请求前进行拦截
axios.interceptors.request.use(
    config => {
        return config
    },
    err => {
        console.log('请求超时');
        return Promise.reject(err);
    }
)

// 返回请求拦截
axios.interceptors.response.use(
    data => {
        return data;
    },
    // err => {
    //     console.log(err.response)
    //     if (err.response.status === 504) {
    //         console.log("服务器被吃了");
    //     } else if (err.response.status === 401) {
    //         console.log("登录信息失效了，请重新登录");
    //         // if (window.location.pathname.indexOf('/login') === -1) {
    //         //     window.location.href = '/login'
    //         // }

    //     } else if (err.response.status === 500) {
    //         console.log("服务器开小差了");
    //     }
    //     return Promise.reject(err)
    // }
)

var post = (url, data) => {
    let params = qs.stringify(data);
    return new Promise((resolve, reject) => {
        axios.post(url, params).then(res => {
            resolve(res)
        })
    })
}

var get = (url, data) => {
    let params = qs.stringify(data);
    return new Promise((resolve, reject) => {
        axios.get(url, params).then(res => {
            resolve(res)
        })
    })
}

export { post, get };