import React from 'react'
import styled from 'styled-components'
import { Route, Switch } from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
//components
// import CourseInfo from '../components/CourseInfoUpload'
import ChapterInfo from '../components/NewChapterUpload'
import Chapter from '../components/ChapterUpload'
import Result from '../components/Result'
import Newsteppers from '../components/Newsteppers'
import CourseInfo from '../components/NewCourseInfoUpload'

const Wrapper = styled.div`
  width:100%;
  overflow:hidden;

  justify-self:center;
  display: grid;
  grid-area: ${props => props.areaname};
  grid-template-rows:30px 100px 660px 10px;
  grid-template-columns: 15% 35% 35% 15%;
  grid-template-areas:
  '. title . .'
  '. stepper stepper .'
  '. content content .'
  '. . . .';
`;

const Title = styled.h2`
  color:#3D67BC;
  width:100%;
  height:100%;
  margin:0;
  padding:0;
  font-size:2em;
  display:'inline-block';
  margin-left:-115px;
`;

const UploadArea = styled.div`
  display: grid;
  margin:0;
  padding:0;
`;


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3D67BC'
    }
  },
  typography: {
    useNextVariants: true
  },
});

class NewCourseUpload extends React.Component {
  render() {
    return (
      <Wrapper areaname={this.props.areaname} >
        <MuiThemeProvider theme={theme}>
          <Title css={`grid-area:title ;`}>课程上传面板</Title>
          <Newsteppers 
            css={`grid-area:stepper ;`}
            steps={['课程信息', '章节信息', '上传完成']}
          ></Newsteppers>
          <UploadArea css={`grid-area:content; `}>
            <Switch>
              <Route
                path='/Newupload/result'
                render={(props) => (<Result {...props}/>)}
                // render={(props) => (<Result {...props} match={props} />)}
              />
              <Route
                path='/Newupload/chapter'
                component={ChapterInfo}
              />
              <Route component={CourseInfo} />
            </Switch>
          </UploadArea>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

export default NewCourseUpload;
