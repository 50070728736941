import React, { Component } from 'react'
import axios from 'axios';
import config from '../config'
import qs from 'qs';
import '../css/studentJuri.css'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Modal, Table, Paper, TableHead, TableRow, TableBody, TableCell, TablePagination, Fade } from '@material-ui/core';
import XLSX from "xlsx";

let eye = require("../assets/img/manage/eye.png")
let eyeC = require("../assets/img/manage/eyeC.png")
const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const tableStyle = {
    border: '1px solid #eee'
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F4F6",
        color: '#000',
        fontSize: 18,
        fontWeight: "600",
        padding: 0
    },
    body: {
        fontSize: 18,
        padding: 0
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: "#f1f1f1",
        },
    },
}))(TableRow);

const table = {
    minWidth: 700,
    padding: "0 30px"
}
const regEmail = new RegExp("[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+$");
export class studentJuri extends Component {
    state = {
        rows: [
        ],  // 学生数据
        rowsPerPage: 10,
        page: 0,
        count: 0,
        juri: "",
        addUserModal: false,
        addUserModal2: false,
        classJson: [],
        name: '',    //用户名
        password: '',
        contentS: "",
        studentArray: [],
        studentJson: [],
        isPassword: true,
        cid: ""
    }

    componentWillReceiveProps(nextProps) {
        var np = nextProps.juri == null ? "" : nextProps.juri
        var p = this.props.juri == null ? "" : this.props.juri
        if (p.toString() != np.toString() || this.props.courseId != nextProps.courseId) {
            console.log(this.props)
            this.setState({
                juri: np ? np : "",
                cid: nextProps.courseId
            }, () => {
                this.getData()
            })
            var _number = window.U.UF.C.queryString("number") || '';
            this.props.getCourse(_number)
        }
    }

    getData = () => {
        const { page, rowsPerPage, search, cid } = this.state
        const params = {
            page: page + 1,
            num: rowsPerPage,
            cid: cid,
            content: search ? search : ""
        }
        axios.defaults.withCredentials = true;
        axios.post(`${config.env.edu}getcoursetudents`, qs.stringify(params)).then(res => {
            console.log(res)
            this.setState({
                rows: res.data[0],
                count: res.data[0].length ? res.data[0][0].num : 0
            })
        })
    }

    // 註冊郵箱驗證
    registereEmail = e => {
        let str = e.target.value.replace(/(^\s*)|(\s*$)/g, "")
        if (!regEmail.test(str)) {
            window.U.Alert(this.props.language.registered_email_regular);
        }
        // else {
        //   this.setState({
        //     registereEmail: e.target.value
        //   })
        // }
    }

    authority(id) {
        var juri = this.state.juri
        if (juri == "" || juri == null) {
            juri = id
        } else {
            juri = juri.split(",")
            if (juri.indexOf(id) != -1) {
                juri.splice(juri.indexOf(id), 1)
            } else {
                juri.push(id)
                juri = juri.join(",")
            }
        }
        this.setState({
            juri: juri
        })
        var _number = window.U.UF.C.queryString("number") || '';
        axios.post(`${config.env.edu}admin/updateCourseojur`,
            `mode=${encodeURIComponent(encodeURIComponent(juri))},${this.state.cid}`
        ).then((res => {
            this.props.getCourse(_number)
        })).catch((err) => {

        })
    }

    handleChangePage = (event, newPage) => {
        console.log(newPage)
        this.setState({
            page: newPage
        }, () => {
            this.getData()
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: this.state.page,
            rowsPerPage: +event.target.value
        }, () => {
            this.getData()
        })
    }

    getClass = () => {
        axios.post(`${config.env.edu}selectAllClass/${this.props.userId}`).then(res => {
            console.log(res)
            if (res.data[0].length > 0) {
                this.setState({
                    classJson: res.data[0]
                })
            }
        })
    }

    // 删除
    del = (e, id, userid) => {
        var _this = this
        window.U.UF.UI.confirm("确定删除此学生吗?", function () {
            axios.post(`${config.env.edu}delcoursestudent/`, `mode=${id}`).then(v => {
                // if (v.data === 1) {
                _this.getData()
                if (_this.state.juri.indexOf(userid) != -1) {
                    _this.authority(userid);
                }
                window.U.Alert('删除成功')
                // } else {
                //     window.U.Alert('删除失败')
                // }
            })
        }, function () {
            return;
        })
    }
    addStudentSubmit = () => {
        const { studentArray } = this.state
        if (studentArray.length == 0) {
            window.U.Alert("请选择需要添加的学生");
            return;
        }
        let params = {
            users: studentArray,
            classId: this.state.cid
        }
        axios.post(`${config.env.edu}addcoursestudent`, qs.stringify(params)).then(res => {
            console.log(res)
            window.U.Alert('添加成功')
            var _number = window.U.UF.C.queryString("number") || '';
            setTimeout(() => {
                this.props.getCourse(_number)
                this.getData()
            }, studentArray.length * 100);
            this.setState({
                addUserModal: false,
                studentArray: [],
                contentS: "",

            })
        })
    }

    getStudent = () => {
        const { contentS } = this.state
        let params = {
            tid: this.state.cid,
            content: contentS
        }
        axios.post(`${config.env.edu}getStudent2`, qs.stringify(params)).then(res => {
            console.log(res)
            // if (res.data[0].length > 0) {
            this.setState({
                studentJson: res.data[0]
            })
            // }
        })
    }
    addSJosn = (uid) => {
        let { studentArray } = this.state
        if (studentArray.indexOf(uid) !== -1) {
            studentArray.splice(studentArray.indexOf(uid), 1)
        } else {
            studentArray.push(uid)
        }

        this.setState({
            studentArray: studentArray
        })
    }

    // 添加学生
    addStudent = () => {
        // this.getClass();
        this.getStudent();
        this.setState({
            addUserModal: true
        })
    }

    addStudent2 = () => {
        this.getClass();
        this.setState({
            addUserModal2: true
        })
    }

    // 提交
    submit = () => {
        const { name, password } = this.state
        var classc = window.$("#classid")[0];
        var classid = classc.selectedOptions[classc.selectedIndex].id;
        let names = name.replace(/(^\s*)|(\s*$)/g, "")
        if (!names) {
            window.U.Alert("用户名必填");
            return;
        }
        if (!regEmail.test(names)) {
            window.U.Alert(this.props.language.registered_email_regular);
            return;
        }
        if (classid == "") {
            window.U.Alert("请选择班级");
            return;
        }
        let obj = {
            username: names,
            password: password,
            class: classid
        }
        let params = {
            userId: this.props.userId,
            users: JSON.stringify([obj])
        }
        axios.defaults.withCredentials = true;
        var a = "http://localhost:7001/edu/" // config.env.edu
        axios.post(`${config.env.edu}batchRegistration`, qs.stringify(params)).then(res => {
            if (res.status === 200) {
                this.setState({
                    addUserModal2: false
                }, () => {
                    this.getData()
                })
                window.U.Alert('添加成功')
            } else {
                window.U.Alert('添加失败')
            }
        }).catch(err => {
            window.U.Alert('添加失败')
        })
        // batchRegistration
    }

    downLoad = () => {
        let arr = [
            ['用户名', '密码', '班级'],
        ]
        // 将数组转化为标签页 
        var ws = XLSX.utils.aoa_to_sheet(arr);
        // 创建工作薄
        var wb = XLSX.utils.book_new()
        // 将标签页插入到工作薄里
        XLSX.utils.book_append_sheet(wb, ws)
        // 将工作薄导出为excel文件
        XLSX.writeFile(wb, '批量上传样例.xlsx');
    }

    importExcel = e => {
        var input = e.target.files;
        if (!input) {
            return;
        }
        // alert(obj.files[0].name);文件名
        var f = input[0];
        var reader = new FileReader();
        let _this = this

        reader.onload = function (e) {
            var data = e.target.result;
            var wb = XLSX.read(data, {
                type: 'binary' //以二进制的方式读取
            });

            var sheet0 = wb.Sheets[wb.SheetNames[0]];//sheet0代表excel表格中的第一页
            var str = XLSX.utils.sheet_to_json(sheet0);//利用接口实现转换。
            var templates = new Array();
            var str1 = input[0].name;
            templates = str1.split(".");//将导入文件名去掉后缀
            str = str.map(x => {
                let obj = {
                    username: x.用户名,
                    password: `${x.密码}`,
                    class: x.班级,
                }
                return obj
            })
            let params = {
                users: JSON.stringify(str),
                userId: _this.props.userId
            }
            axios.defaults.withCredentials = true;
            debugger
            var a = "http://localhost:7001/edu/" //config.env.edu
            axios.post(`${config.env.edu}batchRegistration`, qs.stringify(params)).then(res => {
                console.log(res.status === 200)
                if (res.status === 200) {
                    window.U.Alert('添加成功')
                    _this.getData()
                } else {
                    window.U.Alert('添加失败')
                }
            }).catch(err => {
                console.log(err)
                window.U.Alert('添加失败')
            })
            // window.localStorage.setItem(templates[0], JSON.stringify(str))//存入localStorage 中

        }
        reader.readAsBinaryString(f);
    }
    render() {
        const { rows, page, rowsPerPage, count, juri, addUserModal, addUserModal2, classJson, name, password, studentJson, contentS, studentArray, isPassword } = this.state;

        return (
            <div style={table} component={Paper}>
                <div className="student" style={{ display: "flex", flexDirection: "row-reverse", marginBottom: "15px" }}>
                    <button onClick={() => { this.addStudent() }}>添加学生</button>
                </div>
                {rows.length > 0 ? <Table style={tableStyle} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">选择</StyledTableCell>
                            <StyledTableCell align="center">用户名</StyledTableCell>
                            <StyledTableCell align="center">昵称</StyledTableCell>
                            <StyledTableCell align="center">性别</StyledTableCell>
                            <StyledTableCell align="center">班级</StyledTableCell>
                            <StyledTableCell align="center">手机号</StyledTableCell>
                            <StyledTableCell align="center">操作</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={rows.name}>
                                <StyledTableCell align="center"><input type="checkbox" onClick={() => { this.authority(row.userid) }} checked={juri.indexOf(row.userid) != -1}></input></StyledTableCell>
                                <StyledTableCell align="center" component="path" scope="row">{row.username}</StyledTableCell>
                                <StyledTableCell align="center">{row.alias ? row.alias : "暂无"}</StyledTableCell>
                                <StyledTableCell align="center">{row.sex ? (row.sex == 0 ? '女' : '男') : '暂无'}</StyledTableCell>
                                <StyledTableCell align="center">{row.classname ? row.classname : '暂无'}</StyledTableCell>
                                <StyledTableCell align="center">{row.phonenumber ? row.phonenumber : '暂无'}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <span style={{ color: '#78ACEE', cursor: 'pointer' }} onClick={(e) => this.del(e, row.id, row.userid)}>删除</span>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table> :
                    <div style={{ textAlign: 'center', }}>
                        <img src={require('../assets/img/manage/null.png')} />
                    </div>}
                {rows.length > 0 && <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    labelRowsPerPage="每页显示条数"
                />}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addUserModal}
                    onClose={() => {
                        this.setState({
                            addUserModal: false
                        })
                    }}
                >
                    <Fade in={addUserModal}>
                        <div className="addStudentModal" style={{ width: "480px" }}>
                            <div>
                                <h2>添加学生</h2>
                                <span className="sspan" onClick={() => { this.setState({ addUserModal: false }) }}><img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <ul>
                                <li>
                                    <div className="sj_panel">
                                        <div className="sj_panel_search" >
                                            <input type="text" value={contentS} placeholder="输入需要搜索的账户、名字、班级" onChange={(e) => {
                                                this.setState({
                                                    contentS: e.target.value
                                                })
                                            }} />
                                            <span onClick={() => { this.getStudent() }}>搜索</span>
                                        </div>
                                        <div className="sj_panel_users">
                                            {studentJson.length > 0 ? studentJson.map((v, i) => (
                                                <div className="sj_panel_user" key={i} onClick={() => { this.addSJosn(v.userid) }}>
                                                    <input type="checkbox" checked={studentArray.indexOf(v.userid) !== -1} />
                                                    <span title={v.alias ? v.alias : "暂无姓名"}>{(v.alias ? v.alias : "暂无姓名")}</span>
                                                    <span title={v.username} style={{ marginLeft: "10px" }}>{v.username}</span>
                                                    <span title={v.classname} style={{ marginLeft: "10px" }}>{v.classname}</span>
                                                </div>
                                            )) :
                                                <div className="sj_panel_user">
                                                    暂无学生
                                                </div>}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="submit">
                                <button style={{ background: '#F23A3A' }} onClick={() => { this.addStudentSubmit() }}>确定</button>
                                <button style={{ background: '#AAA' }} onClick={() => { this.setState({ addUserModal: false }) }}>取消</button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addUserModal2}
                    onClose={() => {
                        this.setState({
                            addUserModal2: false
                        })
                    }}
                >
                    <Fade in={addUserModal2}>
                        <div className="addStudentModal">
                            <div>
                                <h2>添加学生</h2>
                                <span className="sspan" onClick={() => { this.setState({ addUserModal2: false }) }}> <img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <ul>
                                <li>
                                    <span className="sspan">用户名：</span>
                                    <input className="inputs" value={name} placeholder='请输入用户名' onBlur={this.registereEmail} onChange={e => { this.setState({ name: e.target.value }) }} /> &nbsp;
                                    <div style={{ marginTop: '5px' }}><i style={{ color: 'red' }}>*&nbsp;</i>学生账号平台使用的账户与名字（请使用邮箱）</div>
                                </li>
                                <li>
                                    <span className="sspan">密码：</span>
                                    {isPassword ?
                                        <input className="inputs" type="password" value={password} placeholder='请输入密码' onChange={e => { this.setState({ password: e.target.value }) }} style={{ paddingRight: "45px" }} /> :
                                        <input className="inputs" type="text" value={password} placeholder='请输入密码' onChange={e => { this.setState({ password: e.target.value }) }} style={{ paddingRight: "45px" }} />
                                    }
                                    {isPassword ?
                                        <img src={eye} class="eyeClass" onClick={() => { this.setState({ isPassword: false }) }} /> :
                                        <img src={eyeC} class="eyeClass" onClick={() => { this.setState({ isPassword: true }) }} />
                                    }

                                </li>
                                <li>
                                    <span className="sspan">班级选择：</span>
                                    {/* <input className="inputs" type="password" value={password} placeholder='请输入密码' onChange={e => { this.setState({ password: e.target.value }) }} /> */}
                                    <select className="inputs" id="classid">
                                        {
                                            classJson.length > 0 ?
                                                classJson.map((v, i) => (
                                                    <option id={v.id}>{v.classname}</option>
                                                )) : <option>暂无班级</option>
                                        }
                                    </select>
                                </li>
                                {/* <li>
                                    <span>&nbsp;性&nbsp;别</span>：
                                    <label onClick={this.radio}>
                                        <input type="radio" name='set' value="1" />&nbsp;&nbsp;男
                                        <input type="radio" name='set' value="2" style={{ marginLeft: 30 }} />&nbsp;&nbsp;女
                                    </label>
                                </li>
                                <li>
                                    <span>手机号</span>：
                                    <input className="inputs" placeholder='请输入手机号' onChange={e => { this.setState({ phone: e.target.value }) }} />
                                </li>
                                <li>
                                    <span>&nbsp;邮&nbsp;箱</span>：
                                    <input className="inputs" placeholder='请输入邮箱' onChange={e => { this.setState({ email: e.target.value }) }} />
                                </li> */}
                            </ul>
                            <div className="submit">
                                <button style={{ background: '#F23A3A' }} onClick={this.submit}>确定</button>
                                <button style={{ background: '#AAA' }} onClick={() => { this.setState({ addUserModal2: false }) }}>取消</button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}


export default studentJuri
