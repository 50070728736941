// 导入
import { post, get } from './http';
import config from '../config'

// 登录
export function getLogin(data) {
    return post(`//login.cocorobo.cn/api/user`, data)
}

// 获取学校列表
export function getSchoolLists(data) {
    return post(`${config.env.edu}admin/selectorganize`, data)
}

// 滑动验证
export function geetestData(data) {
    return get(`${config.env.api}geetest/pc-geetest/register`, data)

}

// 获取邮箱验证码
export function getCode(data){
    return post(`${config.env.api}activate/emailverification`,data)
}

// 微信code
export function getWeixinCode(data){
    return get(`//weixin.cocorobo.cn/login.php`,data)
}