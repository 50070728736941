import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import axios from 'axios'
import {Redirect} from 'react-router-dom'
import _ from '../utils'

import {nextStep, lastStep } from '../actions/upload/index'

import { Button } from '@material-ui/core'

import Subtitle from './Subtitle'
import ChapterUploadPanel from './ChapterUploadPanel'

import config from '../config'

const Wrapper = styled.div`
    margin:0;
    padding:0;
    overflow:hidden;
	width: 100%;
	display: grid;
    grid-template-columns: 17.5% 30% 5% 30% 17.5%;
	grid-template-rows:30px 4 35px;
	grid-template-areas:
	'. title . . .'
	'. container container container .'
	'. submit . reset .';
`;

const Container = styled.div`
	grid-area: container;
	overflow:auto;
`;
const Submit = styled.div`
    grid-area: submit;
`;
const Reset = styled.div`
	grid-area: reset;
`;

const mapStateToProps = (state) => {
	return {
        courseId:state.upload.courseId,
        titles:state.upload.chapters.titles,
        length:state.upload.chapters.length
	}
}
const mapDispatchToProps = dispatch => {
	return {
		handleBack(activeStep){
			dispatch(lastStep(activeStep))
		},

		handleNext(activeStep){
			dispatch(nextStep(activeStep))
		}
	}
}

class ChapterInfo extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			chapters:null
		}
	}
	Reset=()=>{
		this.ChapterUploadPanel.reset();
	}
	getChapterVal=(val)=>{
		this.setState({
			chapters:val
		})
	}
	SubmitChapterInfo=()=>{
		const {courseId}=this.props;
		let chaptersList=this.state.chapters;
		const Chapters = chaptersList.reduce((prev, { id, title })=>
			Object.assign(prev, { [courseId+'_'+(id)]: title }), {})
		// const Chapters = chaptersList.map((item)=>[item.title])
		chaptersList.forEach( (element,index) => {
			let attachmentUrl=JSON.parse(`[${element.attachmentUrl}]`)
			let clipUrl=element.clipUrl.split(',');
			let thumbUrl=element.thumbUrl.split(',');
			let slideUrl =element.slideUrl.split(',');
			
			axios({
				method:'post',
				url:config.env.server+'/chapter/upload',
				headers:{
					'Authorization': 'yezicoco',
					'Content-Type':'application/json'
				},
				data:{
					"attachmentUrl" : attachmentUrl, 
					"avatar" : "//staging.cocorobo.hk/assets/avatar/ccrb.png", 
					"clipUrl" : clipUrl, 
					"slideUrl" : slideUrl, 
					"tag" : [], 
					"thumbUrl" : thumbUrl, 
					"bg" :element.bg,
					"chapterId" : courseId+'_'+(index+1),
					"courseId" : courseId, 
					"intro" : element.intro, 
					"title" :	element.title, 
					"uploader" : "R&D", 
					"organize" : "CocoRobo",
					"chapters" :Chapters 
				}
			})
		});
		this.props.handleNext(2)
		this.props.history.push('/Newupload/result')
	}
	render() {
		if(!this.props.courseId){
			return <Redirect to ='/course/Newupload'/>
		}
		return (
			<Wrapper>
				<div css={`
					grid-area: title;
                    margin-left: -385px;
				`}>
					<Subtitle
						name="章节信息"
					/>
				</div>
                <Container>
					<ChapterUploadPanel 
						ref={(ChapterUploadPanel) => this.ChapterUploadPanel = ChapterUploadPanel} 
						getChapterVal={this.getChapterVal}
						/>
                </Container>
                <Submit>
                <Button color="primary" variant="contained" onClick={this.SubmitChapterInfo}>确认上传章节信息</Button>
                </Submit>
                <Reset>
                <Button color="primary" variant="contained" onClick={this.Reset}>重新填写</Button>
                </Reset>
			</Wrapper>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterInfo);
