import React from 'react'
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import '../css/studentRank.css'
import { TablePagination, Modal, Fade } from '@material-ui/core';
import ReactEcharts from "echarts-for-react";
import * as echarts from 'echarts';

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

class studentRank extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            page: 0,
            SearchTitle: "",
            count: 0,
            open: false,
            delId: "",
            checkModal: false,
            checkJson: { },
            type: 0,
            score: [],
            addClassModal: false,
            pChapters: [],
            ppeople: "",
            ut: "",
            tname: { },
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.courseId != nextProps.courseId) {
            console.log(this.props)
            this.setState({
                page: 0
            }, () => {
                this.getData()
            })
            this.getData()
        }
    }
    getData = () => {
        const { page, SearchTitle, type } = this.state
        const { courseId } = this.props
        let params = {
            cid: courseId,
            content: SearchTitle,
            type: type,
            page: page + 1
        }
        axios.post(`${config.env.edu}getRank`, qs.stringify(params)).then(res => {
            console.log(res)
            setTimeout(() => {
                this.setState({
                    rows: res.data[0],
                    count: res.data[0].length ? res.data[0][0].num : 0
                })
            }, 0);
        })
    }
    deleteData = () => {
        const { delId } = this.state
        axios.post(`${config.env.edu}deleteTest`, `mode=${delId}`).then(res => {
            if (res.status === 200) {
                window.U.Alert('删除成功')
                this.handleClose();
                this.getData()
            } else {
                this.handleClose();
                window.U.Alert('删除失败')
            }
        })
    }

    pageOnChange = (e, page) => {
        console.log(page)
        this.setState({
            page: page
        }, () => {
            this.getData()
        })
    }

    updateSTitle = (e) => {
        this.setState({
            SearchTitle: e.target.value
        })
    }

    openDel = (id) => {
        this.setState({
            open: true,
            delId: id
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }
    search = () => {
        this.setState({
            page: 1,
        })
        this.getData()
    }

    setType = (e) => {
        var value = e.target.value;
        this.setState({
            type: value
        }, () => {
            this.getData()
        })

    }

    getDeatail = (uid, people) => {
        axios.get(`${config.env.edu}getSummaryById2/${this.props.courseId}/${uid}`).then(res => {
            var course = res.data[0][0]
            var _json2 = []
            var state = JSON.parse(res.data[0][0].state)
            for (let cj = 0; cj < state.length; cj++) {
                for (let k = 0; k < state[cj].chapterData.length; k++) {
                    state[cj].chapterData[k].cd = state[cj].chapterid
                    _json2.push(state[cj].chapterData[k])
                }
            }

            var score = JSON.parse(res.data[0][0].score)
            var ut = ""
            if (score) {
                score.testScore = this.sum(score.testScore) / score.testScore.length
                score.sum = score.sum * 20
                score.code = score.code * 20
                ut = res.data[0][0].updatetime
            }
            this.setState({
                score: score ? [score] : [],
                addClassModal: true,
                pChapters: _json2,
                ppeople: people,
                ut: ut,
                tname: { tname: res.data[0][0].tname, teachername: res.data[0][0].teachername }
            })
            console.log(_json2);
        })
    }
    /**
    * @description 配置图表
    * @returns 
    * @memberof EchartsRadar
    */
    getOption() {
        const { score } = this.state
        return {
            title: {
                text: ''
            },
            //点击提示标签
            // tooltip: {},
            radar: {
                //雷达图绘制类型，支持 'polygon' 和 'circle' [ default: 'polygon' ]
                shape: 'polygon',
                splitNumber: 4,
                center: ['50%', '60%'],
                radius: '65%',
                //指示器名称和指示器轴的距离。[ default: 15 ]
                nameGap: 5,
                triggerEvent: true,
                name: {
                    textStyle: {
                        color: '#999',
                        backgroundColor: 'transparent'
                        // borderRadius: 3,
                        // padding: [3, 5]
                    },
                    formatter: function (value, indicator) {
                        value = value.replace(/\S{5}/g, function (match) {
                            return match + '\n'
                        })
                        // value = value + '\n' + indicator.value;
                        return '{a|' + value + '}' + '\n' + '{b|' + indicator.value + "分" + '}'
                        // return `{a|${value}(${indicator.value})分}`
                    },
                    //富文本编辑 修改文字展示样式
                    rich: {
                        a: {
                            color: "#999",
                            fontSize: 12,
                            align: "center",
                            width: "200px"
                        },
                        b: {
                            color: "#333",
                            fontSize: 17,
                            align: "center"
                        }
                    }
                },
                // 设置雷达图中间射线的颜色
                axisLine: {
                    lineStyle: {
                        color: '#ddd',
                    },
                },
                indicator: [
                    { "name": "测验答题分", "value": Math.round(score[0].testScore), "max": 100 },
                    { "name": "编程能力分", "value": score[0].code, "max": 100 },
                    { "name": "项目实践分", "value": score[0].sum, "max": 100 },
                ],
                //雷达图背景的颜色，在这儿随便设置了一个颜色，完全不透明度为0，就实现了透明背景
                splitArea: {
                    show: false,
                    areaStyle: {
                        color: 'rgba(255,0,0,0)', // 图表背景的颜色
                    },
                }
            },
            series: [{
                name: '分数',
                type: 'radar',
                //显示雷达图选中背景
                areaStyle: { normal: { } },
                data: [
                    {
                        value: [Math.round(score[0].testScore), score[0].code, score[0].sum],
                        // 设置区域边框和区域的颜色
                        itemStyle: {
                            normal: {
                                //雷达图背景渐变设置
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0.5,
                                    color: 'rgba(48,107, 231, 1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(73,168, 255, 0.7)'
                                }]),
                                //去除刻度
                                opacity: 0,
                                //雷达图边线样式
                                lineStyle: {
                                    width: 0,
                                    color: '#306BE7',
                                },
                            },
                        },
                    }
                ]
            }]
        };
    }

    /**
        * @description 雷达图选中区域点击事件和外部显示标签点击事件
        * @param {any} param 
        * @param {any} echarts 
        * @memberof EchartsRadar
        */
    onChartClick(param, echarts) {
        console.log(param)
    }
    /**
     * @description 点击legend事件
     * @param {any} param 
     * @param {any} echarts 
     * @memberof EchartsRadar
     */
    onChartLegendselectchanged(param, echarts) {
        console.log(param)
    }

    sum = (arr) => {
        var s = 0;
        arr.forEach(function (val, idx, arr) {
            s += val;
        }, 0);

        return s;
    };

    render() {
        const { page, count, rows, SearchTitle, open, score,
            addClassModal,
            pChapters,
            ppeople,
            ut, tname } = this.state

        let onEvents = {
            'click': this.onChartClick.bind(this),
            'legendselectchanged': this.onChartLegendselectchanged.bind(this)
        }
        return (
            <div className="admin_table_block" >
                {/* <div className="pb_headT flex">
                    <div>
                        编程作业
                    </div>
                    <div className="admin_course_input">
                        <div className="admin_course_searchT admin_user_btnT" onClick={this.search}>搜索</div><input className="user_input"
                            placeholder="请输入课程标题或提交者"
                            style={{ float: "right", width: "200px", height: "40px", margin: "2px 12px 0px 0px", fontSize: "16px", textIndent: ".5em" }} value={SearchTitle} onChange={v => this.updateSTitle(v)} />
                    </div>
                </div> */}
                <div className="search sr_search" style={{ padding: "0px 40px" }}>
                    <div className="sr_select">
                        <span>分数筛选：</span>
                        <select onChange={e => { this.setType(e) }}>
                            <option value="0">全部分数</option>
                            <option value="1">测验答题分</option>
                            <option value="2">编程能力分</option>
                            <option value="3">项目实践分</option>
                        </select>
                    </div>
                    <div><input placeholder="请输入姓名..." value={SearchTitle} style={{ height: "33px" }} onChange={e => { this.setState({ SearchTitle: e.target.value }) }} /> <button style={{ cursor: "pointer" }} onClick={() => { this.getData(); }}>查询</button></div>
                </div>
                {rows.length > 0 ? <div className="hs_pub_tbT tableT" style={{ height: "420px" }}>
                    <ul className="hs_dp_headT user_headT sr_headT">
                        <li>
                            <div>排名</div>
                            <div>姓名</div>
                            <div>测验答题分</div>
                            <div>编程能力分</div>
                            <div>项目实践分</div>
                            <div>平均分</div>
                            <div>操作</div>
                        </li>
                    </ul>
                    <ul className="hs_dp_tbodyT user_tbodyT sr_tbodyT">
                        {rows && rows.map((x, i) => (
                            <li key={i}>
                                <div>{x.order}</div>
                                <div>{x.alias ? x.alias : x.username}</div>
                                <div>{x.testScore}</div>
                                <div>{x.code * 20}</div>
                                <div>{x.sum * 20}</div>
                                <div>{Math.round(parseInt(x.zScore) / 3)}</div>
                                <div><span className="hs_pub_deleteBtnT" onClick={() => { this.getDeatail(x.userid, x.alias ? x.alias : x.username) }}>查看详情</span></div>
                            </li>
                        ))}
                    </ul>
                </div> : <div style={{ textAlign: 'center', }}>
                    <img src={require('../assets/img/manage/null.png')} />
                </div>}
                <div>{count > 0 ? <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    rowsPerPage={10}
                    rowsPerPageOptions={[10]}
                    onChangePage={this.pageOnChange}
                /> : ""}</div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addClassModal}
                    onClose={() => {
                        this.setState({
                            addClassModal: false
                        })
                    }}
                >
                    <Fade in={addClassModal}>
                        <div className="sumModel">
                            <div className="title">
                                <h2>综合评分查看</h2>
                                <span onClick={() => { this.setState({ addClassModal: false }) }}><img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <div className="score_box">
                                {
                                    <div className="ss_score_controduce">
                                        <div className="ss_score_detail">
                                            <div className="ss_info_box">
                                                <div className="c_flex ss_stu_info">
                                                    <div className="sstitle">
                                                        <img style={{ width: '80px' }} src={require("../assets/img/toux.png")} />
                                                        <span>{ppeople}</span>
                                                    </div>
                                                    <div className="ssScore">
                                                        <span>{score[0] ? Math.round((score[0].testScore + score[0].sum + score[0].code) / 3) : "--"}</span>
                                                        <div>目前平均分</div>
                                                    </div>
                                                </div>
                                                <div className="ss_radar">
                                                    {score[0] ? <ReactEcharts
                                                        option={this.getOption()}
                                                        notMerge={true}
                                                        lazyUpdate={true}
                                                        onEvents={onEvents}
                                                        style={{ width: '100%', height: '100%' }}
                                                    /> : <div style={{ color: "#7c7c7c" }}>目前老师暂未评分哦</div>}
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ margin: "5px 0 0", color: "#a1a1a1" }}>学习进度</div>
                                                <div className="ss_state_box" style={{ height: "85px" }}>
                                                    {
                                                        pChapters && pChapters.map((k, index) => (
                                                            <div className="ss_state_poi" key={index}>
                                                                <div className="ss_line_box">
                                                                    {pChapters.length == index + 1 ? '' : <div className="ss_line"></div>}
                                                                    {k.isread ? <img src={require('../assets/img/manage/learned.png')} className="ss_line_dian" /> : <img src={require('../assets/img/manage/learn.png')} className="ss_line_dian" />}
                                                                </div>
                                                                <div className="ss_font" style={{ color: "#202020" }}>
                                                                    <span title={k.minutiaName}>{k.minutiaName}</span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {score[0] && <div className="c_flex ss_score_teacher">
                                            <div style={{ fontSize: "17px" }}>评价</div>
                                            <div style={{ marginTop: "10px" }}>
                                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "5px" }}>
                                                    <img src={require("../assets/img/manage/ttou.png")} style={{ width: "30px" }} />
                                                    <span style={{ marginLeft: "10px", fontSize: "16px" }}>{tname.tname ? tname.tname + "老师" : tname.teachername}</span>
                                                    <span style={{ marginLeft: "10px", fontSize: "13px", color: "#999" }}>{ut}</span>
                                                </div>
                                                <span style={{ marginLeft: "40px", maxHeight: "100px", overflow: "auto", fontSize: "14px" }}>{score[0] && (score[0].remarks ? score[0].remarks : "暂无评价")}</span>
                                            </div>
                                        </div>}
                                    </div>
                                }
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}

export default studentRank
