import React from 'react'
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import '../css/homework.css'
import { TablePagination, Modal, Fade } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

class homework extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            page: 1,
            SearchTitle: "",
            count: 0,
            open: false,
            delId: "",
            checkModal: "",
            checkJson: {},
        }
    }

    componentDidMount() {
        console.log(this.props)
        this.setState({
            page:1
        },()=>{
            this.getData()
        })
    }
    getData = () => {
        const { page, SearchTitle } = this.state
        const { userId } = this.props
        var a = SearchTitle == "" ? " " : SearchTitle
        axios.defaults.withCredentials = true;
        axios.get(`${config.env.edu}getHomework/${userId}/${a}/${page == 0 ? 1 : page}`).then(res => {
            console.log(res)
            var resA = res.data[0].map((x, i) => {
                var a = JSON.parse(x.chapters)
                ccc: for (let j = 0; j < a.length; j++) {
                    for (let k = 0; k < a[j].chapterData.length; k++) {
                        if (a[j].chapterData[k].typeName == "编程" && a[j].chapterData[k].id == x.chapterid) {
                            x.radio = a[j].chapterData[k]
                            console.log(x.radio)
                            break ccc;
                        }
                    }
                }
                return x;
            })
            this.setState({
                rows: resA,
                count: resA[0] ? resA[0].num : 0
            })
        })
    }
    deleteData = () => {
        const { delId } = this.state
        axios.post(`${config.env.edu}deleteTest`, `mode=${delId}`).then(res => {
            if (res.status === 200) {
                window.U.Alert('删除成功')
                this.handleClose();
                this.getData()
            } else {
                this.handleClose();
                window.U.Alert('删除失败')
            }
        })
    }

    pageOnChange = (e, page) => {
        console.log(page)
        this.setState({
            page: page
        }, () => {
            this.getData()
        })
    }

    updateSTitle = (e) => {
        this.setState({
            SearchTitle: e.target.value
        })
    }

    openDel = (id) => {
        this.setState({
            open: true,
            delId: id
        })
    }

    openTest = (x) => {
        if (!x) {
            window.alert("该学生未上传图片作业")
            return;
        }
        window.open(x)
    }

    downloadFile = (content, filename) => {
        var a = document.createElement('a')
        var blob = new Blob([content])
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
    }

    openTest2 = (x) => {
        if(!x){
            window.alert("该学生未上传xml作业")
            return;
        }
        var url = x // demo图片
        var a = url.substr(url.lastIndexOf("/")).slice(1)
        var _this = this
        _this.ajax(url, function (xhr) {
            // + '.' + url.replace(/(.*\.)/, '')
            var filename = a  // 自定义文件名+后缀
            _this.downloadFile(xhr.response, filename)
        }, {
            responseType: 'blob'
        })
    }

    ajax = (url, callback, options) => {
        window.URL = window.URL || window.webkitURL
        var xhr = new XMLHttpRequest()
        xhr.open('get', url, true)
        if (options.responseType) {
            xhr.responseType = options.responseType
        }
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                callback(xhr)
            }
        }
        xhr.send()
    }
    handleClose = () => {
        this.setState({
            open: false
        })
    }
    search = () => {
        this.setState({
            page: 1,
        })
        this.getData()
    }
    render() {
        const { page, count, rows, SearchTitle, open, } = this.state
        return (
            <div className="admin_table_block" >
                <div className="pb_headT flex">
                    <div>
                        编程作业
                    </div>
                    <div className="admin_course_input">
                        <div className="admin_course_searchT admin_user_btnT" onClick={this.search}>搜索</div><input className="user_input"
                            placeholder="请输入课程标题或提交者"
                            style={{ float: "right", width: "200px", height: "40px", margin: "2px 12px 0px 0px", fontSize: "16px", textIndent: ".5em" }} value={SearchTitle} onChange={v => this.updateSTitle(v)} />
                    </div>
                </div>
                {rows.length > 0 ? <div className="hs_pub_tbT tableT">
                    <ul className="hs_dp_headT user_headT">
                        <li>
                            <div>章节测试标题</div>
                            <div>课程标题</div>
                            <div>提交者</div>
                            <div>提交时间</div>
                            <div>操作</div>
                        </li>
                    </ul>
                    <ul className="hs_dp_tbodyT user_tbodyT">
                        {rows && rows.map((x, i) => (
                            <li key={i}>
                                <div>{x.radio.minutiaName}</div>
                                <div>{x.title}</div>
                                <div>{x.username}</div>
                                <div>{x.time}</div>
                                <div><span className="hs_pub_updateBtnT" onClick={() => {
                                    this.openTest(x.picture)
                                }}>查看图片</span><span className="hs_pub_deleteBtnT" onClick={() => { this.openTest2(x.text) }}>查看详情</span></div>
                            </li>
                        ))}
                    </ul>
                </div> : <div style={{ textAlign: 'center', }}>
                    <img src={require('../assets/img/notcontent.png')} />
                </div>}
                <div>{count > 0 ? <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    rowsPerPage={10}
                    rowsPerPageOptions={[10]}
                    onChangePage={this.pageOnChange}
                /> : ""}</div>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"是否删除"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" css={`width:500px`}>
                            是否删除此用户测验？
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.deleteData} color="primary">
                            确定
                        </Button>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            取消
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default homework
