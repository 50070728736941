/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import '../css/login.css';
import { RGCaptcha, reset } from 'react-geetest-captcha';
import axios from 'axios';
import qs from 'qs';
import UserAgreement from '../components/UserAgreement'
import PrivacyAgreement from '../components/PrivacyAgreement'
import config from '../config'
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { Popover, CircularProgress, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getSchoolLists, geetestData, getWeixinCode } from '../http/api'
import transitions from '@material-ui/core/styles/transitions';

const CAPTCHA_NAME = 'demoCaptcha';
const mapDispatchToProps = (dispatch) => ({
  openRoomModal(state) {
    dispatch(toggleRoom(state));
  },
  toggleLogin(state) {
    dispatch(toggleLogin(state));
  },
  openUserDropdown(state) {
    dispatch(toggleUserDropdown(state));
  },
});
const mapStateToProps = state => {
  const { profile } = state.user;
  return {
    loginModalIsOpen: state.modals.loginModalIsOpen,
    account: profile && profile.account,
    name: profile && profile.name
  };
};
const regEmail = new RegExp("^[A-Za-z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$");
const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);
// const useStyles = makeStyles({
//   progress: {
//     margin: theme.spacing(2),
//   },
// });

const modalStyle = {
  padding: '12px 25px 12px 25px',
  zIndex: 999999999,
  fontSize: 16,
  maxWidth: 300,
  minWidth: 100,
  color: 'rgba(255,255,255,1)',
  background: 'rgba(0,0,0,0.75)',
  borderRadius: 10,
  position: 'fixed',
  top: "20%",
  left: 0,
  right: 0,
  margin: '0px auto',
  textAlign: 'center',
  wordBreak: 'break-word'
}

@withRouter
class login extends React.Component {
  // const [completed, setCompleted] = useState(0)
  constructor(props) {
    super(props)
    this.state = {
      login: true,
      schoolShow: false,  // 學校列表顯示
      schoolNum: '', //  学校邮箱编号
      list: [
      ],
      schoolName: '',   // 學校名稱
      schoolId: '',    // 選中的學校id
      value: '',  // 學校
      identityValue: 1,   // 選擇的身份
      identityShow: false,   // 身份選擇下拉列表
      userEmail: '',     // 用戶登錄郵箱
      userPassWord: '',    // 用戶登錄密碼
      progress: 0,         // 进度条
      title: '',
      progressShow: false,
      geetest_challenge: '',
      geetest_validate: '',
      geetest_seccode: '',
      isread: false,
      open: false,
      open2: false,
      type: "",
      errData: ''
    }
  }

  componentDidMount() {
    var _type = window.U.UF.C.queryString("type") || '';
    if (_type == 1 || _type == 2) {
      // window.location.href = "https://fangzhen.cocorobo.cn"
      this.setState({
        type: _type
      })
    }
    //console.log("MD5 加密", md5("123456"))
    let that = this
    setTimeout(() => {
      that.Loginthrid()
    }, 10)
    // this.getSchoolList('')
    var Height = document.documentElement.clientHeight
    var font = document.getElementsByClassName('login')[0];
    font.style.height = (Height - 150) + 'px';
    function blue() {
      // font.style.color = 'blue';
    }
    font.onclick = function () {
      blue();
    }
    //this.geetestRegister();
  }

  Loginthrid() {
    let that = this
    if (window.QC) {
      window.QC.Login({
        btnId: "qqLogin",	//插入按钮的节点id
      }, (e) => {
        window.QC.Login.getMe(function (openId, accessToken) {
          let params = {
            openid: openId
          }
          console.log("当前登录用户的openId为：" + openId);
          // if (!arr) {
          window.openId = openId;
          axios.post(`${config.env.api}api/user`, qs.stringify(params)).then(res => {

            if (res.data[0][0].active === 1) {
              that.props.toggleLogin(true)
              if (that.state.type == 1) {
                var _UnityType = window.U.UF.C.queryString("UnityType") || '';
                if (_UnityType) {
                  window.location.href = "https://fangzhen.cocorobo.cn/unity?type=" + _UnityType
                } else {
                  window.location.href = "https://fangzhen.cocorobo.cn"
                }
              } else if (that.state.type == 2) {
                // window.location.href = "https://fangzhen.cocorobo.cn"
              } else {
                that.props.history.push('/')
              }
            }
          }).catch(err => {
            if (err && err.response && err.response.status === 401) {
              that.props.history.push('/thirdlogin')
            }
          })
          // }
        })
      });
    }
    if (this.getQueryString('code') && this.getQueryString('state')) {
      // oiztX1dwR-W2mBJ5HcvaSEB8yKGY
      axios.defaults.withCredentials = false;
      axios.get(`//weixin.cocorobo.cn/login.php?code=${this.getQueryString('code')}`).then(res => {
        if (res.data && res.data.openid) {
          let params = {
            openid: res.data.openid
          }
          window.openId = res.data.openid;
          axios.defaults.withCredentials = true;
          axios.post(`${config.env.api}api/user`, qs.stringify(params)).then(res => {
            if (res.data && res.data[0] && res.data[0][0] && res.data[0][0].active === 1) {
              that.props.toggleLogin(true)
              if (that.state.type == 1) {
                var _UnityType = window.U.UF.C.queryString("UnityType") || '';
                if (_UnityType) {
                  window.location.href = "https://fangzhen.cocorobo.cn/unity?type=" + _UnityType
                } else {
                  window.location.href = "https://fangzhen.cocorobo.cn"
                }
              } else if (that.state.type == 2) {
                // window.location.href = "https://fangzhen.cocorobo.cn"
              } else {
                that.props.history.push('/')
              }
            }
          }).catch(err => {
            console.log(err.response)
            if (err && err.response && err.response.status === 401) {
              that.props.history.push('/thirdlogin')
            }
          })
        }
      })
    }
  }
  // url 取值
  getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }

  geetestRegister() {
    reset(CAPTCHA_NAME);
  }

  geetestValidate(isSuccess) {
    this.setState({
      geetest_challenge: isSuccess.geetest_challenge,
      geetest_validate: isSuccess.geetest_validate,
      geetest_seccode: isSuccess.geetest_seccode
    })
  }

  geetest = () => {
    return geetestData().then((res) => {
      var captcha = (res && res.data) || {};
      return captcha;
    })
      .catch((err) => {
        console.log(err);
      })
    // return axios.get(`${config.env.api}geetest/pc-geetest/register`)
    //   .then((res) => {
    //     var captcha = (res && res.data) || {};
    //     return captcha;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
  }

  // 獲取學校列表
  getSchoolList = e => {
    getSchoolLists({ mode: e }).then(res => {
      console.log(res.data[0])
      if (res.data[0].length > 0) {
        this.setState({
          value: res.data[0][0].name,
          schoolShow: false,
          schoolId: res.data[0][0].id,
          schoolNum: res.data[0][0].mail,
        })
      } else {
        this.setState({
          value: '该学校编号不存在',
          schoolShow: false,
          schoolId: '',
          schoolNum: '',
        })
      }
    })
  }

  progress = (n) => {
    let num = this.state.progress + n;
    num = num > 100 ? 0 : num
    this.setState({
      progress: num
    })
  }

  // 用戶登錄郵箱驗證
  loginEmail = e => {
    // 清楚首尾空格
    this.setState({
      userEmail: e.target.value
    })
  }

  // 用戶登錄密碼 
  loginPassWord = e => {
    this.setState({
      userPassWord: e.target.value
    })
  }


  // 根據輸入的值動態獲取學校列表
  changeList = e => {
    // 根據輸入的值動態獲取學校列表
    // e.target.value = e.target.value.replace(/[^\d]/g,'')
    console.log('e.target.value', e.target.value)
    this.setState({
      schoolName: e.target.value
    })
    if (e.target.value) {
      this.getSchoolList(e.target.value)
    } else {
      this.setState({
        value: '',
      })
    }
  }

  // 點擊學校列表，獲取學校名稱
  clickList = (e) => {
    // console.log(email)
  }

  // 點擊彈出身份列表
  identityShow = () => {
    const { identityShow } = this.state
    this.setState({
      identityShow: !identityShow
    })
  }

  // 登錄
  login = () => {
    const { language } = this.props
    // if (this.state.geetest_challenge && this.state.geetest_validate && this.state.geetest_seccode) {
    // /user
    const { userEmail, userPassWord, schoolNum } = this.state
    let a = schoolNum ? ('@' + schoolNum) : '@cocorobo.cc'
    let email = ''
    let str = userEmail.replace(/(^\s*)|(\s*$)/g, "")
    if (!regEmail.test(str)) {
      email = str + a
    } else {
      email = str
    }
    // !regpass.test(userPassWord)
    if (userPassWord.length < 6) {

      this.geetestRegister();
      window.U.Alert(language.password_regular);
      return;
    }
    this.setState({
      progressShow: true
    })
    let aa = setInterval(() => {
      this.progress(10)
    }, 100)
    let params = {
      geetest_challenge: this.state.geetest_challenge,
      geetest_validate: this.state.geetest_validate,
      geetest_seccode: this.state.geetest_seccode,
      loginUsername: email,
      loginPassword: btoa(userPassWord)
    }
    axios.defaults.withCredentials = true;
    var _that = this;
    console.log(params)
    // return 
    axios.post(`${config.env.api}api/user`, qs.stringify(params)).then(res => {
      _that.geetestRegister();
      if (res.data[0][0].active === 1) {
        _that.setState({
          progressShow: false,
          progress: 100
        }, () => {
          window.U.Alert(`<p>${language.login_successful}</p><br/><img src='/img/loading.gif' />`, 1000);
        })
        setTimeout(() => {
          window.localStorage['identity'] = JSON.stringify(res.data[0][0].identity)
          _that.props.toggleLogin(true)
          if (_that.state.type == 1) {
            var _UnityType = window.U.UF.C.queryString("UnityType") || '';
            if (_UnityType) {
              window.location.href = "https://fangzhen.cocorobo.cn/unity?type=" + _UnityType
            } else {
              window.location.href = "https://fangzhen.cocorobo.cn"
            }
          } else if (_that.state.type == 2) {
            // window.location.href = "https://fangzhen.cocorobo.cn"
          } else {
            _that.props.history.push('/')
          }
          clearInterval(aa)
        }, 1000)
      } else {
        // window.U.Alert(language.login_fail);
        _that.setState({
          progressShow: false,
          errData: language.login_fail
        }, () => {
          console.log(language.login_fail)
          _that.setState({
            isread: true
          })
          _that.closeModel()
        })
      }
    }).catch(err => {
      _that.geetestRegister();
      _that.setState({
        progressShow: false,
        errData: err.response.data || language.login_fail
      }, () => {
        console.log(err.response.data || language.login_fail)
        _that.setState({
          isread: true
        })
        _that.closeModel()
      })
    })
    // }
    // else {
    //   this.geetestRegister();
    //   window.U.Alert(`<p>${language.click_validation}</p>`, 1000);
    // }
  }

  closeModel = () => {
    let _this = this
    setTimeout(() => {
      _this.setState({
        isread: false
      })
    }, 2000)
  }
  enter = (e) => {
    if (e.keyCode === 13) {
      this.login()
    }
  }


  jumpWeixin = () => {
    window.location.href = "//cocorobo.cn/get-weixin-code.html?appid=wxe9d7fff3c659445f&redirect_uri=" + window.encodeURI("//edu.cocorobo.cn/login") + "&response_type=code&scope=snsapi_login&state=" + Math.floor(Math.random() * 1000000);
  }

  jumpQQ = () => {
    window.open('https://graph.qq.com/oauth2.0/authorize?client_id=101882546&response_type=token&scope=all&redirect_uri=https%3A%2F%2Fedu.cocorobo.cn%2Flogin', 'oauth2Login_10273', 'height=525,width=585, toolbar=no, menubar=no, scrollbars=no, status=no, location=yes, resizable=yes');
  }

  handleClose = () => {
    this.setState({
      isread: false
    })
  }

  render() {
    const { login, schoolName, value, progressShow, userEmail, progress, userPassWord, isread, errData } = this.state
    const { language } = this.props
    return (
      <div className="login" onClick={(e) => {
        this.setState({
          schoolShow: false,
          identityShow: false
        })
      }}>
        <div className="contain" onKeyDown={this.enter} style={{ position: "absolute", top: "50%", marginTop: (login ? -218 : -232) + "px", left: 'calc(50% - 200px)' }}>
          <div style={{ padding: '0 20px', marginTop: '25px' }}>
            <div className="userLogin" onClick={(e) => {
              e.stopPropagation()
            }}>
              <img src={require("../assets/img/schoo.png")} style={{ width: '23px', paddingRight: 3 }} />
              <input className='schoolNumsty' type='text'
                style={{ width: "100%" }} placeholder='学校或组织编号（选填）'
                onChange={this.changeList} value={schoolName} />
              <span style={{
                marginLeft: 15, display: 'block', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                position: 'relative', top: 5
              }}>{value}</span>
            </div>
            <div className="userLogin">
              <img src={require("../assets/img/user.png")} />
              <input placeholder='用户名' onBlur={this.loginEmail} value={userEmail} onChange={e => {
                this.setState({
                  userEmail: e.target.value
                })
              }} />
            </div>
            <div className="userLogin">
              <img src={require("../assets/img/password.png")} />
              <input placeholder={language.user_passworld} type="password" onChange={this.loginPassWord} value={userPassWord} />
            </div>

            <div id="forgetPassword">
              <Link to='/password'><p>{language.forget_passworld}</p></Link>
            </div>
          </div>
          {/* <div className="Geetest" id="Geetest">
            <RGCaptcha
              name={CAPTCHA_NAME}
              width="100%"
              onSuccess={this.geetestValidate.bind(this)}
              data={this.geetest}
              lang={(window.localStorage.language && JSON.parse(window.localStorage.language) === 1) ? 'en' : 'zh-cn'}
            />
          </div> */}
          <div style={{ padding: '40px 20px 10px' }}>
            <div className="loginUser">
              <button onClick={this.login}>{language.login}</button>
            </div>
            <div className="guge" id='thirdPartyLogin'>
              <span>第三方登录</span>
              <div style={{ display: 'inline-block' }}>
                <span onClick={this.jumpWeixin}>
                  <img src={require('../assets/images/weixin.png')} />
                  微信
                </span>
                <span id="qqLogin" className="qqLogin" style={{ height: 21 }}>
                </span>
                <span onClick={this.jumpQQ}>
                  <img src={require('../assets/images/qq.png')} />
                  QQ
                </span>
              </div>
            </div>
            <p id='thirdPartyRegistered'>{language.no_account}
              <Link to="/registered">
                <span>{language.click_registered}</span>
              </Link>
            </p>
          </div>
        </div>
        <Popover
          // id={id}
          open={progressShow}
          anchorOrigin={{
            vertical: 200,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: '20px 30px', fontSize: 20 }}>
            <CircularProgress style={{ display: 'block', margin: '0 auto' }} variant="static" value={progress} />
          </div>
        </Popover>
        {isread && <div style={modalStyle}>
          {errData || '用户不存在'}
        </div>}

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(login);
