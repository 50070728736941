import React from 'react'
import styled from 'styled-components'
//components
import Subtitle from './Subtitle'
//config
import config from '../config'
import { get, post } from 'axios';


const Wrapper = styled.ul`
  width:100%;
  grid-area:${props => props.areaname};
  font-size:1em;
  margin:0;
  padding:0;
  color:${props => props.color || '#27303B'};
  background-color:#76B8DE;
  display:grid;
  grid-gap:10px;
  padding:10px;
  border-radius:5px;
  box-sizing:border-box;
`;

const Bullet = styled.li`
  width:100%;
  height:auto;
  display:inline-block;
  display:grid;
  grid-template-columns: 20% 80%;
  background-color:#fff;
  box-sizing:border-box;
  border-radius:5px;
  overflow:hidden;
  overflow: hidden;
  position: relative;
`;
const Bullet1 = styled.li`
  width:100%;
  height:auto;
  display:inline-block;
  display:grid;
  background-color:#fff;
  box-sizing:border-box;
  border-radius:5px;
  overflow:hidden;
  overflow: hidden;
  position: relative;
`;
const Box = styled.div`
`
const Arrow = styled.div`
    position: absolute;
    height: 7px;
    border-style: solid;
    border-width: 0px 7px 7px 7px;
    border-color: #ff000000 #0fe20f00 black #79fdfd00;
    width: 7px;
    transform: rotate(270deg);
    transition: 0.5s all;
    right: 15px;
    top: 22px;
    cursor:pointer;
`;
const Contentbox = {
  width: "100%",
  minHeight: "0px",
  maxHeight: "0px",
  // display: "none",
  backgroundColor: "#fff",
  borderRadius: "5px",
  margin: "5px 0 0 0",
  textAlign: "left",
  padding: "0px",
  fontSize: "1.4em",
  wordBreak: "break-all",
  transition: "0.2s all",
  overflow: "hidden",
};


class Chapter extends React.Component {
  state = {
    ppp: []
  }
  // 点击箭头
  open = (index) => {
    var a = window.$(".arrowX")[index];
    var b = window.$(".cbox")[index];
    var d = window.$(".dbox")[index];

    if (a.className.indexOf("active") == -1) {
      a.style.transform = "rotate(180deg)";
      a.className += " active";
      // b.style.minHeight = "100%";
      // b.style.maxHeight = "30000px";
      // b.style.padding = "5px";
      var _height = d.offsetHeight
      b.style.height = _height + 'px';
      b.style.marginTop = "10px";
    } else {
      a.style.transform = "rotate(270deg)";
      a.className = a.className.replace(" active", "");
      // b.style.minHeight = "0px";
      // b.style.maxHeight = "0px";
      // b.style.padding = "0px";
      b.style.height = "0px";
      b.style.marginTop = "0px";
    }

  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    this.getUnit()
  }

  getUnit = () => {
    get(`${config.env.edu}getUnit/${this.props.courseId}`).then(res => {
      // if (res.data[0]) {
      //   console.log(res.data[0])
      // }
      this.setState({
        ppp: res.data[0]
      })
    })
  }

  render() {
    const _arr = this.props.chapters ? this.props.chapters : ([]);
    const _state = this.props.state ? this.props.state : '';
    const { ppp } = this.state

    return (
      <Wrapper areaname={this.props.areaname}>
        {_state == 2 ? ppp.map((val,index) =>(
          <Box key={`Chapter__Bullet--${index}`}>
            <Bullet key={`Chapter__Bullet--${index}`}>
              <div style={{ color: '#27303B', backgroundColor: '#E1EDF7', height: '100%', width: '100%' }}>
                <Subtitle
                  name={index + 1}
                  css={`
              text-align:center;
              // padding:14px 0;
              font-size:1.5em;
              box-sizing:border-box;
              place-self:center;
              position:relative;
              top:calc(50% - 12px)
            `}
                />
              </div>
              <Subtitle
                name={val.title} //{this.props.chapters[val]}
                color='#27303B'
                css={`
              padding:14px 30px;
              font-size:1.5em;
              box-sizing:border-box;
              cursor: pointer;
              text-align:left
            `}
              />
            </Bullet>
          </Box>
        )) : _arr.map((val, index) => (
          <Box key={`Chapter__Bullet--${index}`}>
            <Bullet key={`Chapter__Bullet--${index}`}>
              <div style={{ color: '#27303B', backgroundColor: '#E1EDF7', height: '100%', width: '100%' }}>
                <Subtitle
                  name={index + 1}
                  css={`
              text-align:center;
              // padding:14px 0;
              font-size:1.5em;
              box-sizing:border-box;
              place-self:center;
              position:relative;
              top:calc(50% - 12px)
            `}
                />
              </div>
              <Subtitle
                name={val.title} //{this.props.chapters[val]}
                color='#27303B'
                css={`
              padding:14px 30px;
              font-size:1.5em;
              box-sizing:border-box;
              cursor: pointer;
              text-align:left
            `}
              // onClick={() => {
              //   // console.log(this.props,`${config.env.basename}/tutor/chapter/${this.props.match.params.courseId}/${index}`)
              //   window.location.href = `${config.env.basename}/tutor/chapter/${this.props.match.params.courseId}/${index}`
              // }}
              />
              {/* {_state == 2 ? <Arrow className="arrowX" onClick={() => this.open(index)}></Arrow> : ''} */}
            </Bullet>
            {/* <div className="cbox" style={Contentbox}>{val.teacher.intro}</div> */}
            {/* <div className="cbox" css={`
                    height: 0;
                    margin-top:0px;
                overflow: hidden;
                transition: all .5s ease 0s;
                text-align:left;`}>
              <div className="dbox" css={`    display: grid;
                grid-gap: 10px;`}>
                {_state == 2 ?
                  _arr[index].chapterData && _arr[index].chapterData.map((x, i) => {
                    return (
                      <div key={index + '-' + i} >
                        <Bullet1 key={`Chapter__Bullet--${index}`}>
                          <Subtitle
                            name={x.minutiaName} //{this.props.chapters[val]}
                            color='#27303B'
                            css={`
                          padding:14px 30px;
                          font-size:1.5em;
                          box-sizing:border-box;
                          cursor: pointer;
                        `}
                          />
                        </Bullet1>
                      </div>

                    )
                  }) : ''}
              </div>
            </div> */}
          </Box>
        ))}

      </Wrapper>
    )
  }
}

export default Chapter;
