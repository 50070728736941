import React, { Component } from 'react'
import '../css/StudentSumUp.css'
import axios from 'axios';
import config from '../config'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Modal, Table, Paper, TableHead, TableRow, TableBody, TableCell, TablePagination, Fade } from '@material-ui/core';

const tableStyle = {
    border: '1px solid #eee'
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F4F6",
        color: '#000',
        fontSize: 18,
        fontWeight: "600",
        borderLeft: "2px solid #F3F4F6"
    },
    body: {
        fontSize: 18,
        borderLeft: "2px solid #F3F4F6"
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: "#f1f1f1",
        },
    },
    body: {

    },
}))(TableRow);

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const table = {
    minWidth: 700,
    padding: "0 30px"
}

export class StudentSumUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: '',
            classname: '',
            rows: [
            ],  // 班级数据
            page: 0,
            addClassModal: false,
            search: "", //查询
            count: 0,  // 查询的总数量,
            sid: "",
            test: 0,
            code: 0,
            sum: 0,
            ssid: "",
            remarks: "",
            testZ: [],
            score: "",
            number: "",
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.number != nextProps.number) {
            this.setState({
                number: nextProps.number
            }, () => {
                this.getData();
            })
        }
    }

    getData = () => {
        axios.get(`${config.env.edu}getSummary2/${this.state.number}/${this.state.page + 1}`).then(res => {
            console.log(res)
            if (res.data[0].length > 0) {
                this.setState({
                    rows: res.data[0],
                    count: res.data[0][0].num
                })
            }
        })
    }

    handleChangePage = (event, newPage) => {
        console.log(newPage)
        this.setState({
            page: newPage
        }, () => {
            this.getData()
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: this.state.page,
            rowsPerPage: +event.target.value
        }, () => {
            this.getData()
        })
    }

    get = (x, type) => {
        if (!x) {
            window.alert("该学生未上传" + type == 1 ? "图片" : "视频")
            return;
        }
        window.open(x)
    }

    sum = (sid) => {
        // this.setState({
        //     addClassModal: true,
        //     sid: sid,
        //     ssid: sid.id
        // })

        axios.get(`${config.env.edu}getSummaryById2/${sid.courseid}/${sid.userid}`).then(res => {
            var course = res.data[0][0]
            if (course.score) {
                this.setState({
                    score: JSON.parse(course.score)
                })
            } else {
                this.setState({
                    score: ""
                })
            }
            var ti = res.data[1]
            var code = res.data[2]

            var testZ = []
            var codeZ = []
            var chapters = JSON.parse(course.chapters)
            for (var i = 0; i < chapters.length; i++) {
                for (var j = 0; j < chapters[i].chapterData.length; j++) {
                    if (chapters[i].chapterData[j].typeName == "测验") {
                        testZ.push(chapters[i].chapterData[j])
                    }
                    if (chapters[i].chapterData[j].typeName == "编程") {
                        codeZ.push(chapters[i].chapterData[j])
                    }
                }
            }
            if (ti.length < testZ.length) {
                window.U.alert("此学生还未做完全部测验，暂不能打分")
                return;
            }
            if (code.length < codeZ.length) {
                window.U.alert("此学生还未提交全部编程作业，暂不能打分")
                return;
            }

            for (var i = 0; i < testZ.length; i++) {
                for (var j = 0; j < ti.length; j++) {
                    if (ti[j].chapterid == testZ[i].id) {
                        testZ[i].Rscore = ti[j].Rscore
                        testZ[i].Ccount = JSON.parse(ti[j].Ccount)
                        break;
                    }
                }
            }
            this.setState({
                addClassModal: true,
                sid: sid,
                ssid: sid.id,
                testZ: testZ
            })

            console.log(res);
        })

    }

    starLight = (score, type) => {
        if (type == 1) {
            this.setState({
                test: score
            })
        } else if (type == 2) {
            this.setState({
                code: score
            })
        } else if (type == 3) {
            this.setState({
                sum: score
            })
        }
    }

    submit = () => {
        const { test, code, sum, ssid, remarks, testZ } = this.state
        var testScore = []
        for (var i = 0; i < testZ.length; i++) {
            var testttt = document.getElementsByClassName("testKongScore")[i].value
            if (testZ[i].Ccount.Ccount > 0 && testttt === "") {
                window.U.alert("有填空题的分数未填写")
                return;
            }
            if (testZ[i].Ccount.Ccount > 0 && testZ[i].Ccount.CZscore > 0 && testttt !== "" && parseInt(testttt) > testZ[i].Ccount.CZscore) {
                window.U.alert("填空得分不能大于所给满分")
                return;
            }
            if (testZ[i].Ccount.Ccount > 0) {
                testScore.push(parseInt(testZ[i].Rscore) + parseInt(testttt))
            } else {
                testScore.push(parseInt(testZ[i].Rscore))
            }

        }

        var score = {
            testScore, code, sum, remarks
        }
        axios.post(`${config.env.edu}score`, `mode=${this.props.userId},${encodeURIComponent(encodeURIComponent(JSON.stringify(score)))},${ssid},${Math.round((this.sumAS(testScore) / testScore.length))},${code},${sum}`).then(res => {
            window.U.alert("评分成功")
            this.setState({
                test: "",
                code: "",
                sum: "",
                ssid: "",
                remarks: "",
                addClassModal: false,
                testZ: [],
            })
        }).catch(err => console.log(err));
    }

    sumAS = (arr) => {
        var s = 0;
        arr.forEach(function (val, idx, arr) {
            s += val;
        }, 0);

        return s;
    };

    render() {
        const { rows, page, addClassModal, classname, rowsPerPage, count, sid, test, code, sum, remarks, testZ, score } = this.state;

        return (
            <div className="AddStudent">
                <div style={table} component={Paper}>
                    {rows.length > 0 ? <Table style={tableStyle} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">单元标题</StyledTableCell>
                                <StyledTableCell align="center">提交者</StyledTableCell>
                                <StyledTableCell align="center">提交时间</StyledTableCell>
                                <StyledTableCell align="center">操作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.classname}>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.title}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.alias ? row.alias : row.username}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.time}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <span className="hs_pub_updateBtnT" onClick={() => this.get(row.poster, 1)}>查看图片</span>
                                            <span className="hs_pub_updateBtnT" style={{ margin: "0 10px" }} onClick={() => this.get(row.video, 2)}>查看视频</span>
                                            <span className="hs_pub_updateBtnT" onClick={() => this.sum(row)}>立即评分</span>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table> :
                        <div style={{ textAlign: 'center', }}>
                            <img src={require('../assets/img/manage/null.png')} />
                        </div>}
                    {rows.length > 0 && <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        rowsPerPage={10}
                        rowsPerPageOptions={[10]}
                        component="div"
                        onChangePage={this.handleChangePage}
                    />}
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addClassModal}
                    onClose={() => {
                        this.setState({
                            addClassModal: false
                        })
                    }}
                >
                    <Fade in={addClassModal}>
                        <div className="sumModel">
                            <div className="title">
                                <h2>项目总结</h2>
                                <span onClick={() => { this.setState({ addClassModal: false }) }}> <img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <div className="score_box">
                                <div className="score_controduce">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div className="score_pBox">
                                            {/* <div><img style={{ width: '55px' }} src={require("../assets/img/manage/portal.png")} /></div> */}
                                            <div><img style={{ width: '55px' }} src={require("../assets/img/toux.png")} /></div>
                                            <div className="c_flex" style={{ marginLeft: "10px" }}>
                                                <span className="uname">{sid.alias ? sid.alias : sid.username}</span>
                                                <h1>{sid.title}</h1>
                                            </div>
                                        </div>
                                        <div>
                                            <span style={{ color: '#78ACEE', cursor: 'pointer', fontSize: "14px", marginRight: "10px" }} onClick={() => this.get(sid.poster, 1)}>查看图片</span>
                                            <span style={{ color: '#78ACEE', cursor: 'pointer', fontSize: "14px" }} onClick={() => this.get(sid.video, 2)}>视频播放</span>
                                        </div>
                                    </div>
                                    <div className="c_flex score_detail">
                                        <div style={{ color: "#676767", fontSize: "17px" }}>简要介绍</div>
                                        <span style={{ fontSize: "17px", wordBreak: "break-all", maxHeight: "60px", overflow: "auto" }}>{sid.remarks ? sid.remarks : "暂无简介"}</span>
                                    </div>
                                </div>
                                <div className="score_controduce">
                                    <div className="c_flex score_detail">
                                        <div style={{ color: "#676767", fontSize: "17px" }}>1.测验评分</div>
                                        {score ? <div className="score_test">
                                            {testZ && testZ.map((x, i) => (
                                                <div class="score_test_box">
                                                    <div class="score_test_title">【测验】{x.minutiaName}</div>
                                                    <div class="score_test_xuan">选择得分：该测验，选择题为<span style={{ color: "#ebaf50" }}>{x.Rscore}分</span></div>
                                                    <div class="score_test_tian">填空得分：{
                                                        x.Ccount.Ccount > 0 ?
                                                            <span><input type="number" class="testKongScore" readonly={true} value={score.testScore[i] - x.Rscore} />请给填空进行打分，满分<span style={{ color: "#ebaf50" }}>{x.Ccount.CZscore}分</span></span> :
                                                            <span class="testKongScore">暂无</span>
                                                    }</div>
                                                </div>
                                            ))}
                                        </div> :
                                            <div className="score_test">
                                                {testZ && testZ.map((x, i) => (
                                                    <div class="score_test_box">
                                                        <div class="score_test_title">【测验】{x.minutiaName}</div>
                                                        <div class="score_test_xuan">选择得分：该测验，选择题为<span style={{ color: "#ebaf50" }}>{x.Rscore}分</span></div>
                                                        <div class="score_test_tian">填空得分：{
                                                            x.Ccount.Ccount > 0 ?
                                                                <span><input type="number" class="testKongScore" />请给填空进行打分，满分<span style={{ color: "#ebaf50" }}>{x.Ccount.CZscore}分</span></span> :
                                                                <span class="testKongScore">暂无</span>
                                                        }</div>
                                                    </div>
                                                ))}
                                            </div>}
                                    </div>
                                    <div className="c_flex score_detail">
                                        <div style={{ color: "#676767", fontSize: "17px" }}>2.编程得分</div>
                                        {score ? <div className="score_Star">
                                            <img src={score.code < 1 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.code < 2 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.code < 3 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.code < 4 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.code < 5 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                        </div> : <div className="score_Star">
                                            <img src={code < 1 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(1, 2) }} />
                                            <img src={code < 2 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(2, 2) }} />
                                            <img src={code < 3 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(3, 2) }} />
                                            <img src={code < 4 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(4, 2) }} />
                                            <img src={code < 5 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(5, 2) }} />
                                        </div>}
                                    </div>
                                    <div className="c_flex score_detail">
                                        <div style={{ color: "#676767", fontSize: "17px" }}>3.项目实践得分</div>
                                        {score ? <div className="score_Star">
                                            <img src={score.sum < 1 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.sum < 2 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.sum < 3 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.sum < 4 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.sum < 5 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                        </div> : <div className="score_Star">
                                            <img src={sum < 1 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(1, 3) }} />
                                            <img src={sum < 2 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(2, 3) }} />
                                            <img src={sum < 3 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(3, 3) }} />
                                            <img src={sum < 4 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(4, 3) }} />
                                            <img src={sum < 5 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(5, 3) }} />
                                        </div>}
                                    </div>
                                    <div className="c_flex score_detail">
                                        <div style={{ fontSize: "17px", borderBottom: "3px solid #eee", display: "flex", paddingBottom: "10px" }}>更多评价</div>
                                        {score ? <textarea readonly={true} value={score.remarks} style={{ fontSize: "16px", height: "90px", marginTop: "10px", outline: "none", padding: "5px", border: "2px solid rgb(238, 238, 238)", borderRadius: "3px" }}></textarea> : <textarea value={remarks} style={{ fontSize: "16px", height: "90px", marginTop: "10px", outline: "none", padding: "5px" }} onChange={(e) => { this.setState({ remarks: e.target.value }) }}></textarea>}
                                    </div>
                                    <div className="submit">
                                        {score ? <button style={{ background: '#F23A3A' }} onClick={() => { this.setState({ addClassModal: false }) }}>已评分</button> : <button style={{ background: '#F23A3A' }} onClick={this.submit}>确定</button>}
                                        <button style={{ background: '#AAA' }} onClick={() => { this.setState({ addClassModal: false }) }}>取消</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}


export default StudentSumUp
