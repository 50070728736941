import cocopkg from '../package.json'

let env = 'dev';

const config = {
  // env: env === 'dev' ?
  // {
  //   // 本地服务器:'//localhost:7999',
  //   server:'//10.3.16.218:8083/api/', ////api.edu.cocorobo.hk
  //   edu: '//10.3.16.218:7001/edu/',
  //   //login:'//localhost:7999/login',
  //   origin:'//cocorobo.hk',
  //   version: cocopkg.version,
  //   basename:'',
  //   //edu:'//beta.cocorobo.hk/course',
  //   //room:'//edu.cocorobo.hk/tutor/room',
  // }
  // // {
  // //   server:'//staging.cocorobo.hk/api',
  // //   login:'//staging.cocorobo.hk/login',
  // //   version: cocopkg.version
  // // }
  env: {
    server: 'https://beta.api.cocorobo.cn/api/',
    api: 'https://beta.api.cocorobo.cn/',
    serverHk: 'https://cloud.api.cocorobo.hk/api/',
    apiHk: 'https://cloud.api.cocorobo.hk/',
    chat: "https://chat.api.cocorobo.cn/",
    edu: 'https://api.edu.cocorobo.cn/edu/',
    // server:'//localhost:8083/api/',
    // api:'//localhost:8083/',
    // edu: '//localhost:7001/edu/',
    origin: '//cocorobo.cn',
    version: cocopkg.version,
    basename: '',
  }
}

export default config;
