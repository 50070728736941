import React, { Component } from 'react'
import axios from 'axios';
import config from '../config'
import '../css/studentHomework.css'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import StudentTest from './studentTest'
import StudentCode from './studentCode'
import StudentSum from './studentSum'
import qs from 'qs';

export class studentHomework extends Component {
    state = {
        userId: '',
        classname: '',
        rows: [
        ],  // 班级数据
        rowsPerPage: 10,
        page: 0,
        addClassModal: false,
        search: "", //查询
        count: 0,  // 查询的总数量
        lineStyle: "",
        type: 0,
        courseid: "",
        testArray: [],
    }

    componentDidMount() {
        console.log(this.props)
        // this.getData()
        this.getCourseType();
    }

    getData = () => {

    }

    check = (type) => {
        let lineStyle = ""
        if (type == 1) {
            lineStyle = "cm_line_chat"

        } else if (type == 2) {
            lineStyle = "cm_line_juri"
        } else if (type == 3) {
            lineStyle = "cm_line_test"
        } else if (type == 4) {
            lineStyle = "cm_line_code"
        } else if (type == 5) {
            lineStyle = "cm_line_sumup"
        }

        this.setState({
            lineStyle: lineStyle,
            type: type
        })
    }

    setType = (e) => {
        var value = e.target.value;
        this.setState({
            courseid: value
        }, () => {
            this.getData()
        })

    }

    getCourseType = () => {
        let params = {
            uid: this.props.userId,
        }
        var a = "http://localhost:7001/edu/" // config.env.edu
        axios.post(`${config.env.edu}getCourseType`, qs.stringify(params)).then(res => {
            console.log(res)
            // if (res.data[0].length > 0) {
            setTimeout(() => {
                this.setState({
                    testArray: res.data[0],
                })
            }, 0);

            // }

        })
    }

    render() {
        const { rows, page, addClassModal, classname, rowsPerPage, count, type, lineStyle, courseid, testArray } = this.state;

        return (
            <div className="AddStudent">
                <div className="pb_head student">
                    我的作业<span className="hp_My">&nbsp;&nbsp;Homework</span>
                </div>
                <div>
                    <div className="sr_select" style={{ margin: "20px 38px 0px" }}>
                        <span>课程筛选：</span>
                        <select onChange={e => { this.setType(e) }} value={courseid}>
                            <option value="">全部分类</option>
                            {
                                testArray.length > 0 && testArray.map((v, i) => (
                                    <option value={v.courseId} key={i}>{v.title}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="cm_navs" style={{ margin: "13px 0px 14px 30px" }}>
                        <div className="cm_nav" onClick={() => { this.check(0) }}>
                            <img src={require('../assets/img/manage/test.png')} />
                            <span style={{ fontSize: "16px" }}>测验查看</span>
                        </div>
                        <div className="cm_nav" onClick={() => { this.check(1) }}>
                            <img src={require('../assets/img/manage/code.png')} />
                            <span style={{ fontSize: "16px" }}>编程作业</span>
                        </div>
                        <div className="cm_nav" onClick={() => { this.check(2) }}>
                            <img src={require('../assets/img/manage/sumup.png')} />
                            <span style={{ fontSize: "16px" }}>项目总结</span>
                        </div>
                        <img src={require('../assets/img/manage/line.png')} className={`cm_line`} style={{ left: (window.$(".cm_nav")[type] && window.$(".cm_nav")[type].offsetLeft + "px") }} />
                    </div>
                    <div className="cm_content" style={{width:"100%"}}>
                        <div className="cm_class_container" style={{ display: type == 0 ? 'block' : "none" }}>
                            <StudentTest userId={this.props.userId} courseId={courseid}></StudentTest>
                        </div>
                        <div className="cm_class_container2" style={{ display: type == 1 ? 'block' : "none" }}>
                            <StudentCode userId={this.props.userId} courseId={courseid}></StudentCode>
                        </div>
                        <div className="cm_class_container2" style={{ display: type == 2 ? 'block' : "none" }}>
                            <StudentSum userId={this.props.userId} courseId={courseid}></StudentSum>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default studentHomework
