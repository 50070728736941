import React from 'react'
import styled from 'styled-components'


const Wrapper = styled.h3`
  // text-align: left;
  margin:0;
  padding:0;
  grid-area:${props => props.areaname};
  width:${props => props.inline ? '50%' : '100%'};
  height:100%;
  font-size:1.8em;
  //color:${props => props.color || '#5F6F85'};
  color:black;
  background-color:${props => props.backgroundColor};
  display:block;

`

class Subtitle extends React.Component {

  render() {
    const { areaname, backgroundColor, color, inline, type, ptype } = this.props;
    console.log(this.props);
    return (
      <Wrapper
        areaname={areaname}
        backgroundColor={backgroundColor}
        color={color}
        inline={inline}
        {...this.props}
        title={this.props.name}
      >
        {/* {type && type.join(",")}  */}
        {type && type.length > 0 && type.map((x, y) => (
          x.ptype == '课程难度' ? <span css={`margin-right:10px`}>难度{x.type}</span> : ''
        ))}
        {ptype && ptype.length > 0 && ptype.map((x, y) => (
          <span>
            {
              x.ptype != '课程难度' ? <span css={`margin-left: 25px;`}>
                {x.ptype + ':'}
                {
                type.map((x2, y2) => (
                  x2.ptype == x.ptype ? <span css={`margin-left:10px`}>{x2.type}</span> : ''
                ))
              }</span> : ''
            }
          </span>
        ))}
      </Wrapper>
    )
  }
}

export default Subtitle;
