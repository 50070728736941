import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import RoomUploadPreview from './RoomUploadPreview'
import Subtitle from './Subtitle'
//router
import { Redirect } from 'react-router-dom'
import axios from 'axios'
//components
import MultipleUpload from './MultipleUpload'

// Images
import videoIcon from '../assets/img/Upload__Icon--video.svg'
import attachmentIcon from '../assets/img/Upload__Icon--attachment.svg'

//config
import config from '../config'
//redux
import { connect } from 'react-redux';
//actions
import {
  createRoomId,
  cancelFile,
  nextStep,
  lastStep,
  updateRoomInfo,
  uploadRoomSlides,
  uploadRoomClip,
  uploadRoomAttachment,
} from '../actions/create';
import TitleInput from './TitleInput';
import IntroInput from './IntroInput';


const Wrapper = styled.div`
  margin-top:50px;
  width:80%;
  justify-self:center;
  display:grid;
  grid-template-columns: 10% 40% 40% 10%;
  grid-template-rows:50px 200px 200px 200px 200px 80px 50px;
  grid-template-areas:
  'title . . .'
  '. content content .'
  '. pdfUpload pdfUpload.'
  '. multipleUploadClips multipleUploadClips .'
  '. multipleUploadAttachment multipleUploadAttachment .'
  '. nextStep nextStep .';
  grid-gap:20px;
  @media screen and (max-width:768px){
    grid-template-rows:50px 200px 200px 200px 80px 50px;
    grid-template-areas:
    'title . . .'
    '. content content .'
    '. coverUpload coverUpload .'
    '. demoUpload demoUpload .'
    '. . . .'
    '. nextStep nextStep .';
  }
`;

const mapStateToProps = (state) =>{
  return{
    roomId: state.create.roomId,
    activeStep: state.steppers.activeStep,
    slides:state.create.slides,
    files: state.create.files,
    clips:state.create.clips,
    attachments:state.create.attachments,
    queue: state.create.queue
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
    initRoomId(callback) {
      dispatch(createRoomId(callback))
    },

    handleNext(activeStep) {
      dispatch(nextStep(activeStep))
    },

    handleBack(activeStep) {
      dispatch(lastStep(activeStep))
    },

    updateRoomInfo(title,brief) {
      dispatch(updateRoomInfo(title,brief))
    },

    uploadRoomSlides(roomId, payload, fileInfo) {
      dispatch(uploadRoomSlides(roomId, payload, fileInfo))
    },

    uploadClips(roomId) {
      const uploadMultiClips = (files, id) => {
        for (let i = 0; i < files.length; i++) {
          dispatch(uploadRoomClip(files[i], id));
        }
      }
      return files => {
        if (!roomId) {
          this.initRoomId(id => uploadMultiClips(files, id));
        } else {
          uploadMultiClips(files, roomId);
        }
      }
    },

    uploadAttachments(roomId) {
      const uploadMultiAttachs = (files, id) => {
        for (let i = 0; i < files.length; i++) {
          dispatch(uploadRoomAttachment(files[i], id));
        }
      }
      return files => {
        if (!roomId) {
          this.initRoomId(id => uploadMultiAttachs(files, id));
        } else {
          uploadMultiAttachs(files, roomId);
        }
      }
    },

    cancelFile(key) {
      dispatch(cancelFile(key))
    }
  }
}


class RoomInfo extends React.Component {
  state = {
    title:null,
    brief:null,
    cover:null,
    demo:null,
  }

  getCoverInfo = (val) => {
    this.setState({
      cover:val.path
    })
    this.props.updateCourseCover(val.path)
  }

  getDemoInfo = (val) => {
    if (/image/g.test(val.type)) {
      this.props.updateCourseDemo(val.path)
    } else {
      this.props.updateCourseDemo(val.name)
    }
  }

  getTitleVal = (val) => {
    this.setState({
      title:val
    })
  }

  getIntroVal = (val) => {
    this.setState({
      brief:val
    })
  }

  submitRoomInfo = () => {
    this.uploadTitleBrief();
    this.props.handleNext(this.props.activeStep);
  }

  uploadTitleBrief = () => {
    let {roomId} = this.props;
    let newData = {
      roomId,
      title:this.state.title,
      brief:this.state.brief,
    }
    axios({
      method:'put',
      url:`${config.env.server}/room/upload`,
      data:newData,
      headers:{'Authorization':'yezicoco'},
    })
    .then(res => {
      console.log('Update title and brief.')
      this.props.updateRoomInfo(this.state.title,this.state.brief)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  uploadSlides = (fileInfo, file) => {
    if (!this.props.roomId) {
      this.props.initRoomId(()=>{
        const { roomId } = this.props;
        const payload = {
          roomId,
        }
        //do whatever you want after roomId init
        //Update Slides Info
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        this.source = source;

        let formData = new FormData();

        Object.keys(payload).forEach((val) => {
          formData.append(val,payload[val])
        })
        //!!! append after roomId

        formData.append('pdf',file);

        this.props.uploadRoomSlides(roomId, formData, fileInfo)
      });
    } else {
      const { roomId } = this.props;
      const payload = {
        roomId,
      }
      //do whatever you want after roomId init
      //Update Slides Info
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      this.source = source;

      let formData = new FormData();

      Object.keys(payload).forEach((val) => {
        formData.append(val,payload[val])
      })
      //!!! append after roomId

      formData.append('pdf',file);

      this.props.uploadRoomSlides(roomId, formData, fileInfo)
    }
  }
  cancelFile = (key) => e => {
    console.log(e.target.parentNode.id)
  }
  componentDidMount() {
    // this.props.initRoomId(() => console.log('Room init'));
  }
  render () {
    if (this.props.activeStep === 1) {
      return <Redirect to={`/create/success`} />
    }

    const { roomId, slides, files, attachments, clips, queue } = this.props;
    const disabled = !(roomId && this.state.title && queue === 0
      && (slides.name || clips.length > 0 || attachments.length > 0));

    return (
      <Wrapper>
        <Subtitle
          areaname='title'
          name='房间'
          />
        <div css={`grid-area:content;`}>
          <TitleInput
            title="請輸入主題"
            
            getTitleVal={this.getTitleVal}
          />
          <IntroInput
            intro="請輸入介紹"
            rows="4"
            introId='course__intro'
            getIntroVal={this.getIntroVal}
          />
        </div>
        <RoomUploadPreview
          areaname='pdfUpload'
          inputid='roomPDFUpload'
          placeholder='點擊上傳示PDF文件'
          type="application/pdf"
          fileKey='pdf'
          value={(slides && slides.name) || null}
          max-size={20480000}
          onChange={this.uploadSlides}
        />
        <MultipleUpload
          key='multipleUploadClips'
          areaname='multipleUploadClips'
          icon={videoIcon}
          keys={clips}
          files={files}
          uploadFile={this.props.uploadClips(roomId)}
          placeholderContent={'將文件拖拽至此或點擊上傳影片'}
          max-size={51200000}
          max-length={15-clips.length}
          inputid='roomVideosUpload'
          type='video/*'
          cancelFile = {this.cancelFile}
        />
        <MultipleUpload
          key='multipleUploadAttachment'
          areaname='multipleUploadAttachment'
          type='*/*'
          icon={attachmentIcon}
          keys={attachments}
          files={files}
          uploadFile={this.props.uploadAttachments(roomId)}
          placeholderContent={'將文件拖拽至此或點擊上傳附件'}
          max-size={51200000}
          max-length={10-attachments.length}
          inputid='roomAttachmentUpload'
          cancelFile = {this.cancelFile}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          css={`
            width:20%;
            grid-area:nextStep;
            place-self:center;
            `}
          onClick={this.submitRoomInfo}
          disabled={disabled}
        >
          下一步
        </Button>
      </Wrapper>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(RoomInfo);
