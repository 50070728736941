import React from 'react'
import { connect } from 'react-redux'
import '../css/profile.css'
import axios from 'axios';
import qs from 'qs';
import config from '../config'
//  import youjian from '../../public/img/youjian.jpg'
import { toggleUserDropdown } from '../redux/Actions';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Modal, Table, Paper, TableHead, TableRow, TableBody, TableCell, TablePagination, Fade } from '@material-ui/core';
import XLSX from "xlsx";

let eye = require("../assets/img/manage/eye.png")
let eyeC = require("../assets/img/manage/eyeC.png")

const mapDispatchToProps = dispatch => ({
    openUserDropdown(state) {
        dispatch(toggleUserDropdown(state));
    },
});
const mapStateToProps = state => ({
    banners: state.banners,
    app: state.app
});

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}
const tableStyle = {
    border: '1px solid #eee'
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F4F6",
        color: '#000',
        fontSize: 18,
        fontWeight: "600",
        padding:0
        
    },
    body: {
        fontSize: 18,
        padding: 0

    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: "#f1f1f1",
        },
    },
}))(TableRow);

const table = {
    minWidth: 700,
    padding: "0 30px"
}
// cookie

const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);
const regEmail = new RegExp("[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+$");
axios.defaults.withCredentials = true;
class AddStudent extends React.Component {
    state = {
        userId: '',
        rows: [
        ],  // 学生数据
        rowsPerPage: 10,
        page: 0,
        addUserModal: false,
        addUserModal2: false,
        name: '',    //用户名
        password: '',    // 密码
        sex: '', //性别
        phone: '',   //手机号
        email: '',   //邮箱
        search: '', //查询
        count: 0,  // 查询的总数量
        classJson: [],
        contentS: "",
        studentArray: [],
        studentJson: [],
        isPassword: true,
    }

    componentDidMount() {
        console.log(this.props)
        this.getData()
    }
    componentWillUnmount = () => {
        window.sessionStorage.setItem('navClick', 0)
    }

    getData = () => {
        const { page, rowsPerPage, search } = this.state
        const params = {
            page: page + 1,
            num: rowsPerPage,
            content: search
        }
        axios.defaults.withCredentials = true;
        axios.post(`${config.env.edu}getuserstudents2/${this.props.userId}/${search ? search : " "}/${page + 1}/${rowsPerPage}`).then(res => {
            console.log(res)
            if (res.data[0].length > 0) {
                this.setState({
                    rows: res.data[0],
                    count: res.data[0][0].num
                })
            }
        })
    }

    getClass = () => {
        axios.post(`${config.env.edu}selectAllClass/${this.props.userId}`).then(res => {
            console.log(res)
            if (res.data[0].length > 0) {
                this.setState({
                    classJson: res.data[0]
                })
            }
        })
    }

    handleChangePage = (event, newPage) => {
        console.log(newPage)
        this.setState({
            page: newPage
        }, () => {
            this.getData()
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: this.state.page,
            rowsPerPage: +event.target.value
        }, () => {
            this.getData()
        })
    }

    // 註冊郵箱驗證
    registereEmail = e => {
        let str = e.target.value.replace(/(^\s*)|(\s*$)/g, "")
        if (!regEmail.test(str)) {
            window.U.Alert(this.props.language.registered_email_regular);
        }
        // else {
        //   this.setState({
        //     registereEmail: e.target.value
        //   })
        // }
    }

    addStudentSubmit = () => {
        // const { name } = this.state
        // var classc = window.$("#classid")[0];
        // var classid = classc.selectedOptions[classc.selectedIndex].id;
        // let str = name.replace(/(^\s*)|(\s*$)/g, "")
        // if (!regEmail.test(str)) {
        //     window.U.Alert(this.props.language.registered_email_regular);
        //     return;
        // }
        // if (classid == "") {
        //     window.U.Alert("请选择班级");
        //     return;
        // }

        // axios.get(`${config.env.edu}addstudents2/${name}/${classid}/${this.props.userId}/`).then(v => {
        //     if (v.data[0].length > 0) {
        //         if (v.data[0][0].success == 3) {
        //             window.U.Alert('添加失败，不能添加自己')
        //         } else if (v.data[0][0].success == 2) {
        //             window.U.Alert('添加失败，此学生不存在')
        //         } else if (v.data[0][0].success == 4) {
        //             window.U.Alert('添加失败，此学生已添加')
        //         } else if (v.data[0][0].success == 1) {
        //             this.getData()
        //             window.U.Alert('添加成功')
        //             this.setState({
        //                 addUserModal: false
        //             })
        //         } else {
        //             window.U.Alert('添加失败')
        //         }
        //     }
        // })

        const { studentArray } = this.state
        var classc = window.$("#classid")[0];
        var classid = classc.selectedOptions[0].id;
        if (studentArray.length == 0) {
            window.U.Alert("请选择需要添加的学生");
            return;
        }
        if (classid == "") {
            window.U.Alert("请选择班级");
            return;
        }
        let params = {
            users: studentArray,
            userId: this.props.userId,
            classId: classid
        }
        axios.post(`${config.env.edu}addstudents3`, qs.stringify(params)).then(res => {
            console.log(res)
            window.U.Alert('添加成功')
            this.setState({
                page: 0
            }, () => {
                this.getData()
            })
            this.setState({
                addUserModal: false,
                studentArray: [],
                contentS: "",

            })
        })
    }

    getStudent = () => {
        const { contentS } = this.state
        let params = {
            tid: this.props.userId,
            content: contentS
        }
        axios.post(`${config.env.edu}getStudent`, qs.stringify(params)).then(res => {
            console.log(res)
            // if (res.data[0].length > 0) {
            this.setState({
                studentJson: res.data[0]
            })
            // }
        })
    }
    addSJosn = (uid) => {
        let { studentArray } = this.state
        if (studentArray.indexOf(uid) !== -1) {
            studentArray.splice(studentArray.indexOf(uid), 1)
        } else {
            studentArray.push(uid)
        }

        this.setState({
            studentArray: studentArray
        })
    }

    // 添加学生
    addStudent = () => {
        this.getClass();
        this.getStudent();
        this.setState({
            addUserModal: true
        })
    }

    addStudent2 = () => {
        this.getClass();
        this.setState({
            addUserModal2: true
        })
    }


    // 选择性别
    radio = e => {
        this.setState({
            sex: e.target.value
        })
    }

    // 提交
    submit = () => {
        const { name, password } = this.state
        var classc = window.$("#classid")[0];
        var classid = classc.selectedOptions[0].id;
        let names = name.replace(/(^\s*)|(\s*$)/g, "")
        if (!names) {
            window.U.Alert("用户名必填");
            return;
        }
        if (!regEmail.test(names)) {
            window.U.Alert(this.props.language.registered_email_regular);
            return;
        }
        if (classid == "") {
            window.U.Alert("请选择班级");
            return;
        }
        let obj = {
            username: names,
            password: password,
            class: classid
        }
        let params = {
            userId: this.props.userId,
            users: JSON.stringify([obj])
        }
        axios.defaults.withCredentials = true;
        var a = "http://localhost:7001/edu/" // config.env.edu
        axios.post(`${config.env.edu}batchRegistration`, qs.stringify(params)).then(res => {
            if (res.status === 200) {
                this.setState({
                    addUserModal2: false,
                    page: 0
                }, () => {
                    this.getData()
                })
                window.U.Alert('添加成功')
            } else {
                window.U.Alert('添加失败')
            }
        }).catch(err => {
            window.U.Alert('添加失败')
        })
        // batchRegistration
    }

    // 删除
    del = (e, id) => {
        // axios.post(`${config.env.edu}admin/userinfo/del/${id}/`, []).then(v => {
        //     if (v.data === 1) {
        //         this.getData()
        //         window.U.Alert('删除成功')
        //     } else {
        //         window.U.Alert('删除失败')
        //     }
        // })
        var _this = this
        window.U.UF.UI.confirm("确定删除此学生吗?", function () {
            axios.post(`${config.env.edu}delstudent2/${id}/`, []).then(v => {
                // if (v.data === 1) {
                // _this.getData()
                _this.setState({
                    page: 0
                }, () => {
                    _this.getData()
                })
                window.U.Alert('删除成功')
                // } else {
                //     window.U.Alert('删除失败')
                // }
            })
        }, function () {
            return;
        })

    }

    downLoad = () => {
        let arr = [
            ['用户名', '密码', '班级'],
        ]
        // 将数组转化为标签页 
        var ws = XLSX.utils.aoa_to_sheet(arr);
        // 创建工作薄
        var wb = XLSX.utils.book_new()
        // 将标签页插入到工作薄里
        XLSX.utils.book_append_sheet(wb, ws)
        // 将工作薄导出为excel文件
        XLSX.writeFile(wb, '批量上传样例.xlsx');
    }

    importExcel = e => {
        var input = e.target.files;
        if (!input) {
            return;
        }
        // alert(obj.files[0].name);文件名
        var f = input[0];
        var reader = new FileReader();
        let _this = this

        reader.onload = function (e) {
            var data = e.target.result;
            var wb = XLSX.read(data, {
                type: 'binary' //以二进制的方式读取
            });

            var sheet0 = wb.Sheets[wb.SheetNames[0]];//sheet0代表excel表格中的第一页
            var str = XLSX.utils.sheet_to_json(sheet0);//利用接口实现转换。
            var templates = new Array();
            var str1 = input[0].name;
            templates = str1.split(".");//将导入文件名去掉后缀
            str = str.map(x => {
                let obj = {
                    username: x.用户名,
                    password: `${x.密码}`,
                    class: x.班级,
                }
                return obj
            })
            let params = {
                users: JSON.stringify(str),
                userId: _this.props.userId
            }
            axios.defaults.withCredentials = true;
            var a = "http://localhost:7001/edu/" //config.env.edu
            axios.post(`${config.env.edu}batchRegistration`, qs.stringify(params)).then(res => {
                console.log(res.status === 200)
                if (res.status === 200) {
                    window.U.Alert('添加成功')
                    this.setState({
                        page: 0
                    }, () => {
                        _this.getData()
                    })
                } else {
                    window.U.Alert('添加失败')
                }
            }).catch(err => {
                console.log(err)
                window.U.Alert('添加失败')
            })
            // window.localStorage.setItem(templates[0], JSON.stringify(str))//存入localStorage 中

        }
        reader.readAsBinaryString(f);
    }

    importExcel2 = e => {
        var input = e.target.files;
        if (!input) {
            return;
        }
        // alert(obj.files[0].name);文件名
        var f = input[0];
        var reader = new FileReader();
        let _this = this

        reader.onload = function (e) {
            var data = e.target.result;
            var wb = XLSX.read(data, {
                type: 'binary' //以二进制的方式读取
            });

            var sheet0 = wb.Sheets[wb.SheetNames[0]];//sheet0代表excel表格中的第一页
            var str = XLSX.utils.sheet_to_json(sheet0);//利用接口实现转换。
            var templates = new Array();
            var str1 = input[0].name;
            templates = str1.split(".");//将导入文件名去掉后缀
            str = str.map(x => {
                let obj = {
                    name: x.学生姓名,
                    class: x.班级,
                }
                return obj
            })
            let params = {
                users: JSON.stringify(str),
                userId: _this.props.userId
            }
            console.log(params);
            axios.defaults.withCredentials = true;
            var a = "http://localhost:7001/edu/" //config.env.edu
            axios.post(`${a}batchRegistration2`, qs.stringify(params)).then(res => {
                console.log(res.status === 200)
                if (res.status === 200) {
                    window.U.Alert('添加成功')
                    this.setState({
                        page: 0
                    }, () => {
                        _this.getData()
                    })
                } else {
                    window.U.Alert('添加失败')
                }
            }).catch(err => {
                console.log(err)
                window.U.Alert('添加失败')
            })
            // window.localStorage.setItem(templates[0], JSON.stringify(str))//存入localStorage 中

        }
        reader.readAsBinaryString(f);
    }

    //三个参数，title是生成的Excel文件名，headers是文件的头部，values为具体的json数据
    exportList = (title, headers) => {
        try {
            var a = "http://localhost:7001/edu/" //config.env.edu
            axios.post(`${a}exportStudent/${this.props.userId}`).then(res => {
                console.log(res);
                var res = res.data[0]
                //如果value的json字段的key值和想要的headers值不一致时，可做如下更改
                //将和下面的Object.fromEntries结合，将json字段的key值改变为要求的excel的header值
                var array = []
                for (var i = 0; i < res.length;i++){
                    var _json = {}
                    _json["用户名"] = res[i].username
                    _json["姓名"] = res[i].alias ? res[i].alias : ""
                    _json["班级"] = res[i].classname ? res[i].classname : ""
                    array.push(_json)
                }

                
                const workbook = XLSX.utils.book_new();//创建一个新的工作簿对象
                let ws = XLSX.utils.json_to_sheet(array);//将json对象数组转化成工作表
                ws["!cols"] = [//设置每一列的宽度
                    { wch: 50 },
                    { wch: 50 },
                    { wch: 50 },
                ]
                XLSX.utils.book_append_sheet(workbook, ws, "sheet1");//把sheet添加到workbook里，第三个参数是sheet名
                XLSX.writeFile(workbook, '学生信息.xlsx');
                // const wopts = { bookType: "xlsx", bookSST: false, type: "array" };//写入的样式bookType:输出的文件类型，type：输出的数据类型，bookSST: 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
                // const wbout = XLSX.write(workbook, wopts);// 浏览器端和node共有的API,实际上node可以直接使用xlsx.writeFile来写入文件,但是浏览器没有该API
                // FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${title} demo.xlsx`);//保存文件
            })
        } catch (e) {
            console.log(e, e.stack);
        }
    };

    render() {
        const { rows, page, rowsPerPage, addUserModal, addUserModal2, name, password, count, classJson, studentJson, contentS, studentArray, isPassword } = this.state;

        return (
            <div className="AddStudent">
                {/* <input type="file" placeholder="批量添加2" accept=".xlsx" onChange={e => this.importExcel2(e)} />
                <button onClick={this.exportList.bind(this, "exportList", ["username", "name", "class"])}>export</button> */}
                <div className="pb_head student">
                    学生列表<span className="hp_My">&nbsp;&nbsp;Student List</span>
                    <span onClick={this.downLoad} style={{ float: 'right', fontSize: 14, marginTop: 15, cursor: "pointer", textDecoration: 'underline', color: "#2268BC" }}>xls 上传样例</span>
                    <span className="downloadSpan" >批量添加</span>
                    <input type="file" placeholder="批量添加" accept=".xlsx" onChange={e => this.importExcel(e)} />
                    <button onClick={this.addStudent2}>未注册添加</button>
                    <button onClick={this.addStudent}>添加学生</button>
                </div>
                <div className="search">
                    <input placeholder="请输入手机号或用户名" onChange={e => { this.setState({ search: e.target.value }) }} /> <button onClick={this.getData}>查询</button>
                </div>
                <div style={table} component={Paper}>
                    {rows.length > 0 ? <Table style={tableStyle} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">用户名</StyledTableCell>
                                <StyledTableCell align="center">昵称</StyledTableCell>
                                <StyledTableCell align="center">性别</StyledTableCell>
                                <StyledTableCell align="center">班级</StyledTableCell>
                                <StyledTableCell align="center">手机号</StyledTableCell>
                                <StyledTableCell align="center">操作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.username}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.alias ? row.alias : "暂无"}</StyledTableCell>
                                    <StyledTableCell align="center">{row.sex ? (row.sex == 0 ? '女' : '男') : '暂无'}</StyledTableCell>
                                    <StyledTableCell align="center">{row.classname}</StyledTableCell>
                                    <StyledTableCell align="center">{row.phonenumber ? row.phonenumber : '暂无'}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <span style={{ color: '#78ACEE', cursor: 'pointer' }} onClick={(e) => this.del(e, row.id)}>删除</span>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table> :
                        <div style={{ textAlign: 'center', }}>
                            <img src={require('../assets/img/notcontent.png')} />
                        </div>}
                    {rows.length > 0 && <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelRowsPerPage="每页显示条数"
                    />}
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addUserModal}
                    onClose={() => {
                        this.setState({
                            addUserModal: false
                        })
                    }}
                >
                    <Fade in={addUserModal}>
                        <div className="addStudentModal" style={{ width: "455px" }}>
                            <div>
                                <h2>添加学生</h2>
                                <span className="sspan" onClick={() => { this.setState({ addUserModal: false }) }}><img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <ul>
                                <li>
                                    <div className="sj_panel">
                                        <div className="sj_panel_search" >
                                            <input type="text" value={contentS} placeholder="输入需要搜索的学生的账户或者名字" onChange={(e) => {
                                                this.setState({
                                                    contentS: e.target.value
                                                })
                                            }} />
                                            <span onClick={() => { this.getStudent() }}>搜索</span>
                                        </div>
                                        <div className="sj_panel_users">
                                            {studentJson.length > 0 ? studentJson.map((v, i) => (
                                                <div className="sj_panel_user" key={i} onClick={() => { this.addSJosn(v.userid) }}>
                                                    <input type="checkbox" checked={studentArray.indexOf(v.userid) !== -1} />
                                                    <span title={v.alias ? v.alias : "暂无姓名"}>{(v.alias ? v.alias : "暂无姓名")}</span>
                                                    <span title={v.username} style={{ marginLeft: "10px", width: "59%" }}>{v.username}</span>
                                                </div>
                                            )) :
                                                <div className="sj_panel_user">
                                                    暂无学生
                                                </div>}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <span>班级选择：</span>
                                    {/* <input className="inputs" type="password" value={password} placeholder='请输入密码' onChange={e => { this.setState({ password: e.target.value }) }} /> */}
                                    <select className="inputs" id="classid" style={{ fontSize: "14px" }}>
                                        {
                                            classJson.length > 0 ?
                                                classJson.map((v, i) => (
                                                    <option id={v.id}>{v.classname}</option>
                                                )) : <option>暂无班级</option>
                                        }
                                    </select>
                                </li>
                            </ul>
                            <div className="submit">
                                <button style={{ background: '#F23A3A' }} onClick={() => { this.addStudentSubmit() }}>确定</button>
                                <button style={{ background: '#AAA' }} onClick={() => { this.setState({ addUserModal: false }) }}>取消</button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addUserModal2}
                    onClose={() => {
                        this.setState({
                            addUserModal2: false
                        })
                    }}
                >
                    <Fade in={addUserModal2}>
                        <div className="addStudentModal">
                            <div>
                                <h2>添加学生</h2>
                                <span className="sspan" onClick={() => { this.setState({ addUserModal2: false }) }}> <img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <ul>
                                <li>
                                    <span className="sspan">用户名：</span>
                                    <input className="inputs" value={name} placeholder='请输入用户名' onBlur={this.registereEmail} onChange={e => { this.setState({ name: e.target.value }) }} /> &nbsp;
                                    <div style={{ marginTop: '5px' }}><i style={{ color: 'red' }}>*&nbsp;</i>学生账号平台使用的账户与名字（请使用邮箱）</div>
                                </li>
                                <li>
                                    <span className="sspan">密码：</span>
                                    {isPassword ?
                                        <input className="inputs" type="password" value={password} placeholder='请输入密码' onChange={e => { this.setState({ password: e.target.value }) }} style={{ paddingRight: "45px" }} /> :
                                        <input className="inputs" type="text" value={password} placeholder='请输入密码' onChange={e => { this.setState({ password: e.target.value }) }} style={{ paddingRight: "45px" }} />
                                    }
                                    {isPassword ?
                                        <img src={eye} className="eyeClass" onClick={() => { this.setState({ isPassword: false }) }} /> :
                                        <img src={eyeC} className="eyeClass" onClick={() => { this.setState({ isPassword: true }) }} />
                                    }
                                </li>
                                <li>
                                    <span className="sspan">班级选择：</span>
                                    {/* <input className="inputs" type="password" value={password} placeholder='请输入密码' onChange={e => { this.setState({ password: e.target.value }) }} /> */}
                                    <select className="inputs" id="classid">
                                        {
                                            classJson.length > 0 ?
                                                classJson.map((v, i) => (
                                                    <option id={v.id}>{v.classname}</option>
                                                )) : <option>暂无班级</option>
                                        }
                                    </select>
                                </li>
                                {/* <li>
                                    <span>&nbsp;性&nbsp;别</span>：
                                    <label onClick={this.radio}>
                                        <input type="radio" name='set' value="1" />&nbsp;&nbsp;男
                                        <input type="radio" name='set' value="2" style={{ marginLeft: 30 }} />&nbsp;&nbsp;女
                                    </label>
                                </li>
                                <li>
                                    <span>手机号</span>：
                                    <input className="inputs" placeholder='请输入手机号' onChange={e => { this.setState({ phone: e.target.value }) }} />
                                </li>
                                <li>
                                    <span>&nbsp;邮&nbsp;箱</span>：
                                    <input className="inputs" placeholder='请输入邮箱' onChange={e => { this.setState({ email: e.target.value }) }} />
                                </li> */}
                            </ul>
                            <div className="submit">
                                <button style={{ background: '#F23A3A' }} onClick={this.submit}>确定</button>
                                <button style={{ background: '#AAA' }} onClick={() => { this.setState({ addUserModal2: false }) }}>取消</button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}

export default AddStudent;

