import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import Subtitle from './Subtitle'
import TitleInput from './TitleInput';
import IntroInput from './IntroInput';
import AddItem from './AddItem'
//router
import axios from 'axios'

//config
import config from '../config'
//redux
import { connect } from 'react-redux';
//actions
import {
  initChapterInfo,
  initCourseId,
  nextStep,
  lastStep,
} from '../actions/upload';
const mapStateToProps = (state) =>{
  return{
    ...state.upload,
    courseId: state.upload.courseId,
    activeStep: state.steppers.activeStep,
  }
}

const mapDispatchToProps = (dispatch)=>{
  return{
    initChapterInfo(titles,length){
      dispatch(initChapterInfo(titles,length))
    },
    initCourseId(courseId){
      dispatch(initCourseId(courseId))
    },
    handleNext(activeStep){
      dispatch(nextStep(activeStep))
    },
    handleBack(activeStep){
      dispatch(lastStep(activeStep))
    },
  }
}


const Wrapper = styled.div`
  width:100%;
  overflow:auto;
  display: grid;
  margin:0;
  padding:0;
  grid-template-rows:30px 180px 200px 30px 172px 25px 25px;
  grid-template-columns: 17.5% 30% 5% 30% 17.5%;

  grid-template-areas:
  '. coursetitle . . .'
  '. courseinfo courseinfo courseinfo .'
  '. coursebg . courseposter .'
  '. chaptertitle . . .'
  '. list list list .'
  '. . . . .'
  '. nextStep . reset .';
`;

const Coursebg = styled.div`
  width:100%;
`;


const Courseposter = styled.div`
  width:100%;
`;

const List = styled.div`
  width:100%;
  padding-top:5px;
  overflow:auto; 
`;

class CourseInfo extends React.Component {
  state = {
    title: null,
    brief: null,
    cover: null,
    demo: null,
    chapters:[]
  }

  
  getCoverInfo = (val) => {
    this.setState({
      cover: val
    })
  }
  getDemoInfo = (val) => {
    this.setState({
      demo: val
    })
  }
  getTitleVal = (val) => {
    this.setState({
      title: val
    })
  }
  getIntroVal = (val) => {
    this.setState({
      brief: val
    })
  }
  getItemVal = (val) => {
    var chapter=[];
    for(var i=0;i<val.length;i++){
      let obj=val[i]
        chapter.push(obj.text)
    }
    this.setState({
      chapters: chapter
    })
  }
  submitCourseInfo = () => {
    let { title, brief, demo, cover,chapters } = this.state;
    if (title && brief && cover && demo&&chapters.length!==0) {
      axios({
        method: 'post',
        url: config.env.server + '/curriculum/upload',
        headers: {
          'Authorization': 'yezicoco',
          'Content-Type':'application/json'
        },
        data:{
            "lockList" : [ 0, 0, 0, 0, 1],
            "title" :title,
            "chapters" :chapters,
            "demo" : demo,
            "poster" : cover,
            "uploader" : "CoCoRoBo",
            "brief" : brief,
            "organize" : "CocoRobo",
            "avatar" : "//staging.cocorobo.hk/assets/img/fake__avatar--chan.png",
            "lock" : false,
            "status":1
        }
      })
      .then((res)=>{
        console.log(res)
        if(res.status===200){
          this.props.initCourseId(res.data)
          this.props.initChapterInfo(this.state.chapters,this.state.chapters.length)
          this.props.handleNext(this.props.activeStep)
          this.props.history.push('/Newupload/chapter')
        }
      })
      .catch((err)=>{
        window.alert('上传失败,请重新上传,Error:'+err)
        console.log(err)
      })
    } else {
      window.alert('请把信息填充完整')
    }
  }
  Reset = () => {
    this.coursetitle.reset();
    this.courseintro.reset();
    this.courseposter.reset();
    this.coursebg.reset();
    this.addlist.reset();
  }
  render() {
    
    return (
      <Wrapper>
        <Subtitle
          css={`grid-area:coursetitle; margin-left: -185px;`}
          areaname='title'
          name='课程信息'
        />
        <div css={`grid-area:courseinfo;`}>
          <TitleInput
            ref={(coursetitle) => { this.coursetitle = coursetitle; }}
            title="请输入课程名称"
            
            getTitleVal={this.getTitleVal}
          />
          <IntroInput
            ref={(courseintro) => { this.courseintro = courseintro; }}
            intro="请输入课程描述"
            rows="4"
            introId='course__intro'
            getIntroVal={this.getIntroVal}
          />
        </div>
        <Coursebg css={`grid-area:coursebg;`}>
          <IntroInput
            ref={(coursebg) => { this.coursebg = coursebg; }}
            intro="课程缩略图(JPG)"
            rows="6"
            introId='course__bg'
            getIntroVal={this.getCoverInfo}
          />
        </Coursebg>
        <Courseposter css={`grid-area:courseposter;`}>
          <IntroInput
            ref={(courseposter) => { this.courseposter = courseposter; }}
            intro="上传课程海报(JPG或MP4)"
            rows="6"
            introId='course__poster'
            getIntroVal={this.getDemoInfo}
          />
        </Courseposter>

        <Subtitle
          css={`grid-area:chaptertitle; margin-left: -185px;`}
          areaname='title'
          name='章节列表'
        />
        <List css={`grid-area:list;`}>
          <AddItem
            
            ref={(addlist) => { this.addlist = addlist; }}
            getItemVal={this.getItemVal}
          ></AddItem>
        </List>

        <Button
          variant="contained"
          color="primary"
          size="large"
          css={`
            width:40%;
            grid-area:nextStep;
            place-self:center;
            `}
          onClick={this.submitCourseInfo}
        >
          下一步
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          css={`
            width:40%;
            grid-area:reset;
            place-self:center;
            `}
          onClick={this.Reset}
        >
          重新填写
        </Button>
      </Wrapper>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseInfo);
