import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../css/profile.css'
import axios from 'axios';
import styled from 'styled-components'
import qs from 'qs';
import config from '../config'
//  import youjian from '../../public/img/youjian.jpg'
import { toggleUserDropdown } from '../redux/Actions';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Modal, Table, Paper, TableHead, TableRow, TableBody, TableCell, TablePagination, Fade } from '@material-ui/core';


const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}
const tableStyle = {
    border: '1px solid #eee'
}

const Button = styled.div`
       color: #fff;
    cursor: pointer;
    text-align: center;
    width: 90px;
    margin: 0 10px;
    background: #5190fd;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    font-size:16px
`

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F4F6",
        color: '#000',
        fontSize: 18,
        fontWeight: "600",
        padding: 0

    },
    body: {
        fontSize: 18,
        padding: 0,
        width: "50%"
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: "#f1f1f1",
        },
    },
}))(TableRow);

const table = {
    minWidth: 700,
    padding: "0 30px",
    marginTop: "10px"
}
export class type extends Component {
    state = {
        userId: '',
        rows: [
        ],  // 学生数据
        rowsPerPage: 10,
        page: 0,
        count: 0,  // 查询的总数量
        pid: '0',
        addModal: false,
        typename: '',
        addClassModal: '',
        contentS: '',
        courseJson: [],
        courseArray: [],
        tid: ""
    }
    componentDidMount() {
        console.log(this.props)
        this.getData()
    }
    getData = () => {
        const { pid, page } = this.state
        let params = {
            pid: pid,
            page: page + 1,
        }
        axios.post(`${config.env.edu}getType`, qs.stringify(params)).then(res => {
            console.log(res)
            // if (res.data[0].length > 0) {
            this.setState({
                rows: res.data[0]
            })
            // }
        })
    }

    getCType = (id) => {
        this.setState({
            page: 0,
            pid: id,
        }, () => {
            this.getData();
        })

    }

    submit = () => {
        const { pid, typename } = this.state
        if (typename == '') {
            window.U.Alert('分类名称不能为空')
            return;
        }
        let params = {
            pid: pid,
            n: typename,
            uid: this.props.userId
        }
        axios.post(`${config.env.edu}addType`, qs.stringify(params)).then(res => {
            if (res.status === 200) {
                this.setState({
                    addModal: false,
                    typename: ""
                }, () => {
                    this.getData()
                })
                window.U.Alert('添加成功')
                this.state.classname = "";
            } else {
                window.U.Alert('添加失败')
            }
        }).catch(err => {
            window.U.Alert('添加失败')
        })
    }


    del = (id) => {
        var cid = id;
        var _this = this
        window.U.UF.UI.confirm("确定删除此班级吗?", function () {
            let params = {
                tid: cid,
            }
            axios.post(`${config.env.edu}deleteType`, qs.stringify(params)).then(v => {
                if (v.data === 1) {
                    _this.getData()
                    window.U.Alert('删除成功')
                } else {
                    window.U.Alert('删除失败')
                }
            })
        }, function () {
            return;
        })
    }

    addCourse = (id) => {
        this.setState({
            addClassModal: true,
            tid: id
        }, () => {
            this.getCourse();
        })
    }

    getCourse = (type) => {
        const { contentS,tid } = this.state
        let params = {
            content: contentS,
            tid:tid,
        }
        axios.post(`${config.env.edu}getTypeCourse`, qs.stringify(params)).then(res => {
            console.log(res)
            // if (res.data[0].length > 0) {
            this.setState({
                courseJson: res.data[0]
            })
            var array = []
            // if (res.data[1].length > 0) {
                for (var i = 0; i < res.data[1].length;i++){
                    array.push(res.data[1][i].courseid);
                }
            if(type != '1'){
                this.setState({
                    courseArray: array
                })
            }
            console.log(array)
            // }
            // }
        })
    }
    addSJosn = (cid) => {
        let { courseArray } = this.state
        if (courseArray.indexOf(cid) !== -1) {
            courseArray.splice(courseArray.indexOf(cid), 1)
        } else {
            courseArray.push(cid)
        }

        this.setState({
            courseArray: courseArray
        })
    }

    addCourseSubmit = () => {
        const { courseArray, tid } = this.state
        let params = {
            course: courseArray.join(","),
            tid: tid,
            uid: this.props.userId
        }
        axios.post(`${config.env.edu}addCourseType`, qs.stringify(params)).then(v => {
            this.getData()
            this.setState({
                addClassModal: false,
                tid:'',
                courseArray:[]
            })
            window.U.Alert('添加成功')
        }).catch(err => {
            window.U.Alert('添加失败')
        })
    }
    render() {
        const { rows, page, rowsPerPage, count, pid, addModal, typename, addClassModal, contentS, courseJson, courseArray } = this.state;
        return (
            <div className="AddStudent">
                <div className="pb_head student">
                    分类管理
                    {pid != '0' ?
                        (<span><button onClick={() => { this.setState({ addModal: true }) }} >添加分类</button><button onClick={() => this.getCType(0)} >返回</button></span>)
                        :
                        ""}
                </div>
                <div style={table} component={Paper}>
                    {rows.length > 0 ? <Table style={tableStyle} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">分类</StyledTableCell>
                                <StyledTableCell align="center">操作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.pid == '0' ? <Button onClick={() => this.getCType(row.id)}>查看分类</Button> : ''}
                                        {row.pid != '0' ? <span>
                                            <Button onClick={() => this.addCourse(row.id)}>添加课程</Button>
                                            <Button onClick={() => this.del(row.id)}>删除</Button>
                                        </span> : ''}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table> : <div style={{ textAlign: 'center', }}>
                        <img src={require('../assets/img/notcontent.png')} />
                    </div>}
                    {rows.length > 0 && <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelRowsPerPage="每页显示条数"
                    />}
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addModal}
                    onClose={() => {
                        this.setState({
                            addModal: false
                        })
                    }}
                >
                    <Fade in={addModal}>
                        <div className="addStudentModal" style={{ width: "470px" }}>
                            <div>
                                <h2>添加分类</h2>
                                <span className="sspan" onClick={() => { this.setState({ addModal: false }) }}><img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <ul style={{ margin: '20px auto' }}>
                                <li>
                                    <span class="addName">分类名称</span>：
                                    <input style={{ width: "auto" }} className="inputs" value={typename} placeholder='请输入分类名称' onChange={e => { this.setState({ typename: e.target.value }) }} /> &nbsp;
                                </li>
                            </ul>
                            <div className="submit">
                                <button style={{ background: '#F23A3A' }} onClick={() => { this.submit() }}>确定</button>
                                <button style={{ background: '#AAA' }} onClick={() => { this.setState({ addModal: false }) }}>取消</button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addClassModal}
                    onClose={() => {
                        this.setState({
                            addClassModal: false
                        })
                    }}
                >
                    <Fade in={addClassModal}>
                        <div className="addStudentModal" style={{ width: "455px" }}>
                            <div>
                                <h2>添加分类</h2>
                                <span className="sspan" onClick={() => { this.setState({ addClassModal: false }) }}><img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <ul>
                                <li>
                                    <div className="sj_panel">
                                        <div className="sj_panel_search" >
                                            <input type="text" value={contentS} placeholder="输入需要搜索的课程名称" onChange={(e) => {
                                                this.setState({
                                                    contentS: e.target.value
                                                })
                                            }} />
                                            <span onClick={() => { this.getCourse('1') }}>搜索</span>
                                        </div>
                                        <div className="sj_panel_users">
                                            {courseJson.length > 0 ? courseJson.map((v, i) => (
                                                <div className="sj_panel_user" key={i} onClick={() => { this.addSJosn(v.courseId) }}>
                                                    <input type="checkbox" checked={courseArray.indexOf(v.courseId) !== -1} />
                                                    <span style={{ width: "calc(100% - 23px)", textAlign: "left" }} title={v.title + ' 编号:' + v.number}>{v.title + ' 编号:' + v.number}</span>
                                                </div>
                                            )) :
                                                <div className="sj_panel_user">
                                                    暂无课程
                                                </div>}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="submit">
                                <button style={{ background: '#F23A3A' }} onClick={() => { this.addCourseSubmit() }}>确定</button>
                                <button style={{ background: '#AAA' }} onClick={() => { this.setState({ addClassModal: false }) }}>取消</button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}



export default type
