import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import '../css/login.css';
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';
import { Grid, Radio, FormControl, FormLabel, FormControlLabel, RadioGroup, TextField, Button, Modal, Switch } from '@material-ui/core/';
import ChapterattachmentUrlUploadPreview from '../components/ChapterattachmentUrlUploadPreview'
import ChapterSlideUploadPreview from '../components/ChapterSlideUploadPreview'
import ChapterBgUploadPreview from '../components/ChapterBgUploadPreview'
import ChapterClipUploadPreview from '../components/ChapterClipUploadPreview'

const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
const reg1 = /^[0-9a-zA-Z]{0,6}$/

const mapDispatchToProps = (dispatch) => ({
    openRoomModal(state) {
        dispatch(toggleRoom(state));
    },
    toggleLogin(state) {
        dispatch(toggleLogin(state));
    },
    openUserDropdown(state) {
        dispatch(toggleUserDropdown(state));
    },
});
const mapStateToProps = state => {
    const { profile } = state.user;
    return {
        loginModalIsOpen: state.modals.loginModalIsOpen,
        userDropdownIsOpen: state.modals.userDropdownIsOpen,
        count: state.newupload.course.count,
    };
};


const input = `
    display:block;
    width:100%;
    outline:none;
    padding:18px 14px;
    // background:#f6f6f6;
    // border:1px solid #bbb;
`

const label = `
    font-size:16px;
    font-weight:bold;
    text-align:left;
    margin:10px 0;
`;

const SubTitle = styled.div`
    margin: 15px 0;
    font-size: 17px;
    font-weight: bold;
    text-align: left;
`;


class passWord extends React.Component {
    // const [completed, setCompleted] = useState(0)
    constructor(props) {
        super(props)
        this.state = {
            isShow: '1',
            roomNum: '',     // 课程ID
            roomID: "",      // 房间id
            chapter: '',     // 章节
            chapter_id_name:"",    // 章节名称
            passWord: '',    // 房间密码
            chapterRoomName: '', // 新章节的房间名称
            checked: false,
            chaptersinfo: {
                title: '',
                teacher: {
                    bg: [],//缩略图
                    intro: '',//描述
                    clipUrl: [],//视频
                    slideUrl: [],//PPT
                    attachmentUrl: [],//附件
                },
            },
            userId: '',
            id: '',
            chapteArr: [],
            chapterShow: false,
            courseShow: false,       // 课程提示
            loading: true,
            roomBorderColor: false,
            switchShow: false,  // 是否显示房间密码
            id: '',
        }
    }

    componentDidMount() {
        var _this = this
        let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
        let id = window.U.UF.C.queryString("id")
        document.title = id?"修改房间":"创建房间"
        if (!!!arr) {
            this.props.history.push('/login')
        }
        else {
            axios.defaults.withCredentials = true;
            axios.get(`${config.env.server}getcookieuserid`).then(res => {
                if (res.data) {
                    _this.setState({
                        userId: res.data[0][0].userid
                    });
                }
            }).catch(err => {
                _this.props.history.push('/login');
                //window.alert(err);
            })
        }

        if (id) {
            axios.post(`${config.env.edu}profile/selectroomid/${id}`).then(res => {
                let data = res.data[0][0]
                if (data) {
                    if (data.content) {
                        _this.setState({
                            isShow: '2',
                            id: data.id,
                            chaptersinfo: JSON.parse(decodeURIComponent(decodeURIComponent(data.content)))[0],
                            chapterRoomName: data.name,
                            passWord: data.password,
                            loading: false,
                            switchShow: data.password ? true : false,
                            id: id,
                            roomID: data.account
                        })
                    } else {
                        _this.setState({
                            isShow: '1',
                            id: data.id,
                            chapterRoomName: data.name,
                            roomNum: data.courseid,
                            chapter: Number(data.chapterid) + 1,
                            chapter_id_name:JSON.parse(data.chapters)[Number(data.chapterid)].title,
                            passWord: data.password,
                            loading: false,
                            switchShow: data.password ? true : false,
                            id: id,
                            roomID: data.account
                        },()=>{
                            console.log(_this.state)
                        })
                    }
                }
            }).catch(err => {

            })
        } else {
            _this.setState({
                loading: false
            })
        }
    }

    // 截取某個字符串
    QueryString = (item) => {
        var sValue = window.location.search.match(new RegExp("[\?\&]" + item + "=([^\&]*)(\&?)", "i"));
        return sValue ? sValue[1] : sValue;
    }

    // 点击单选按钮选择以那种方式上传
    radioClick = (e) => {
        this.setState({
            isShow: e.target.value,
        })
    }

    // 返回首頁
    returnHome = () => {
        this.props.history.push('/login')
    }

    submit = () => {
        const { roomNum, roomID, chapter, passWord, isShow, chaptersinfo, userId, id, chapterRoomName } = this.state
        const { language } = this.props
        if (!roomID || !reg1.test(roomID)) {
            alert(language.room_name_prompt);
            return;
        }
        if (isShow === '1') {
            if (!chapterRoomName) {
                alert(language.room_name);
                return;
            }
            if (!roomNum) {
                alert(language.course_id);
                return;
            }
            if (!chapter) {
                alert(language.chapter_choose);
                return;
            }
            axios.post(`${config.env.edu}profile/selectcoursenumber/${roomNum}/`).then(res => {
                if (res.data[0][0]) {
                    let mode = id ? `${id},${encodeURIComponent(chapterRoomName)},${passWord},${roomNum},${chapter - 1},,${userId},${''}` : `${encodeURIComponent(chapterRoomName)},${passWord},${roomNum},${chapter - 1},,${userId},${''},${roomID}`
                    id ? this.updateRoom(mode) : this.createRoom(mode)
                }
                // else {
                //     alert('該課程ID不存在，請填寫存在的課程ID')
                // }
            }).catch(err => {
                console.log(err.response)
            })
        } else {
            if (this.props.count > 0) {
                alert(language.wait_upload_complete);
                return;
            }
            if (!chapterRoomName) {
                alert(language.room_name);
                return;
            }
            if (!chaptersinfo.title) {
                alert(language.write_chapter_name);
                return;
            }
            if (chaptersinfo.teacher.bg.length === 0) {
                alert(language.upload_back);
                return
            }
            if (chaptersinfo.teacher.slideUrl.length === 0) {
                alert(language.upload_pdf);
                return
            }
            if (!chaptersinfo.teacher.intro) {
                alert(language.write_chapter_prompt);
                return
            }
            let param = chaptersinfo
            param.teacher.bg.map(x => x.url);
            param.teacher.clipUrl.map(x => x.url);
            param.teacher.slideUrl.map(x => x.url);
            param.teacher.attachmentUrl.map(x => x.url)
            let arr = encodeURIComponent(encodeURIComponent(JSON.stringify([param])))
            let mode = id ? `${id},${encodeURIComponent(chapterRoomName)},${passWord},,,,${userId},${arr}` : `${encodeURIComponent(chapterRoomName)},${passWord},,,,${userId},${arr},${roomID}`
            id ? this.updateRoom(mode) : this.createRoom(mode)
        }
    }

    createRoom = val => {
        const { language } = this.props
        axios.post(`${config.env.edu}profile/roomscreate`, qs.stringify({ mode: val })).then(res => {
            if (res.data[0][0].account) {
                window.U.Alert(language.create_complete)
                this.props.history.push('/roomResult/' + this.state.roomID)
            } else {
                window.U.Alert(language.room_id_exist)
            }
        }).catch(err => {
            window.U.Alert(language.create_failure)
        })
    }

    updateRoom = val => {
        const { language } = this.props
        axios.post(`${config.env.edu}profile/updateroom`, qs.stringify({ mode: val })).then(res => {
            if (res.data === 1) {
                window.U.Alert(language.change_complete)
                this.props.history.push('/roomResult/' + this.state.roomID)
            }
        }).catch(err => {
            window.U.Alert(language.change_failure)
        })
    }

    handleChange = (event, i) => {
        let id = i + 1;
        let { chaptersinfo } = this.state;
        let value = event.target.checked;
        let list = chaptersinfo
        for (let i = 0; i < list.length; i++) {
            let obj = list[i]
            if (obj.id === id) {
                obj.checked = value
            }
            this.setState({
                chaptersinfo: list
            })
        }
        if (list[i].checked === true) {
            [
                list[i].student.bg,
                list[i].student.intro,
                list[i].student.clipUrl,
                list[i].student.slideUrl,
                list[i].student.attachmentUrl
            ] = [list[i].teacher.bg,
            list[i].teacher.intro,
            list[i].teacher.clipUrl,
            list[i].teacher.slideUrl,
            list[i].teacher.attachmentUrl
                ]
        } else {
            list[i].student.bg = [];
            list[i].student.intro = '';
            list[i].student.clipUrl = [];
            list[i].student.slideUrl = [];
            list[i].student.attachmentUrl = []
        }
        this.setState({
            chaptersinfo: list
        })
    }



    // 背景图
    updateTeacherchapterBg = (val) => {
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        list.teacher.bg = val
        this.setState({
            chaptersinfo: list
        })
    }
    // PDF
    updateTeacherchapterSlide = val => {
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        list.teacher.slideUrl = val
        this.setState({
            chaptersinfo: list
        })
    }
    // 章节描述
    updateTeachersIntro = val => {
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        list.teacher.intro = val.target.value
        this.setState({
            chaptersinfo: list
        })
    }
    // 视频
    updateTeacherchapterClip = val => {
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        list.teacher.clipUrl = val
        this.setState({
            chaptersinfo: list
        })
    }
    // 附件
    updateTeacherchapterAttachment = val => {
        const { chaptersinfo } = this.state;
        let list = chaptersinfo
        list.teacher.attachmentUrl = val
        this.setState({
            chaptersinfo: list
        })
    }

    clickList = (e,title) => {
        this.setState({
            chapter: e+1,
            chapterShow: false,
            chapter_id_name:title
        })
    }

    // 获取房间名称
    get_room_name = e => {
        this.setState({
            chapterRoomName: e.target.value
        })
    }

    // 房间id
    get_room_id = e => {
        if (!reg1.test(e.target.value)) {
            this.setState({
                roomBorderColor: true
            })
        } else {
            this.setState({
                roomBorderColor: false
            })
        }
        this.setState({
            roomID: e.target.value
        })
    }

    switchCheck = e => {
        this.setState({
            switchShow: !this.state.switchShow
        })
    }

    render() {
        const { isShow, roomNum, chapterShow, passWord, chaptersinfo, chapter_id_name, chapteArr, id, chapterRoomName, courseShow, roomBorderColor, roomID, switchShow } = this.state
        const { language } = this.props
        return (
            <div className="room" style={{ width: '80%', margin: '0 auto' }} onClick={() => {
                this.setState({
                    chapterShow: false
                })
            }}>
                <Grid container item lg={12} spacing={2} style={{ height: '100%' }}>
                    <Grid item lg={3} md={3} sm={6} >
                        <div css={`font-size:30px;font-weight:bold;text-align:left;margin-top: 20px;margin-bottom:10px;`}>{id ? `${language.change_room}` : `${language.create_room}`} </div>
                        <FormControl component="fieldset">
                            <FormLabel style={{ padding: '10px 0' }} component="legend">{id ? `${language.change_room}` : `${language.create_room}`}：</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={isShow} onChange={this.radioClick}>
                                <FormControlLabel value='1' disabled={id ? true : false} control={<Radio />} label={language.create_room_course_chapter} />
                                <FormControlLabel value='2' disabled={id ? true : false} control={<Radio />} label={language.create_room_upload_chapter} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={8} md={8} sm={6} style={{ height: '100%', borderLeft: '1px solid #3d67bc', marginTop: 20 }}>
                        {isShow === '1' ? <div >
                            <h2 style={{ paddingLeft: '10%', fontSize: 28 }}>{language.create_room_course_chapter}</h2>
                            <ul style={{ marginTop: 50, paddingLeft: '10%' }}>
                                <li>
                                    <div css={label}><span css={`color:red`}>*</span>{language.course_id}: {courseShow ? <span style={{ color: 'red', fontSize: 12, marginLeft: 20 }}>{language.cpurse_no}</span> : ""}</div>
                                    <input css={input} value={roomNum} placeholder={language.course_id} onChange={e => {
                                        this.setState({
                                            roomNum: e.target.value
                                        })
                                    }} onBlur={e => {
                                        if (!e.target.value) {
                                            return
                                        }
                                        axios.get(`${config.env.edu}course/${e.target.value}`).then(res => {
                                            if (res.data[0][0]) {
                                                this.setState({
                                                    chapteArr: JSON.parse(res.data[0][0].chapters),
                                                    courseShow: false,
                                                    chapter: '',
                                                })
                                            } else {
                                                this.setState({
                                                    courseShow: true,
                                                    chapteArr: [],
                                                    chapter: '',
                                                })
                                            }
                                        }).catch(err => {
                                            this.setState({
                                                courseShow: true,
                                                chapteArr: [],
                                                chapter: '',
                                            })
                                        })
                                    }} />
                                </li>
                                <li style={{ position: 'relative' }} onClick={e => {
                                    e.stopPropagation()
                                }}>
                                    <div css={label}><span css={`color:red`}>*</span>{language.chapter_choose}: </div>
                                    <input placeholder={language.chapter_choose_prompt}
                                        css={input}
                                        onChange={e => {
                                            this.setState({
                                                // chapter: e.target.value,
                                                chapterShow: true
                                            })
                                        }}
                                        onFocus={(e) => {
                                            this.setState({ chapterShow: true })
                                        }} value={chapter_id_name} />
                                    {chapterShow ?
                                        <ul className="selectList">
                                            {chapteArr.length > 0 ? chapteArr.map((x, k) => {
                                                return (
                                                    <li key={x.id} onClick={() => this.clickList(k,x.title)}>{k + 1}.{x.title}</li>
                                                )
                                            }) : <div style={{ textAlign: 'center', padding: 10 }}>{(roomNum && !courseShow) ? `${language.data_load}` : `${language.chapter_choose_prompt}`}</div>}
                                        </ul> : ''}
                                </li>
                            </ul>
                        </div> : <div>
                                <h2 style={{ paddingLeft: '10%', fontSize: 28 }}>{language.create_room_upload_chapter}</h2>
                                <div style={{ marginTop: 50, paddingLeft: '10%' }}>
                                    <div css={label}><span css={`color:red`}>*</span>{language.chapter_name}: </div>
                                    <input css={input} value={chaptersinfo.title} placeholder={language.chapter_name} onChange={e => {
                                        let obj = this.state.chaptersinfo
                                        obj.title = e.target.value
                                        this.setState({
                                            chaptersinfo: obj
                                        })
                                    }} />
                                    <div css={`clear:both;`}>
                                        <div css={`width:48%;float:left;`}>
                                            <SubTitle>
                                                <span css={`color:red`}>*</span>{language.chapter_back_img}
                                                <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>PNG, GIF, JPEG</span>
                                            </SubTitle>
                                            <div css={`height:114px;margin-top:22px;`}>
                                                <ChapterBgUploadPreview
                                                    preread={chaptersinfo.teacher.bg}
                                                    getChapterBgVal={(value) => this.updateTeacherchapterBg(value)}
                                                    language={language}
                                                ></ChapterBgUploadPreview>
                                            </div>
                                        </div>
                                        <div css={`width:48%;float:right;margin-top:6px;`}>
                                            <SubTitle>
                                                <span css={`color:red`}>*</span>{language.chapter_PDF}
                                                <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>PDF</span>
                                            </SubTitle>
                                            <div css={`height:135px;width:100%;float:left`}>
                                                <ChapterSlideUploadPreview
                                                    preread={chaptersinfo.teacher.slideUrl}
                                                    format='application/pdf'
                                                    getChapterSlideVal={(value) => this.updateTeacherchapterSlide(value)}
                                                    language={language}
                                                />
                                            </div>
                                        </div>
                                        <div css={`width:48%;float:left`}>
                                            <SubTitle><span css={`color:red`}>*</span>{language.chapter_describe}:</SubTitle>
                                            <TextField
                                                value={chaptersinfo.teacher.intro}
                                                onChange={(e) => this.updateTeachersIntro(e)}
                                                row={4}
                                                label={language.chapter_describe_prompt}
                                                variant="outlined"
                                                fullWidth={true}
                                                multiline={true}
                                                margin='dense'
                                                rows={4}
                                            ></TextField>
                                        </div>
                                        <div css={`width:48%;float:right;margin-top:-20px;`}>
                                            <SubTitle>
                                                {language.chapter_video}:<span style={{ color: "red", fontSize: "12px" }}>(*{language.chapter_video_prompt})</span>
                                                <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>MP4, M4V, MOV, AVI</span>
                                            </SubTitle>
                                            <div css={`height:135px;`}>
                                                <ChapterClipUploadPreview
                                                    preread={chaptersinfo.teacher.clipUrl}
                                                    getChapterClipVal={(value) => this.updateTeacherchapterClip(value)}
                                                    language={language}
                                                />
                                            </div>
                                        </div>
                                        <div css={`width:48%;overflow:hidden;`}>
                                            <SubTitle>
                                                {language.chapter_attachment}:<span style={{ color: "red", fontSize: "12px" }}>(*{language.chapter_video_prompt})</span>
                                                <span css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128);margin-top:5px`}>{language.all_files}</span>
                                            </SubTitle>
                                            <div css={`height:135px;`}>
                                                <ChapterattachmentUrlUploadPreview
                                                    preread={chaptersinfo.teacher.attachmentUrl}
                                                    getChapterAttachmentUrlVal={(value) => this.updateTeacherchapterAttachment(value)}
                                                    language={language}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                        <div style={{ marginLeft: '10%' }}>
                            <div css={label}><span css={`color:red`}>*</span>{language.room_name}: </div>
                            <input css={input} value={chapterRoomName} placeholder={language.room_name} onChange={this.get_room_name} />
                        </div>
                        <div style={{ marginLeft: '10%' }}>
                            <div css={label}><span css={`color:red`}>*</span>{language.room_id}: </div>
                            <input disabled={id ? true : false} style={roomBorderColor ? { borderColor: 'red', borderWidth: 2 } : { borderColor: '#bbb' }} css={input} value={roomID} placeholder={language.room_id} onChange={this.get_room_id} />
                        </div>
                        <div style={{ marginLeft: '10%' }}>
                            <FormControlLabel
                                checked={switchShow}
                                control={<Switch color="primary" onClick={this.switchCheck} />}
                                label={language.is_room_password}
                                labelPlacement="end"
                            />
                            {switchShow ? <div css={label}>{language.room_passworld}:</div> : ""}
                            {switchShow ? <input css={input} placeholder={language.room_passworld} type='text' onChange={e => {
                                this.setState({
                                    passWord: e.target.value
                                })
                            }}
                                css={`
                                        -webkit-text-security:disc;text-security:disc;
                                        `} /> : ''}
                        </div>
                        <div style={{ textAlign: 'center', margin: "0 0 60px 10%", display: 'flex', justifyContent: 'center', flexWrap: 'wrap', }}>
                            <Button onClick={this.returnHome} style={{ background: '#3d67bc', color: '#fff', fontSize: 20, padding: '5px 8px', marginTop: 40 }}>{language.return_home}</Button>
                            <Button onClick={this.submit} style={{ background: '#3d67bc', color: '#fff', fontSize: 20, padding: '5px 8px', marginTop: 40, marginLeft: '10%' }}>{id ? `${language.change_room}` : `${language.create_room}`}</Button>
                        </div>
                    </Grid>
                </Grid>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.loading}
                    onClose={() => {
                        this.setState({
                            loading: true
                        })
                    }}
                >
                    <div style={{ width: '100%', height: '100%' }}>
                        <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
                        <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{language.loadings}</p>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(passWord);;
