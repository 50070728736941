import React, { Component } from 'react'
import axios from 'axios';
import config from '../config'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Modal, Table, Paper, TableHead, TableRow, TableBody, TableCell, TablePagination, Fade } from '@material-ui/core';
import '../css/studentSum.css'
import ReactEcharts from "echarts-for-react";
import * as echarts from 'echarts';
import qs from 'qs';

const tableStyle = {
    border: '1px solid #eee'
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F4F6",
        color: '#000',
        fontSize: 18,
        fontWeight: "600",
        borderLeft: "2px solid #F3F4F6"
    },
    body: {
        fontSize: 18,
        borderLeft: "2px solid #F3F4F6"
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: "#f1f1f1",
        },
    },
    body: {

    },
}))(TableRow);

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const table = {
    minWidth: 700,
    // padding: "0 30px"
}


export class studentSum extends Component {
    state = {
        userId: '',
        classname: '',
        rows: [
        ],  // 班级数据
        page: 0,
        addClassModal: false,
        search: "", //查询
        count: 0,  // 查询的总数量,
        sid: "",
        test: 0,
        code: 0,
        sum: 0,
        ssid: "",
        remarks: "",
        testZ: [],
        score: "",
        number: "",
        pChapters: [],
        ppeople: "",
        ut: "",
        tname: {},
        homework: [],
        cid: "",
    }

    componentDidMount() {
        console.log(this.props)
        this.setState({
            number: this.props.userId
        }, () => {
            this.getData();
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            cid: nextProps.courseId
        }, () => {
            this.getData();
        })
    }

    getData = () => {
        let params = {
            n: this.state.number,
            cid: this.state.cid,
            page: this.state.page + 1
        }
        var a = "http://localhost:7001/edu/"//config.env.edu
        axios.post(`${config.env.edu}getSummaryById2`, qs.stringify(params)).then(res => {
            console.log(res)
            // if (res.data[0].length > 0) {
            this.setState({
                rows: res.data[0],
                count: res.data[0].length > 0 ? res.data[0][0].num : 0
            })
            // }
        })
    }

    handleChangePage = (event, newPage) => {
        console.log(newPage)
        this.setState({
            page: newPage
        }, () => {
            this.getData()
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: this.state.page,
            rowsPerPage: +event.target.value
        }, () => {
            this.getData()
        })
    }

    get = (x, type) => {
        if (!x) {
            window.U.alert("该学生未上传" + (type == 1 ? "图片" : "视频"))
            return;
        }
        window.open(x)
    }

    sum = (sid) => {
        // this.setState({
        //     addClassModal: true,
        //     sid: sid,
        //     ssid: sid.id
        // })

        axios.get(`${config.env.edu}getSummaryById2/${sid.courseid}/${sid.userid}`).then(res => {
            var course = res.data[0][0]
            if (course.score) {
                this.setState({
                    score: JSON.parse(course.score)
                })
            } else {
                this.setState({
                    score: ""
                })
            }
            var ti = res.data[1]
            var code = res.data[2]

            var testZ = []
            var codeZ = []
            var chapters = JSON.parse(course.chapters)
            for (var i = 0; i < chapters.length; i++) {
                for (var j = 0; j < chapters[i].chapterData.length; j++) {
                    if (chapters[i].chapterData[j].typeName == "测验") {
                        testZ.push(chapters[i].chapterData[j])
                    }
                    if (chapters[i].chapterData[j].typeName == "编程") {
                        codeZ.push(chapters[i].chapterData[j])
                    }
                }
            }
            if (ti.length < testZ.length) {
                window.U.alert("此学生还未做完全部测验，暂不能打分")
                return;
            }
            if (code.length < codeZ.length) {
                window.U.alert("此学生还未提交全部编程作业，暂不能打分")
                return;
            }

            for (var i = 0; i < testZ.length; i++) {
                for (var j = 0; j < ti.length; j++) {
                    if (ti[j].chapterid == testZ[i].id) {
                        testZ[i].Rscore = ti[j].Rscore
                        testZ[i].Ccount = JSON.parse(ti[j].Ccount)
                        break;
                    }
                }
            }
            this.setState({
                addClassModal: true,
                sid: sid,
                ssid: sid.id,
                testZ: testZ
            })

            console.log(res);
        })

    }

    starLight = (score, type) => {
        if (type == 1) {
            this.setState({
                test: score
            })
        } else if (type == 2) {
            this.setState({
                code: score
            })
        } else if (type == 3) {
            this.setState({
                sum: score
            })
        }
    }

    submit = () => {
        const { test, code, sum, ssid, remarks, testZ } = this.state
        var testScore = []
        for (var i = 0; i < testZ.length; i++) {
            var testttt = document.getElementsByClassName("testKongScore")[i].value
            if (testZ[i].Ccount.Ccount > 0 && testttt === "") {
                window.U.alert("有填空题的分数未填写")
                return;
            }
            if (testZ[i].Ccount.Ccount > 0 && testZ[i].Ccount.CZscore > 0 && testttt !== "" && parseInt(testttt) > testZ[i].Ccount.CZscore) {
                window.U.alert("填空得分不能大于所给满分")
                return;
            }
            if (testZ[i].Ccount.Ccount > 0) {
                testScore.push(parseInt(testZ[i].Rscore) + parseInt(testttt))
            } else {
                testScore.push(parseInt(testZ[i].Rscore))
            }

        }

        var score = {
            testScore, code, sum, remarks
        }
        axios.post(`${config.env.edu}score`, `mode=${this.props.userId},${encodeURIComponent(encodeURIComponent(JSON.stringify(score)))},${ssid},${Math.round((this.sumAS(testScore) / testScore.length))},${code},${sum}`).then(res => {
            window.U.alert("评分成功")
            this.setState({
                test: "",
                code: "",
                sum: "",
                ssid: "",
                remarks: "",
                addClassModal: false,
                testZ: [],
            })
        }).catch(err => console.log(err));
    }

    sumAS = (arr) => {
        var s = 0;
        arr.forEach(function (val, idx, arr) {
            s += val;
        }, 0);

        return s;
    };


    getDeatail = (uid, people) => {
        axios.get(`${config.env.edu}getSummaryById2/${uid}/${this.props.userId}`).then(res => {
            var course = res.data[0][0]
            var _json2 = []
            var state = JSON.parse(res.data[0][0].state)
            for (let cj = 0; cj < state.length; cj++) {
                for (let k = 0; k < state[cj].chapterData.length; k++) {
                    state[cj].chapterData[k].cd = state[cj].chapterid
                    _json2.push(state[cj].chapterData[k])
                }
            }

            var score = JSON.parse(res.data[0][0].score)
            var ut = ""
            if (score) {
                score.testScore = this.sumAS(score.testScore) / score.testScore.length
                score.sum = score.sum * 20
                score.code = score.code * 20
                ut = res.data[0][0].updatetime
            } else {
                var homework = []
                for (var x in _json2) {
                    if (_json2[x].typeName == "编程" || _json2[x].typeName == "测验") {
                        _json2[x].is = 1
                        homework.push(_json2[x])
                    }
                }
                if (res.data[1]) {
                    var r1 = res.data[1]
                    for (var i in homework) {
                        for (var c in r1) {
                            if (homework[i].typeName == "测验" && homework[i].id == r1[c].chapterid) {
                                homework[i].is = 2
                                homework[i].time = r1[c].time
                            }
                        }
                    }
                }
                if (res.data[2]) {
                    var r1 = res.data[2]
                    for (var i in homework) {
                        for (var c in r1) {
                            if (homework[i].typeName == "编程" && homework[i].id == r1[c].chapterid) {
                                homework[i].is = 2
                                homework[i].time = r1[c].time
                            }
                        }
                    }
                }
                this.setState({
                    homework: homework
                })
            }
            this.setState({
                score: score ? [score] : [],
                addClassModal: true,
                pChapters: _json2,
                ppeople: people,
                ut: ut,
                tname: { tname: res.data[0][0].tname, teachername: res.data[0][0].teachername }
            })
            console.log(_json2);
        })
    }

    /**
   * @description 配置图表
   * @returns 
   * @memberof EchartsRadar
   */
    getOption() {
        const { score } = this.state
        return {
            title: {
                text: ''
            },
            //点击提示标签
            // tooltip: {},
            radar: {
                //雷达图绘制类型，支持 'polygon' 和 'circle' [ default: 'polygon' ]
                shape: 'polygon',
                splitNumber: 4,
                center: ['50%', '60%'],
                radius: '65%',
                //指示器名称和指示器轴的距离。[ default: 15 ]
                nameGap: 5,
                triggerEvent: true,
                name: {
                    textStyle: {
                        color: '#999',
                        backgroundColor: 'transparent'
                        // borderRadius: 3,
                        // padding: [3, 5]
                    },
                    formatter: function (value, indicator) {
                        value = value.replace(/\S{5}/g, function (match) {
                            return match + '\n'
                        })
                        // value = value + '\n' + indicator.value;
                        return '{a|' + value + '}' + '\n' + '{b|' + indicator.value + "分" + '}'
                        // return `{a|${value}(${indicator.value})分}`
                    },
                    //富文本编辑 修改文字展示样式
                    rich: {
                        a: {
                            color: "#999",
                            fontSize: 12,
                            align: "center",
                            width: "200px"
                        },
                        b: {
                            color: "#333",
                            fontSize: 17,
                            align: "center"
                        }
                    }
                },
                // 设置雷达图中间射线的颜色
                axisLine: {
                    lineStyle: {
                        color: '#ddd',
                    },
                },
                indicator: [
                    { "name": "测验答题分", "value": Math.round(score[0].testScore), "max": 100 },
                    { "name": "编程能力分", "value": score[0].code, "max": 100 },
                    { "name": "项目实践分", "value": score[0].sum, "max": 100 },
                ],
                //雷达图背景的颜色，在这儿随便设置了一个颜色，完全不透明度为0，就实现了透明背景
                splitArea: {
                    show: false,
                    areaStyle: {
                        color: 'rgba(255,0,0,0)', // 图表背景的颜色
                    },
                }
            },
            series: [{
                name: '分数',
                type: 'radar',
                //显示雷达图选中背景
                areaStyle: { normal: {} },
                data: [
                    {
                        value: [Math.round(score[0].testScore), score[0].code, score[0].sum],
                        // 设置区域边框和区域的颜色
                        itemStyle: {
                            normal: {
                                //雷达图背景渐变设置
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0.5,
                                    color: 'rgba(48,107, 231, 1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(73,168, 255, 0.7)'
                                }]),
                                //去除刻度
                                opacity: 0,
                                //雷达图边线样式
                                lineStyle: {
                                    width: 0,
                                    color: '#306BE7',
                                },
                            },
                        },
                    }
                ]
            }]
        };
    }

    /**
        * @description 雷达图选中区域点击事件和外部显示标签点击事件
        * @param {any} param 
        * @param {any} echarts 
        * @memberof EchartsRadar
        */
    onChartClick(param, echarts) {
        console.log(param)
    }
    /**
     * @description 点击legend事件
     * @param {any} param 
     * @param {any} echarts 
     * @memberof EchartsRadar
     */
    onChartLegendselectchanged(param, echarts) {
        console.log(param)
    }


    render() {
        const { rows, page, addClassModal, classname, rowsPerPage, count, sid, test, code, sum, remarks, testZ, score, pChapters, homework,
            ppeople,
            ut, tname } = this.state;

        let onEvents = {
            'click': this.onChartClick.bind(this),
            'legendselectchanged': this.onChartLegendselectchanged.bind(this)
        }
        return (
            <div className="AddStudent">
                <div style={table} component={Paper}>
                    {rows.length > 0 ? <div className="hs_pub_tbT tableT" style={{ borderTop: "1px solid #ebebeb", margin: "10px 0px 0px 34px" }}>
                        <ul className="hs_dp_headT user_headT">
                            <li>
                                <div>课程标题</div>
                                <div>单元标题</div>
                                <div>提交时间</div>
                                <div>操作</div>
                            </li>
                        </ul>
                        <ul className="hs_dp_tbodyT user_tbodyT" style={{ height: "500px" }}>
                            {rows && rows.map((x, i) => (
                                <li key={i}>
                                    <div>{x.ctitle}</div>
                                    <div>{x.title}</div>
                                    <div>{x.time}</div>
                                    <div>
                                        <span className="hs_pub_updateBtnT" onClick={() => this.get(x.poster, 1)}>查看图片</span>
                                        <span className="hs_pub_updateBtnT" style={{ margin: "0 10px" }} onClick={() => this.get(x.video, 2)}>查看视频</span>
                                        <span className="hs_pub_updateBtnT" onClick={() => this.getDeatail(x.courseId, x.alias ? x.alias : x.username)}>查看评分</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div> : <div style={{ textAlign: 'center', }}>
                        <img src={require('../assets/img/manage/null.png')} />
                    </div>}
                    {rows.length > 0 && <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        rowsPerPage={10}
                        rowsPerPageOptions={[10]}
                        component="div"
                        onChangePage={this.handleChangePage}
                    />}
                </div>
                {/* <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addClassModal}
                    onClose={() => {
                        this.setState({
                            addClassModal: false
                        })
                    }}
                >
                    <Fade in={addClassModal}>
                        <div className="sumModel">
                            <div className="title">
                                <h2>项目总结</h2>
                                <span onClick={() => { this.setState({ addClassModal: false }) }}> <img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <div className="score_box">
                                <div className="score_controduce">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div className="score_pBox">
                                            <div><img style={{ width: '55px' }} src={require("../assets/img/toux.png")} /></div>
                                            <div className="c_flex" style={{ marginLeft: "10px" }}>
                                                <span className="uname">{sid.alias ? sid.alias : sid.username}</span>
                                                <h1>{sid.title}</h1>
                                            </div>
                                        </div>
                                        <div>
                                            <span style={{ color: '#78ACEE', cursor: 'pointer', fontSize: "14px", marginRight: "10px" }} onClick={() => this.get(sid.poster, 1)}>查看图片</span>
                                            <span style={{ color: '#78ACEE', cursor: 'pointer', fontSize: "14px" }} onClick={() => this.get(sid.video, 2)}>视频播放</span>
                                        </div>
                                    </div>
                                    <div className="c_flex score_detail">
                                        <div style={{ color: "#676767", fontSize: "17px" }}>简要介绍</div>
                                        <span style={{ fontSize: "17px", wordBreak: "break-all", maxHeight: "60px", overflow: "auto" }}>{sid.remarks ? sid.remarks : "暂无简介"}</span>
                                    </div>
                                </div>
                                <div className="score_controduce">
                                    <div className="c_flex score_detail">
                                        <div style={{ color: "#676767", fontSize: "17px" }}>1.测验评分</div>
                                        {score ? <div className="score_test">
                                            {testZ && testZ.map((x, i) => (
                                                <div class="score_test_box">
                                                    <div class="score_test_title">【测验】{x.minutiaName}</div>
                                                    <div class="score_test_xuan">选择得分：该测验，选择题为<span style={{ color: "#ebaf50" }}>{x.Rscore}分</span></div>
                                                    <div class="score_test_tian">填空得分：{
                                                        x.Ccount.Ccount > 0 ?
                                                            <span><input type="number" class="testKongScore" readonly={true} value={score.testScore[i] - x.Rscore} />请给填空进行打分，满分<span style={{ color: "#ebaf50" }}>{x.Ccount.CZscore}分</span></span> :
                                                            <span class="testKongScore">暂无</span>
                                                    }</div>
                                                </div>
                                            ))}
                                        </div> :
                                            <div className="score_test">
                                                {testZ && testZ.map((x, i) => (
                                                    <div class="score_test_box">
                                                        <div class="score_test_title">【测验】{x.minutiaName}</div>
                                                        <div class="score_test_xuan">选择得分：该测验，选择题为<span style={{ color: "#ebaf50" }}>{x.Rscore}分</span></div>
                                                        <div class="score_test_tian">填空得分：{
                                                            x.Ccount.Ccount > 0 ?
                                                                <span><input type="number" class="testKongScore" />请给填空进行打分，满分<span style={{ color: "#ebaf50" }}>{x.Ccount.CZscore}分</span></span> :
                                                                <span class="testKongScore">暂无</span>
                                                        }</div>
                                                    </div>
                                                ))}
                                            </div>}
                                    </div>
                                    <div className="c_flex score_detail">
                                        <div style={{ color: "#676767", fontSize: "17px" }}>2.编程得分</div>
                                        {score ? <div className="score_Star">
                                            <img src={score.code < 1 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.code < 2 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.code < 3 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.code < 4 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.code < 5 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                        </div> : <div className="score_Star">
                                            <img src={code < 1 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(1, 2) }} />
                                            <img src={code < 2 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(2, 2) }} />
                                            <img src={code < 3 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(3, 2) }} />
                                            <img src={code < 4 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(4, 2) }} />
                                            <img src={code < 5 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(5, 2) }} />
                                        </div>}
                                    </div>
                                    <div className="c_flex score_detail">
                                        <div style={{ color: "#676767", fontSize: "17px" }}>3.项目实践得分</div>
                                        {score ? <div className="score_Star">
                                            <img src={score.sum < 1 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.sum < 2 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.sum < 3 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.sum < 4 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                            <img src={score.sum < 5 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} />
                                        </div> : <div className="score_Star">
                                            <img src={sum < 1 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(1, 3) }} />
                                            <img src={sum < 2 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(2, 3) }} />
                                            <img src={sum < 3 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(3, 3) }} />
                                            <img src={sum < 4 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(4, 3) }} />
                                            <img src={sum < 5 ? require("../assets/img/manage/star.png") : require("../assets/img/manage/istar.png")} onClick={() => { this.starLight(5, 3) }} />
                                        </div>}
                                    </div>
                                    <div className="c_flex score_detail">
                                        <div style={{ fontSize: "17px", borderBottom: "3px solid #eee", display: "flex", paddingBottom: "10px" }}>更多评价</div>
                                        {score ? <textarea readonly={true} value={score.remarks} style={{ fontSize: "16px", height: "90px", marginTop: "10px", outline: "none", padding: "5px", border: "2px solid rgb(238, 238, 238)", borderRadius: "3px" }}></textarea> : <textarea value={remarks} style={{ fontSize: "16px", height: "90px", marginTop: "10px", outline: "none", padding: "5px" }} onChange={(e) => { this.setState({ remarks: e.target.value }) }}></textarea>}
                                    </div>
                                    <div className="submit">
                                        {score ? <button style={{ background: '#F23A3A' }} onClick={() => { this.setState({ addClassModal: false }) }}>已评分</button> : <button style={{ background: '#F23A3A' }} onClick={this.submit}>确定</button>}
                                        <button style={{ background: '#AAA' }} onClick={() => { this.setState({ addClassModal: false }) }}>取消</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal> */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addClassModal}
                    onClose={() => {
                        this.setState({
                            addClassModal: false
                        })
                    }}
                >
                    <Fade in={addClassModal}>
                        <div className="sumModel">
                            <div className="title">
                                <h2>综合评分查看</h2>
                                <span onClick={() => { this.setState({ addClassModal: false }) }}><img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <div className="score_box">
                                {
                                    <div className="ss_score_controduce">
                                        <div className="ss_score_detail">
                                            <div className="ss_info_box">
                                                <div className="c_flex ss_stu_info">
                                                    <div className="sstitle">
                                                        <img style={{ width: '80px' }} src={require("../assets/img/toux.png")} />
                                                        <span>{ppeople}</span>
                                                    </div>
                                                    <div className="ssScore">
                                                        <span>{score[0] ? Math.round((score[0].testScore + score[0].sum + score[0].code) / 3) : "--"}</span>
                                                        <div>目前平均分</div>
                                                    </div>
                                                </div>
                                                <div className="ss_radar">
                                                    {score[0] ? <ReactEcharts
                                                        option={this.getOption()}
                                                        notMerge={true}
                                                        lazyUpdate={true}
                                                        onEvents={onEvents}
                                                        style={{ width: '100%', height: '100%' }}
                                                    /> : <div style={{ color: "#7c7c7c" }}>目前老师暂未评分哦</div>}
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ margin: "5px 0 0", color: "#a1a1a1" }}>学习进度</div>
                                                <div className="ss_state_box" style={{ height: "85px" }}>
                                                    {
                                                        pChapters && pChapters.map((k, index) => (
                                                            <div className="ss_state_poi" key={index}>
                                                                <div className="ss_line_box">
                                                                    {pChapters.length == index + 1 ? '' : <div className="ss_line"></div>}
                                                                    {k.isread ? <img src={require('../assets/img/manage/learned.png')} className="ss_line_dian" /> : <img src={require('../assets/img/manage/learn.png')} className="ss_line_dian" />}
                                                                </div>
                                                                <div className="ss_font" style={{ color: "#202020" }}>
                                                                    <span title={k.minutiaName}>{k.minutiaName}</span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {!score[0] && homework && <div className="c_flex ss_score_teacher">
                                            <div style={{ color: "#a1a1a1", fontSize: "16px" }}>作业提交情况</div>
                                            <div className="ss_score_homework_box">
                                                {homework && homework.map((v, i) => (
                                                    <div className={"ss_score_homework_panel " + (v.is == 1 ? "" : "sssh_is_box")}>
                                                        <span title={v.minutiaName}>{`【${v.typeName}】${v.minutiaName}`}</span>
                                                        <span className="ss_score_homework_is">{v.is == 1 ? "" : (<div><img src={require("../assets/img/manage/homes.png")} /><span>已提交</span></div>)}</span>
                                                        <span className={"ss_score_homework_none " + (v.is == 1 ? "" : "sssh_is")}>{v.is == 1 ? "未提交" : v.time}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>}
                                        {score[0] && <div className="c_flex ss_score_teacher">
                                            <div style={{ fontSize: "17px" }}>评价</div>
                                            <div style={{ marginTop: "10px" }}>
                                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "5px" }}>
                                                    <img src={require("../assets/img/manage/ttou.png")} style={{ width: "30px" }} />
                                                    <span style={{ marginLeft: "10px", fontSize: "16px" }}>{tname.tname ? tname.tname + "老师" : tname.teachername}</span>
                                                    <span style={{ marginLeft: "10px", fontSize: "13px", color: "#999" }}>{ut}</span>
                                                </div>
                                                <span style={{ marginLeft: "40px", maxHeight: "100px", overflow: "auto", fontSize: "14px" }}>{score[0] && (score[0].remarks ? score[0].remarks : "暂无评价")}</span>
                                            </div>
                                        </div>}
                                    </div>
                                }
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}


export default studentSum
