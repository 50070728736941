import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import Subtitle from './Subtitle'
import CoverUploadPreview from './CoverUploadPreview'
import DemoUploadPreview from './DemoUploadPreview'

import IntroInput from './IntroInput'
import TitleInput from './TitleInput'
import Chapteroverlook from './Chapteroverlook'
import Chapteredit from './Chapteredit'
import Uploader from './Uploader'
import { Button } from '@material-ui/core'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { updateChapters } from '../actions/newupload'
import config from '../config'
// Actions
import {
  setStep,
  updateCourseInfo,
  updateCourseCover,
  updateCourseDemo,
  initChapters,
  updateCoursenumber
} from '../actions/newupload'
import { scheduleCounts, successCounts } from '../redux/Actions';

const mapStateToProps = (state) => {
  return {
    activeStep: state.steppers.activeStep,
    title: state.newupload.course.title,
    brief: state.newupload.course.brief,
    cover: state.newupload.course.cover,
    demo: state.newupload.course.demo,
    chapters: state.newupload.chapter.chapters,
    count: state.newupload.course.count,
    number: state.newupload.course.number,
    scheduleCount: state.modals.scheduleCount,
    successCount: state.modals.successCount
  }
}

const title = `font-size:16px;font-weight:bold;text-align:left;margin:10px 0`
const titles = `font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128)`

const mapDispatchToProps = (dispatch) => {
  return {
    handleSet(activeStep) {
      dispatch(setStep(activeStep))
    },
    updateCourseInfo(title, brief) {
      dispatch(updateCourseInfo(title, brief))
    },
    updateCourseCover(cover) {
      dispatch(updateCourseCover(cover))
    },
    updateCourseDemo(demo) {
      dispatch(updateCourseDemo(demo))
    },
    initChapters(initchapters, prechaptertitles, prekeys, length) {
      dispatch(initChapters(initchapters, prechaptertitles, prekeys, length));
    },
    updateCoursenumber(number) {
      dispatch(updateCoursenumber(number))
    },
    scheduleCounts(state) {
      dispatch(scheduleCounts(state));
    },
    successCounts(state) {
      dispatch(successCounts(state));
    },
    updateChapters(chapters) {
      dispatch(updateChapters(chapters))
    },
  }
}

const Wrapper = styled.div`
  width: 80%;
  justify-self: center;
  margin-top:50px;
  display: grid;
  grid-template-columns: 10% 37.5% 5% 37.5% 10%;
  grid-template-rows: 50px 300px auto 20px auto 40px auto 40px 60px;
  grid-template-areas:
  '. title title . .'
  '. content content content .'
  '. coverUpload . demoUpload .'
  '. . . . .'
  '. overlook overlook overlook .'
  '. divider divider divider .'
  '. chapteredit chapteredit chapteredit .'
  '. divider2 divider2 divider2 .'
  // '. uploader uploader uploader .'
  '. last . next .';
`;
const Divider = styled.hr`
  align-self: center;
  grid-area:divider2;
  height: 1px;
  border: none;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0;
`;
const Divider2 = styled.hr`
  align-self: center;
  grid-area:divider;
  height: 1px;
  border: none;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0;
`;
// cookie
const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
class Coursecheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
      brief: props.brief,
      cover: props.cover,
      demo: props.demo,
      isNull: false,
      userId: "",
      titleShow: false,
      briefShow: false,
    }
    props.handleSet(2);
  }
  componentDidMount() {
    var _this = this;
    let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
    if (arr) {
      axios.get(`${config.env.server}getcookieuserid`).then(res => {
        if (res.data[0][0]) {
          _this.setState({
            userId: res.data[0][0].userid
          });
        }
      }).catch(err => {
        _this.props.history.push('/login');
        // window.alert(err.response.data)
      })
    }
    let data = JSON.parse(window.localStorage.obj)
    if (!data.title || !data.brief) {
      this.props.history.push('/upload')
    }
    this.preread()
  }
  onLast = () => {
    let { title, brief, cover, demo } = this.state;
    this.props.updateCourseInfo(title, brief)
    this.props.updateCourseCover(cover)
    this.props.updateCourseDemo(demo)
    window.localStorage['arr'] = JSON.stringify(this.props.chapters.length !== 0 ? this.props.chapters : JSON.parse(window.localStorage.arr))
    this.props.history.push('/upload/chapter/edit')
    window.localStorage['arr'] = JSON.stringify(this.props.chapters.length !== 0 ? this.props.chapters : JSON.parse(window.localStorage.arr))
  }
  guid = () => {
    var _num, i, _guid = "";
    for (i = 0; i < 32; i++) {
      _guid += Math.floor(Math.random() * 16).toString(16); //随机0  - 16 的数字 转变为16进制的字符串
      _num = Math.floor((i - 7) / 4);                        //计算 (i-7)除4
      if (_num > -1 && _num < 4 && (i == (7 + 4 * _num))) {    //会使guid中间加 "-"   形式为xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
        _guid += "-";
      }
    }
    return _guid;
  }

  onSubmit = () => {
    if (this.props.count !== 0) {
      alert(this.props.language.wait_upload_complete);
      return;
    }
    this.posteverything()
  }
  posteverything = () => {
    let { title, brief, cover, demo, language, chapters, number, updateCourseInfo, updateCourseCover, updateCourseDemo } = this.props;
    let { isNull, userId } = this.state;
    let data = JSON.parse(window.localStorage.obj)
    let dataarr = JSON.parse(window.localStorage.arr)
    let dataNumber = JSON.parse(window.localStorage.number)
    let Chapters = [];
    if (title === '' || brief === '' || cover.length === 0) {// || demo.length === 0
      isNull = true;
      window.alert(language.course_information_no_perfect);
      return
    }
    chapters = chapters.length === 0 ? dataarr : chapters
    for (let i = 0; i < chapters.length; i++) {
      if (chapters[i].teacher.bg.length === 0) {
        this.setState({
          isNull: true
        })
        window.alert(`第${i + 1}章 ${language.teacher_chapter_back_no_perfect}`);
        return
      } else if (chapters[i].teacher.intro === '') {
        this.setState({
          isNull: true
        })
        window.alert(`第${i + 1}章 ${language.teacher_chapter_describe_no_perfect}`);
        return
      } else if (chapters[i].teacher.slideUrl.length === 0) {
        this.setState({
          isNull: true
        })
        window.alert(`第${i + 1}章 ${language.teacher_chapter_PDF_no_perfect}`);
        return
      } else if (chapters[i].student.bg.length === 0) {
        this.setState({
          isNull: true
        })
        window.alert(`第${i + 1}章 ${language.student_chapter_back_no_perfect}`);
        return
      } else if (chapters[i].student.intro === '') {
        this.setState({
          isNull: true
        })
        window.alert(`第${i + 1}章 ${language.student_chapter_describe_no_perfect}`);
        return
      } else if (chapters[i].student.slideUrl.length === 0) {
        this.setState({
          isNull: true
        })
        window.alert(`第${i + 1}章 ${language.student_chapter_PDF_no_perfect}`);
        return
      } else {
        setTimeout(() => {
          this.setState({
            isNull: false
          })
        }, 0);
      }
    }
    // if (isNull === false) {
    for (let i = 0; i < chapters.length; i++) {
      let teacherclipUrl = []
      let teacherslideUrl = []
      let teacherattachmentUrl = []
      let studentclipUrl = []
      let studentslideUrl = []
      let studentattachmentUrl = []
      for (let u = 0; u < chapters[i].teacher.clipUrl.length; u++) {
        if (chapters[i].teacher.clipUrl[u].url) {
          teacherclipUrl.push(chapters[i].teacher.clipUrl[u])
        }
      }

      for (let u = 0; u < chapters[i].teacher.slideUrl.length; u++) {
        if (chapters[i].teacher.slideUrl[u].url) {
          teacherslideUrl.push(chapters[i].teacher.slideUrl[u].url)
        }
      }
      for (let u = 0; u < chapters[i].teacher.attachmentUrl.length; u++) {
        teacherattachmentUrl.push({
          title: chapters[i].teacher.attachmentUrl[u].title,
          url: chapters[i].teacher.attachmentUrl[u].url
        })
      }

      for (let u = 0; u < chapters[i].student.clipUrl.length; u++) {
        if (chapters[i].student.clipUrl[u].url) {
          studentclipUrl.push(chapters[i].student.clipUrl[u])
        }
      }

      for (let u = 0; u < chapters[i].student.slideUrl.length; u++) {
        if (chapters[i].student.slideUrl[u].url) {
          studentslideUrl.push(chapters[i].student.slideUrl[u].url)
        }
      }

      for (let u = 0; u < chapters[i].student.attachmentUrl.length; u++) {
        studentattachmentUrl.push({
          title: chapters[i].student.attachmentUrl[u].title,
          url: chapters[i].student.attachmentUrl[u].url
        })
      }

      Chapters.push({
        chapterid: this.guid(),
        title: chapters[i].title,
        teacher: {
          bg: chapters[i].teacher.bg[0].url,
          intro: chapters[i].teacher.intro,
          clipUrl: teacherclipUrl,
          slideUrl: teacherslideUrl,
          attachmentUrl: teacherattachmentUrl
        },
        student: {
          bg: chapters[i].student.bg[0].url,
          intro: chapters[i].student.intro,
          clipUrl: studentclipUrl,
          slideUrl: studentslideUrl,
          attachmentUrl: studentattachmentUrl
        }
      })
    }
    // }
    let a = encodeURIComponent(encodeURIComponent(number || dataNumber));
    let encodetitle = encodeURIComponent(encodeURIComponent(title || data.title));
    let encodebrief = encodeURIComponent(encodeURIComponent(brief || data.brief));
    let encodecover = encodeURIComponent(encodeURIComponent(cover[0].url || data.cover[0].url));
    let encodedemo = demo.length > 0 ? encodeURIComponent(encodeURIComponent(demo[0].url || data.demo[0].url)) : '';
    let encodechapters = encodeURIComponent(encodeURIComponent(JSON.stringify(Chapters)));
    if (number || dataNumber) {
      axios.post(`${config.env.edu}updateCourses`,
        `mode=${userId},${a},${encodetitle},${encodebrief},${encodedemo},${encodecover},${encodechapters},1`
      ).then((res => {
        if (res.status === 200) {
          // 修改成功清空数据
          updateCourseInfo(null, null);
          updateCourseCover([]);
          updateCourseDemo([]);
          updateCoursenumber(undefined)
          this.props.initChapters([], [{ title: '' }], [0]);
          localStorage.removeItem('number')
          localStorage.removeItem('obj')
          localStorage.removeItem('arr')
          localStorage.removeItem('keys')
          localStorage.removeItem('prechaptertitles')
          window.location.href = '/upload/result/' + res.data[0][0].number
        }
      })).catch((err) => {
        window.alert(this.props.language.change_failure + ',error:' + err)
      })
    } else {
      var uploadid = window.localStorage["uploadid"] || window.Guid.newGuid();
      axios.post(`${config.env.edu}createCourses`,
        `mode=${userId},${encodetitle},${encodebrief},${encodedemo},${encodecover},${encodechapters},1,${uploadid}`
      ).then((res => {
        if (res.status === 200) {
          // 上传成功清空数据
          updateCourseInfo(null, null);
          updateCourseCover([]);
          updateCourseDemo([]);
          initChapters([], [{ title: '' }], [0]);
          localStorage.removeItem('number')
          localStorage.removeItem('obj')
          localStorage.removeItem('arr')
          localStorage.removeItem('keys')
          localStorage.removeItem('prechaptertitles')
          window.location.href = '/upload/result/' + res.data[0][0].number
        }
      })).catch((err) => {
        window.alert(this.props.language.upload_fail + ',error:' + err)
      })
    }
  }

  preread = () => {
    let data = JSON.parse(window.localStorage.obj)
    this.coursebrief.preread(data.brief);
    this.coursetitle.preread(data.title);
    this.props.updateCourseCover(data.cover)
    this.props.updateCourseDemo(data.demo)
  }
  getCoverInfo = (val) => {
    this.setState({
      cover: val
    })
    this.props.updateCourseCover(val)
  }
  getDemoInfo = (val) => {
    this.setState({
      demo: val
    })
    this.props.updateCourseDemo(val)
  }
  getTitleVal = (val) => {
    // if (val.length > 20) {
    //   this.setState({
    //     titleShow: true
    //   })
    // } else {
    this.setState({
      title: val,
      titleShow: false
    })
    this.props.updateCourseInfo(val, this.state.brief)
    // }
  }
  getIntroVal = (val) => {
    if (val.length > 1000) {
      this.setState({
        briefShow: true
      })
    } else {
      this.setState({
        brief: val,
        briefShow: false
      })
      this.props.updateCourseInfo(this.state.title, val)
    }
  }

  render() {
    const { briefShow, titleShow } = this.state
    const { language, count } = this.props
    return (
      <Wrapper>
        <Subtitle
          areaname='title'
          name={language.check_course_content}
        />
        <div css={`grid-area:content;`}>
          <div css={`overflow:hidden;`}>
            <div css={title}><span css={`color:red`}>*</span>{language.course_name}: </div>
            <TitleInput
              ref={(coursetitle) => { this.coursetitle = coursetitle; }}
              text={language.course_name}
              title={language.course_name}
              getTitleVal={v => this.getTitleVal(v)}
            />
          </div>
          <div>
            <div css={title}><span css={`color:red`}>*</span>{language.course_describe} : {briefShow ? <span style={{ fontSize: 14, float: 'right', color: 'red' }}>{language.course_describe_prompt}
            </span> : <span css={titles}>{language.course_describe_prompt}</span>}</div>
            <IntroInput
              ref={(coursebrief) => { this.coursebrief = coursebrief; }}
              intro={language.course_describe_prompt}
              rows="4"
              introId='course__intro'
              getIntroVal={v => this.getIntroVal(v)}
            />
          </div>
        </div>
        <div css={`grid-area:coverUpload`}>
          <div css={`height:33px`}>
            <div css={`font-size:16px;font-weight:bold;float:left`}><span css={`color:red`}>*</span>{language.course_cover}:</div>
            <div css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128)`}>
              <p>PNG, GIF, JPEG</p>
            </div>
          </div>
          <CoverUploadPreview language={language}
          />
        </div>
        <div css={`grid-area:demoUpload`}>
          <div css={`height:33px`}>
            {/* <span css={`color:red`}>*</span> */}
            <div css={`font-size:16px;font-weight:bold;float:left`}>{language.course_preview_video}:</div>
            <div css={`font-size:12px;font-weight:bold;float:right;color:rgba(128,128,128)`}>
              <p>MP4, M4V, MOV, AVI</p>
            </div>
          </div>
          <DemoUploadPreview language={language}
          />
        </div>
        <Chapteroverlook handleScroll={this.props.handleScroll} language={language} history={this.props.history}></Chapteroverlook>
        <Divider></Divider>
        <Chapteredit language={language} history={this.props.history} ></Chapteredit>
        <Divider2></Divider2>
        {/*<Uploader></Uploader>*/}
        <div
          css={`grid-area:last;text-align:center;`}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={this.onLast}
          >
            {language.return_change}
          </Button>
        </div>
        <div
          css={`grid-area:next;text-align:center;`}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={this.onSubmit}
          >
            {count === 0 ? ((this.props.number || JSON.parse(window.localStorage.number)) ? `${language.confirm_change}` : `${language.confirm_upload}`) : language.uploading}
          </Button>
        </div>
      </Wrapper>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Coursecheck);