import React from 'react'
import { connect } from 'react-redux'
import '../css/admin.css';
import axios from 'axios';
import config from '../config'
import Modal from '@material-ui/core/Modal';
import Type from '../components/type';
import Examine from '../components/examine';

// cookie
const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
class admin extends React.Component {

  state = {
    userId: '',
    loading: true
  }

  componentDidMount() {
    /*直接进admin*/
    /* 
    document.title = "CocoRobo 后台管理中心"
    window.ServerIp = config.env.edu;
    let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
    let _this = this
    axios.defaults.withCredentials = true;
    axios.get(`${config.env.server}getcookieuserid`).then(res => {
      _this.setState({
        userId: '4be5e344-ce1a-11e9-a839-028edca3b798'
      });
      window.language = _this.props.language
      const script = document.createElement('script');
      script.type = "text/javascript";
      script.src = "/profileF.js";
      script.async = "async";
      script.onload = function () {
        window.eduonload();
        _this.setState({
          loading: false
        })
      }
      document.body.appendChild(script);
    });
  
      }).catch(err => {
        this.props.history.push('/login');
        //window.alert(err);
      })*/
    document.title = "CocoRobo 后台管理中心"
    window.ServerIp = config.env.edu;
    let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
    if (!!!arr) {
      this.props.history.push('/login')
    }
    else {
      let _this = this
      axios.defaults.withCredentials = true;
      axios.get(`${config.env.server}getcookieuserid`).then(res => {
        if (res.data[0][0]) {
          // if (res.data[0][0].identity === 1 && res.data[0][0].identity === 0) {
          //   this.props.history.push('/login')
          //   return;
          // }
          _this.setState({
            userId: res.data[0][0].userid
          });
          window.language = _this.props.language
          const script = document.createElement('script');
          script.type = "text/javascript";
          script.src = "/profileF.js";
          script.async = "async";
          script.onload = function () {
            window.eduonload();
            _this.setState({
              loading: false
            })
          }
          document.body.appendChild(script);
        }

      }).catch(err => {
        this.props.history.push('/login');
        //window.alert(err);
      })
    }
    //  window.U.UF.DL.asynLoadJs({src:"/publicjs/org.js","charset": "utf-8", "type": "text/javascript"},function(){});
    //  window.U.UF.DL.asynLoadJs({src:"/publicjs/org.js","charset": "utf-8", "type": "text/javascript"},function(){});
  }
  render() {
    const { language } = this.props
    return (
      <div className="admin_page">
        <div className="admin_nav">
          <div className='divhover'><img src="/img/shouye-2.png" alt="" />{language.homepage_managment}</div>
          <div><img src="/img/class1-1.png" alt="" />{language.course_managment}</div>
          <div><img src="/img/home1-1.png" alt="" />{language.room_managment}</div>
          <div><img src="/img/profile1-1.png" alt="" />{language.user_managment}</div>
          <div><img src="/img/notice1-1.png" alt="" />{language.notice_managment}</div>
          <div><img src="/img/creat-1.png" alt="" />{language.add_school}</div>
          <div><img src="/img/creat-1.png" alt="" />权限管理</div>
          <div><img src="/img/creat-1.png" alt="" />分类管理</div>
          <div><img src="/img/creat-1.png" alt="" />审核管理</div>
        </div>
        <div class="admin_bjshow">
          <div className="admin_table">
            <div class="admin_table_block">
              <div class="pb_head">{language.homepage_managment}</div>
              <div class="banner_body">
                <div class="banner_control">
                  <div class="banner_controlTop">
                    <div class="banner_controlTop_l">
                      <h1>Banner管理</h1>
                      <div class="banner_add">
                        <img src="/img/Addcopy.png" />
                        <span>添加banner</span>
                      </div>
                    </div>
                    <div class="hc_pub_tb table">
                      <ul class="hc_dp_head">
                        <li>
                          <div>{language.img}</div>
                          <div>{language.link}</div>
                          <div>{language.operation}</div>
                        </li>
                      </ul>
                      <ul class="hc_dp_tbody banner_table">
                        {/* <li>
                      <div><img src="/img/banner1.png" /></div>
                      <div><span>https://fanyi.baidu.com/translate?aldtype=16047&</span></div>
                      <div>
                        刪除
                </div>
                    </li>
                    <li>
                      <div><img src="/img/banner2.png" /></div>
                      <div><span>https://fanyi.baidu.com/translate?aldtype</span></div>
                      <div>
                        刪除
                </div>
                    </li> */}
                      </ul>
                    </div>
                    {/* <div class="banner_addSpecial">
                  <img src="/img/add1.png"/>
                  <span onclick="InsertCourse()">新增首頁課程專區</span>
                </div> */}
                  </div>
                  {/* kctj */}
                  {/* <div class="banner_special">
                <div class="banner_specialTop">
                  <div class="banner_specialTopTitle">
                    <img src="/img/Topping.png" />
                    <h1>基礎套件專區</h1>
                  </div>
                  <div class="banner_specialTopRight">
                    <div class="banner_specialAddclass">
                      <img src="/img/Addcopy.png" />
                      <span>添加課程</span>
                    </div>
                    <div class="banner_specialDelclass">
                      <img src="/img/layer.png" />
                      <span>删除专区</span>
                    </div>
                  </div>
                </div>
                <div class="hc_pub_tb table" style={{width:'872px'}}>
                  <ul class="hc_dd_head">
                    <li>
                      <div>課程名稱</div>
                      <div>鏈接</div>
                      <div>操作</div>
                    </li>
                  </ul>
                  <ul class="hc_dd_tbody"> */}
                  {/* <li>
                      <div>机械臂（镭射切割）</div>
                      <div><span>https://fanyi.baidu.com/translate?aldtype</span></div>
                      <div>編輯</div>
                      <div>刪除</div>
                    </li>
                    <li>
                      <div>机械臂（镭射切割）</div>
                      <div><span>https://fanyi.baidu.com/translate?al</span></div>
                      <div>編輯</div>
                      <div>刪除</div>
                    </li>
                    <li>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </li> */}
                  {/* </ul>
                  <div class="banner_special_selectmore">
                    <span>查看更多</span>
                    <img src="/img/zhankai.png" />
                  </div>
                </div>
              </div> */}

                  {/* <div class="banner_course">
                <div class="banner_courseTop">
                  <div class="banner_courseTopTitle">
                    <img src="/img/Topping.png" id="#em"/>
                    <h1>課程套件</h1>
                  </div>
                  <div class="banner_courseTopRight">
                    <div class="banner_courseAddclass">
                      <img src="/img/Addcopy.png" />
                      <span>添加課程</span>
                    </div>
                    <div class="banner_courseDelclass">
                      <img src="/img/layer.png" />
                      <span>删除专区</span>
                    </div>
                  </div>
                </div>
                <div class="hc_pub_tb table" style={{width:'872px'}}>
                  <ul class="hc_dd_head">
                    <li>
                      <div>課程名稱</div>
                      <div>鏈接</div>
                      <div>操作</div>
                    </li>
                  </ul>
                  <ul class="hc_ddbb_tbody"> */}
                  {/* {/* <li>
                      <div>机械臂（镭射切割）</div>
                      <div><span>https://fanyi.baidu.com/translate?aldtype</span></div>
                      <div>編輯</div>
                      <div>刪除</div>
                    </li>
                    <li>
                      <div>机械臂（镭射切割）</div>
                      <div><span>https://fanyi.baidu.com/translate?al</span></div>
                      <div>編輯</div>
                      <div>刪除</div>
                    </li>
                    <li> 
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </li> */}
                  {/* </ul>
                  <div class="banner_special_selectmore">
                    <span>查看更多</span>
                    <img src="/img/zhankai.png" />
                  </div>
                </div>
              </div>
              <div class="banner_course">
                <div class="banner_courseTop">
                  <div class="banner_courseTopTitle">
                    <img src="/img/Topping.png" />
                    <h1>AIoT課程套件</h1>
                  </div>
                  <div class="banner_courseTopRight">
                    <div class="banner_courseAddclass">
                      <img src="/img/Addcopy.png" />
                      <span>添加課程</span>
                    </div>
                    <div class="banner_courseDelclass">
                      <img src="/img/layer.png" />
                      <span>删除专区</span>
                    </div>
                  </div>
                </div>
                <div class="hc_pub_tb table" style={{width:'872px'}}>
                  <ul class="hc_dd_head">
                    <li>
                      <div>課程名稱</div>
                      <div>鏈接</div>
                      <div>操作</div>
                    </li>
                  </ul>
                  <ul class="hc_ddcc_tbody"> */}
                  {/* {/* <li>
                      <div>机械臂（镭射切割）</div>
                      <div><span>https://fanyi.baidu.com/translate?aldtype</span></div>
                      <div>編輯</div>
                      <div>刪除</div>
                    </li>
                    <li>
                      <div>机械臂（镭射切割）</div>
                      <div><span>https://fanyi.baidu.com/translate?al</span></div>
                      <div>編輯</div>
                      <div>刪除</div>
                    </li>
                    <li> 
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </li> */}
                  {/* </ul>
                  <div class="banner_special_selectmore">
                    <span>查看更多</span>
                    <img src="/img/zhankai.png" />
                  </div>
                </div>
              </div>*/}
                </div>
                <div class='banner_addSpecial'>
                  <img src="/img/add1.png" />
                  <span class="newapplication">{language.add_home_course_area}</span>
                </div>
                <div style={{color:'red'}}>注意：若删除一级分类，务必清除分类里的课程，否则影响分类检索</div>
              </div>
            </div>
            {/**課程管理 */}
            <div class="admin_div">
              <div class="pb_head">{language.course_managment}</div>
              <div class='admin_course_input admin_class'>
                <button class="template_btn" style={{ float: "unset" }}>创建模板课程</button>
                <div class='admin_course_search admin_class'>{language.search}</div>
                <input placeholder={`${language.course_name},${language.course_numbers}`} style={{ float: 'right', width: '200px', height: '30px', margin: '8px 12px 0 0', fontSize: '18px', textIndent: '10px' }} />
              </div>
              <div class="hs_pub_tb table">
                <ul class="hs_dp_head class_head">
                  <li>
                    <div class="admin_width">{language.courseid}</div>
                    <div class="admin_Mwidth">{language.course_name}</div>
                    <div class="admin_Mwidth">{language.create}</div>
                    <div>{language.create_time}</div>
                    <div>{language.last_change_time}</div>
                    <div>{language.operation}</div>
                  </li>
                </ul>
                <ul class="hs_dp_tbody class_tbody">

                </ul>
              </div>
            </div>
            {/* 房間管理 */}
            <div class="admin_div">
              <div class="pb_head">{language.room_managment}</div>
              <div class='admin_course_input admin_room'>
                <div class='admin_course_search admin_room'>{language.search}</div>
                <input placeholder={language.room_name} class="admin_room_select_input" style={{ float: 'right', width: '200px', height: '30px', margin: '8px 12px 0 0', fontSize: '18px', textIndent: '10px' }} />
              </div>
              <div class="hs_pub_tb table">
                <ul class="hs_dp_head room_head">
                  <li>
                    <div class="admin_Mwidth">{language.room_name}</div>
                    <div>{language.create}</div>
                    <div>{language.create_time}</div>
                    <div>{language.last_change_time}</div>
                    <div>{language.operation}</div>

                  </li>
                </ul>
                <ul class="hs_dp_tbody room_tbody">
                  {/* <li>
                    <div class="admin_width">123</div>
                    <div class="admin_Mwidth">機械臂(鐳射切割)</div>
                    <div>張家號</div>
                    <div>2019-09-17</div>
                    <div>2019-09-17</div>
                    <div>
                      <span class="hs_pub_updateBtn">編輯</span>
                      <span class="hs_pub_deleteBtn">刪除</span>
                    </div>
                  </li>
                  <li>
                    <div class="admin_width">123</div>
                    <div class="admin_Mwidth">機械臂(鐳射切割)</div>
                    <div>張家號</div>
                    <div>2019-09-17</div>
                    <div>2019-09-17</div>
                    <div>
                      <span class="hs_pub_updateBtn">編輯</span>
                      <span class="hs_pub_deleteBtn">刪除</span>
                    </div>
                  </li>
                  <li>
                    <div class="admin_width">123</div>
                    <div class="admin_Mwidth">機械臂(鐳射切割)</div>
                    <div>張家號</div>
                    <div>2019-09-17</div>
                    <div>2019-09-17</div>
                    <div>
                      <span class="hs_pub_updateBtn">編輯</span>
                      <span class="hs_pub_deleteBtn">刪除</span>
                    </div>
                  </li> */}
                </ul>
              </div>
              <div class="room_page"></div>
            </div>
            {/* 用戶管理 */}
            <div class="admin_div">
              <div class="pb_head">{language.user_managment}</div>
              <div class='admin_course_input admin_user'>
                <div class='admin_course_search admin_user_btn'>{language.search}</div>
                <input placeholder={language.user_email} class="user_input" style={{
                  float: 'right', width: '200px', height: '30px', margin: '8px 12px 0 0', fontSize: '18px', textIndent: '10px'
                }} />
              </div>
              <div class="hs_pub_tb table">
                <ul class="hs_dp_head user_head">
                  <li>
                    {/* <div class="">ID</div> */}
                    <div class="admin_Mwidth">{language.name}</div>
                    <div>{language.user_email}</div>
                    <div>{language.user_role}</div>
                    <div>{language.operation}</div>
                  </li>
                </ul>
                <ul class="hs_dp_tbody user_tbody">
                  {/* <li>
                    <div class="admin_width">123</div>
                    <div class="admin_Mwidth">張家號</div>
                    <div>161583186@qq.com</div>
                    <div>學生</div>
                    <div>
                      <span class="hs_pub_updateBtn">編輯</span>
                      <span class="hs_pub_deleteBtn">刪除</span>
                    </div>
                  </li> */}
                </ul>
              </div>
              <div class="user_page"></div>
            </div>
            {/* 通知管理 */}
            <div class="admin_div">
              <div class="pb_head">{language.notice_managment}</div>
              <div class="pb_tianjia notice_creatNotice"><span class="icon"></span><span class="text">{language.add_total_notice}</span></div>
              <div class="hs_pub_tb table">
                <ul class="hs_dp_head notice_head">
                  <li>
                    {/* <div class="">ID</div> */}
                    <div class="admin_Mwidth">{language.notice_content}</div>
                    <div class="admin_width">{language.create}</div>
                    <div>{language.create_time}</div>
                    <div>{language.operation}</div>
                  </li>
                </ul>
                <ul class="hs_dp_tbody notice_tbody">
                  {/* <li>
                    <div class="admin_width">123</div>
                    <div class="admin_Mwidth">調整調整調整調整調整調整調整調整調整調整調整調整調整調整</div>
                    <div>張家號</div>
                    <div>2019-09-17</div>
                    <div>
                      <span class="hs_pub_deleteBtn">刪除</span>
                    </div>
                  </li>
                  <li>
                    <div class="admin_width">123</div>
                    <div class="admin_Mwidth">調整調整調整調整調整調整調整調整調整調整調整調整調整調整</div>
                    <div>張家號</div>
                    <div>2019-09-17</div>
                    <div>
                      <span class="hs_pub_deleteBtn">刪除</span>
                    </div>
                  </li>
                  <li>
                    <div class="admin_width">123</div>
                    <div class="admin_Mwidth">調整調整調整調整調整調整調整調整調整調整調整調整調整調整</div>
                    <div>張家號</div>
                    <div>2019-09-17</div>
                    <div>
                      <span class="hs_pub_deleteBtn">刪除</span>
                    </div>
                  </li> */}
                </ul>
              </div>
              <div class="news_page"></div>
            </div>
            {/* 創建分組 */}
            <div class="admin_div">
              <div class="pb_head">{language.add_school}</div>
              <div class="pb_tianjia org_creatSchool"><span class="icon"></span><span class="text">{language.add_school}</span></div>
              <div class="hs_pub_tb table org_table">
                <ul class="hs_dp_head org_head">
                  <li>
                    <div class="admin_Mwidth">{language.school}</div>
                    <div class="admin_width">{language.operation}</div>
                  </li>
                </ul>
                <ul class="hs_dp_tbody org_tbody">
                  {/* <li>
                  <div class="admin_Mwidth">深圳技師學院</div>
                  <div class="admin_width">
                    <span class="hs_pub_deleteBtn">刪除</span>
                  </div>
                </li>
                <li>
                  <div class="admin_Mwidth">班尼路小學</div>
                  <div class="admin_width">
                  <span class="hs_pub_deleteBtn">刪除</span>
                  </div>
                </li>
                <li>
                  <div class="admin_Mwidth">啊啊啊啊啊</div>
                  <div class="admin_width">
                  <span class="hs_pub_deleteBtn">刪除</span>
                  </div>
                </li> */}
                </ul>
              </div>
              <div class="org_page"></div>
            </div>
            {/* 权限管理 */}
            <div class="admin_div">
              <div class="pb_head">权限管理</div>

              <div class="jur_select"><input class="jur_selecrinput" /><p class="jur_search">搜索</p></div>
              <div class="hs_pub_tb table ">
                <ul class="hs_dp_head jur_tbody ">
                  <li>
                    <div class="admin_Mwidth">管理员账号</div>
                    <div class="admin_width">管理员类型</div>
                    <div class="admin_width">创建时间</div>
                    <div class="admin_width">课件页面</div>
                    <div class="admin_width">操作</div>
                  </li>
                </ul>
                <ul class="hs_dp_tbody jur_tbody">

                </ul>
              </div>
              <div class="hs_pub_tb table">
                <ul class="hs_dp_head jur_tbody1">
                  <li>
                    <div class="">板块</div>
                    <div class="">页面</div>
                  </li>
                </ul>
                <ul class="hs_dp_tbody jur_tbody1">
                  <li>
                    <div class="">首页管理</div>
                    <div class=""><input class="jur_Module" type="checkbox" value="0" />首页管理</div>
                  </li>
                </ul>
                <ul class="hs_dp_tbody jur_tbody1">
                  <li>
                    <div class="">课程管理</div>
                    <div class=""><input class="jur_Module" type="checkbox" value="0" />课程管理</div>
                  </li>
                </ul>
                <ul class="hs_dp_tbody jur_tbody1">
                  <li>
                    <div class="">房间管理</div>
                    <div class=""><input class="jur_Module" type="checkbox" value="0" />房间管理</div>
                  </li>
                </ul>
                <ul class="hs_dp_tbody jur_tbody1">
                  <li>
                    <div class="">用户管理</div>
                    <div class=""><input class="jur_Module" type="checkbox" value="0" />用户管理</div>
                  </li>
                </ul>
                <ul class="hs_dp_tbody jur_tbody1">
                  <li>
                    <div class="">通知管理</div>
                    <div class=""><input class="jur_Module" type="checkbox" value="0" />通知管理</div>
                  </li>
                </ul>
                <ul class="hs_dp_tbody jur_tbody1">
                  <li>
                    <div class="">添加组织</div>
                    <div class=""><input class="jur_Module" type="checkbox" value="0" />添加组织</div>
                  </li>
                </ul>
                <div class="jur_buttom">返回</div>
                <div class="jur_buttom">確定</div>
              </div>
              <div class="jur_page"></div>
            </div>
            {/* 分类管理 */}
            <div class="admin_div">
                <Type></Type>
            </div>
            {/* 审核管理 */}
            <div class="admin_div">
              <Examine></Examine>
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.loading}
          onClose={() => {
            this.setState({
              loading: true
            })
          }}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
            <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{language.loadings}</p>
          </div>
        </Modal>
      </div>
    )
  }
}
export default connect()(admin);
