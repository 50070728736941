import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import '../css/login.css';
import { RGCaptcha, reset } from 'react-geetest-captcha';
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { Popover, CircularProgress, Typography } from '@material-ui/core';
import Button from '../components/Button';
import { getCode, getLogin } from '../http/api'

const CAPTCHA_NAME = 'demoCaptcha';
const mapDispatchToProps = (dispatch) => ({
    openRoomModal(state) {
        dispatch(toggleRoom(state));
    },
    toggleLogin(state) {
        dispatch(toggleLogin(state));
    },
    openUserDropdown(state) {
        dispatch(toggleUserDropdown(state));
    },
});
const mapStateToProps = state => {
    const { profile } = state.user;
    return {
        loginModalIsOpen: state.modals.loginModalIsOpen,
        account: profile && profile.account,
        name: profile && profile.name
    };
};
const regEmail = new RegExp("[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+$");
const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);
const isEmail = (s) => {
    return /^([a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+$)$/.test(s)
}

@withRouter
class ThirdLogin extends React.Component {
    state = {
        email: "",
        code: "",
        password: "",
        codeShow: true,
        codeTime: 60
    }
    componentDidMount() {

    }

    getEmail = e => {
        this.setState({
            email: e.target.value
        })
    }

    // 获取输入密码
    getPassword = e => {
        this.setState({
            password: e.target.value
        })
    }

    getCode = e => {
        this.setState({
            code: e.target.value
        })
    }

    // 验证码登录
    login = () => {
        console.log(isEmail(this.state.email))
        let params = {
            openid: window.openId,
            code: this.state.code,
            isregister: false
        }
        axios.defaults.withCredentials = true;
        getCode(params).then(res => {
            if (res.data === 1) {
                window.U.Alert('验证成功')
                this.props.toggleLogin(true)
                this.props.history.push('/')
            } else {
                window.U.Alert('验证失败，请检查验证码输入是否正确')
            }
        })
    }

    // 不验证登录
    directlyLogin = () => {
        let params = {
            openid: window.openId,
            isregister: true,
            googleId: '',
            identity: 1,
            college: "",
        }
        getLogin(params).then(res => {
            if (res.data === 1) {
                window.U.Alert('登录成功')
                this.props.toggleLogin(true)
                this.props.history.push('/')
            } else {
                window.U.Alert('登录失败')
            }
        })
    }

    // 获取code 点击倒计时
    time = () => {
        let times = setInterval(() => {
            if (this.state.codeTime === 0) {
                clearInterval(times)
                this.setState({
                    codeTime: 60,
                    codeShow: true
                })
            } else {
                let codeTime = this.state.codeTime
                this.setState({
                    codeTime: --codeTime
                })
            }
        }, 1000);
    }

    getCodes = () => {
        if (!isEmail(this.state.email)) {
            alert('请输入正确邮箱格式');
            return false;
        }
        if (this.state.password.length < 6) {
            alert('请输入大于6位的密码');
            return false;
        }
        let params = {
            openid: window.openId,
            username: this.state.email,
            password: this.state.password,
            googleId: '',
            identity: 1,
            college: "",
        }
        getLogin(params).then(res => {
            if (res.data[0][0].type === 1) {
                window.U.Alert('验证码已发送');
                this.setState({
                    codeShow: false
                }, () => {
                    this.time()
                })
            } else {
                window.U.Alert('验证码发送失败，请检查邮箱和密码是否正确')
            }
        })
    }

    render() {
        const { codeShow, codeTime } = this.state
        return (
            <div className="thirdLogin">
                <div className="contain">
                    <p>绑定邮箱</p>
                    <div className="user_input">
                        <img src={require("../assets/img/email.png")} />
                        <input placeholder="请输入邮箱" onChange={this.getEmail} />
                    </div>
                    <div className="user_input">
                        <img src={require("../assets/img/password.png")} />
                        <input placeholder="请输入密码" type="password" onChange={this.getPassword} />
                    </div>
                    <div className="user_input">
                        <input placeholder="请输入邮箱验证码" className="validation" onChange={this.getCode} />
                        {codeShow ? <Button name="获取邮箱验证码"
                            css={`
                            padding:10px 15px;
                            font-size:12px;
                            float:right;
                        `} onClick={this.getCodes} /> :
                            <Button name={'已发送(' + codeTime + ')'}
                                css={`
                                padding:10px 15px;
                                font-size:12px;
                                float:right;
                                background:#ccc;
                            `} />}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button name="验证进入"
                            css={`
                            margin:25px auto 20px;
                            padding:10px 25px;
                            font-size:16px;
                        `}
                            onClick={this.login}
                        />
                        <Button name="跳过验证"
                            css={`
                            margin:25px auto 20px;
                            padding:10px 25px;
                            font-size:16px;
                        `}
                            onClick={this.directlyLogin}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdLogin);;
