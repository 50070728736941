import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import config from '../config'
import { TextField } from '@material-ui/core'

import _ from '../utils'
// Actions
import {
  lastStep,
  nextStep
} from '../actions/upload'

const Title = styled.h3`
  margin:0;
  padding:0;
  width:100%;
  height:100%;

  font-size:1.7em;
  color:${props=>props.color || '#5F6F85'};
  background-color:${props=>props.backgroundColor};
  display:${props=>props.inline?'inline-block':'block'};
`

const mapStateToProps = (state, props) => {
  return {
    courseId:state.upload.courseId,
    chapters: state.upload.chapters.titles,
    length:state.upload.chapters.length
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleBack(activeStep){
			dispatch(lastStep(activeStep))
		},
		handleNext(activeStep){
			dispatch(nextStep(activeStep))
		},
  }
}

class ChapterUploadPanel extends React.Component {
  constructor(props){
    super(props)
    this.state={
      chapters:this.InitChapterState(),
      length:this.props.length
    }
  }
  ItemDebounced = _.debounce(this.props.getChapterVal || function(){}, 500);
  InitChapterState=()=> {
    let {chapters} = this.props;
    console.log(chapters)
    let ChapterList=[];
    chapters.forEach((currentValue,index) => {
      let obj=Object.assign({},{
        id:(index+1),
        title:currentValue,
        intro:'',//描述
        slideUrl:'',//PPT
        bg:'',//缩略图
        clipUrl:'',//视频
        attachmentUrl:'',//附件
        thumbUrl:'',//缩略图 
        slideUrl:'',//PPT
        clipUrl:'',//视频
        attachmentUrl:'',//附件
        thumbUrl:'',//缩略图
      })
      ChapterList.push(obj)
    });
    this.ItemDebounced(ChapterList)
    return ChapterList
  }
  updateIntro=(e,index)=>{
    let id=index+1
    var value = e.target.value;
    let list=this.state.chapters;
    for(var i = 0;i<list.length;i++){
      var obj=list[i]
      if(obj.id===id){
        obj.intro=value
      }
      this.setState({
        chapters:list
      })
      this.ItemDebounced(list)
    }
  }
  updateBg=(e,index)=>{
    let id=index+1
    var value = e.target.value;
    let list=this.state.chapters;
    for(var i = 0;i<list.length;i++){
      var obj=list[i]
      if(obj.id===id){
        obj.bg=value
      }
      this.setState({
        chapters:list
      })
      this.ItemDebounced(list)
    }
  }
  updateslideUrl=(e,index)=>{
    let id=index+1
    var value = e.target.value;
    let list=this.state.chapters;
    for(var i = 0;i<list.length;i++){
      var obj=list[i]
      if(obj.id===id){
        obj.slideUrl=value
      }
      this.setState({
        chapters:list
      })
      this.ItemDebounced(list)
    }
  }
  updateclipUrl=(e,index)=>{
    let id=index+1
    var value = e.target.value;
    let list=this.state.chapters;
    for(var i = 0;i<list.length;i++){
      var obj=list[i]
      if(obj.id===id){
        obj.clipUrl=value
      }
      this.setState({
        chapters:list
      })
      this.ItemDebounced(list)
    }
  }
  updatethumbUrl=(e,index)=>{
    let id=index+1
    var value = e.target.value;
    let list=this.state.chapters;
    for(var i = 0;i<list.length;i++){
      var obj=list[i]
      if(obj.id===id){
        obj.thumbUrl=value
      }
      this.setState({
        chapters:list
      })
      this.ItemDebounced(list)
    }
  }
  updateattachmentUrl=(e,index)=>{
    let id=index+1
    var value = e.target.value;
    let list=this.state.chapters;
    for(var i = 0;i<list.length;i++){
      var obj=list[i]
      if(obj.id===id){
        obj.attachmentUrl=value
      }
      this.setState({
        chapters:list
      })
      this.ItemDebounced(list)
    }
  }
  reset=()=>{
    this.setState({
      chapters:this.InitChapterState()
    })
  }
  render () {
    if(!this.props.courseId){
      this.props.history.replace(`${config.env.server}course/Newupload`)
    }
    var ChapterList = () => {
      var list = [];
      for(let i = 0; i < this.state.length; i++) {
          list.push(
            <React.Fragment key={i}>
            <br/>
            <Title>章节{i+1}:{this.state.chapters[i].title}</Title>
            <TextField
              label="章节描述"
              value={this.state.chapters[i].intro}
              onChange={(e)=>this.updateIntro(e,i)}
              variant="outlined"
              margin='dense'
              fullWidth={true}
              multiline={true}
              rows={3}
            ></TextField>

            <TextField
              label="章节预览图"
              value={this.state.chapters[i].bg}
              onChange={(e)=>this.updateBg(e,i)}
              variant="outlined"
              margin='dense'
              fullWidth={true}
              multiline={true}
              rows={1}
            ></TextField>
            
            <TextField
              label="课程PPT图片链接"
              variant="outlined"
              fullWidth={true}
              multiline={true}
              margin='dense'
              value={this.state.chapters[i].slideUrl}
              onChange={(e)=>this.updateslideUrl(e,i)}
              rows={4}
            ></TextField>
    
            <TextField
              label="课程视频链接"
              variant="outlined"
              fullWidth={true}
              multiline={true}
              margin='dense'
              value={this.state.chapters[i].clipUrl}
              onChange={(e)=>this.updateclipUrl(e,i)}
              rows={3}
            ></TextField>
    
            <TextField
              label="课程视频对应缩略图链接"
              variant="outlined"
              fullWidth={true}
              multiline={true}
              margin='dense'
              value={this.state.chapters[i].thumbUrl}
              onChange={(e)=>this.updatethumbUrl(e,i)}
              rows={3}
            ></TextField>
    
            <TextField
            label="课程视频对应附件链接"
            variant="outlined"
            fullWidth={true}
            multiline={true}
            margin='dense'
            value={this.state.chapters[i].attachmentUrl}
            onChange={(e)=>this.updateattachmentUrl(e,i)}
            rows={3}
          ></TextField>
            </React.Fragment>
          
          )
      }
      return list
    }
    return(
      <div>
        {ChapterList()}
      </div>
    )
  }
}
//HOC高阶组件使用ref 需要配置forwardRef
export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef:true})(ChapterUploadPanel);
