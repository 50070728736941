import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'


const Wrapper = styled.div`
 
`;

const App = styled.div`
  grid-area:app;
`

const CourseCardContainer = styled.div`
  grid-area:${props => props.areaname};
  display:grid;
  grid-template-columns:1fr 1fr 1fr 1fr;
  grid-gap:30px 20px;
  @media screen and (max-width:1023px){
    grid-template-columns:1fr 1fr;
  }
`

// const mapStateToProps = state => ({
//   banners: state.banners,
//   app: state.app
// });

// const mapDispatchToProps = dispatch => ({
//   fetchBanners(data) {
//     dispatch(setBanners(data));
//   },
//   fetchApps(data) {
//     dispatch(setApp(data));
//   },
//   openUserDropdown(state) {
//     dispatch(toggleUserDropdown(state));
//   },
// });

// const filterProps = list => {
//   const result = [];
//   list.forEach(val => {
//     const { date, __v, _id, ...obj } = val;
//     result.push(obj);
//   });
//   return result;
// };

class Homepage extends React.Component {
  //state = { "bannerList": null, "app": [], loading: true }

  componentDidMount() {

  }

  returnHome(that) {
    that.props.history.push('/');
  }

  // componentWillUnmount = () => {
  //   this.props.openUserDropdown(true)
  // }

  componentWillMount = () => {
    let _this = this
    window.addEventListener("popstate", function (e) {
      _this.props.history.push('/')
    }, false);
  }

  render() {
    const { banners, app, language } = this.props
    return (
      <div>
        <Wrapper areaname={this.props.areaname}>
          <App>
            <div style={{height:'calc(100vh - 135px)',position:'relative',backgroundColor:'rgb(37,110,216)'}}>
              <img src={require('../assets/img/401_banner.png')} style={{position:'absolute',bottom:0,width:'100%'}} alt="" onClick={() => this.returnHome(this)} />
            </div>
          </App>
        </Wrapper>
      </div>
    )
  }
}

export default connect()(Homepage);
