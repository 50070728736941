import React, { Component } from 'react'
import axios from 'axios';
import config from '../config'
import '../css/studentChat.css'

export class studentChat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            lengths: [1, 2, 3, 4, 5],
            users: {},      // 用户信息
            studyStutas: '', // 学生学习进度
            isD: false
        }
    }

    loaddata = () => {
        var _that = this
        if (!window.$("#chatscript")[0]) {
            let script = document.createElement('script');
            script.onload = script.onreadystatechange = function () {
                if (script.ready || !script.readyState || script.readyState === "loaded" || script.readyState === 'complete') // 这是IE的判断语句
                {
                    script.ready = true;
                    var userid = _that.props.userId;
                    axios.get(`${config.env.edu}admin/userinfo/userinfoById/${userid}/`).then(r => {
                        let data = r.data[0][0];
                        data.userid = userid;
                        window.user = data
                        _that.loadio(_that, data);
                    })
                }
            };
            script.id = "chatscript";
            script.type = 'text/javascript';
            script.src = `${config.env.chat}socket.io/socket.io.js`;
            script.async = "async";
            document.body.appendChild(script);
        }
    }

    loadio = (that, user) => {
        if (that.state.socket == null) {
            //初始化加入群聊
            var uid = user.userid; //"a77e9404-efec-11e9-96f9-028edca3b798";// 
            var name = user.alias || user.username;
            var room = that.props.number ? that.props.number : "1";
            var type = 0;
            // setTimeout(function () { that.update(3); }, 2000)
            that.uid = uid;
            that.name = name;
            that.room = room;
            that.type = type;
            console.log(`wss:${config.env.chat}`)
            var socket = window.io(`wss:${config.env.chat}`);
            window.socket = socket;
            //收到服务器发来的join事件时
            socket.on("join", function (states, chatRecord, user) {
                //所有人打印
                // that.join(that, states);
                //直接到指定的已經閱讀位置
                // if (user.type > 0 && user.uid == that.uid) {
                //     that.type = user.type;
                //     var i = 0, j = 0;
                //     fore: for (i = 0; i < that.that.props.data.length; i++) {
                //         for (j = 0; j < that.that.props.data[i].chapterData.length; j++) {
                //             if (user.type > 0) {
                //                 that.that.props.data[i].chapterData[j].isread = true;
                //             }
                //             else {
                //                 break fore;
                //             }
                //             user.type--;
                //         }
                //     }
                //     that.that.props.getDataVideo(j, i);
                // }
                //聊天室前100消息
                if (chatRecord.length != 0) {
                    window.$('.lt_content')[0].innerHTML = "";
                }
                for (var i = 0; i < chatRecord.length; i++) {
                    that.addLine(chatRecord[i].msg, chatRecord[i].name, 1)
                }
            })

            //收到服务器发来的join事件时
            // socket.on("update", function (states) {
            //     that.join(that, states);
            // })
            socket.on("getchatstate", function (state) {
                console.log(state)
                if(state == 1){
                    that.setState({
                        isD: false
                    })
                }else if(state == 0){
                    that.setState({
                        isD: true
                    })
                }else{
                    socket.emit("updatechatstate", 0);
                }
            })

            //接收到服务器发来的message事件
            socket.on("message", function (msg, user) {
                that.addLine(msg, user, 1)
            });

            //退出聊天室处理
            // socket.on("signOut", function (states) {
            //     that.signOut(that, states);
            // })


            window.$('#enter')[0].onclick = function () {
                //solve code
                if (that.isNull(window.$(".sendInput")[0].value)) {
                    window.$(".sendInput")[0].value = "";
                    return;
                }
                var msg = window.$(".sendInput")[0].value;
                socket.emit("message", msg, name, room) //将消息发送给服务器
                window.$(".sendInput")[0].value = "";
                return false //阻止form提交
            }

            //发送昵称给后端
            socket.emit("join", uid, name, room, type);
            socket.emit("getchatstate");
        }
    }

    // 退出
    signOut = (that, states) => {
        that.join(that, states);
    }

    //判断是否为空
    isNull = str => {
        if (str == "") {
            return true
        };
        var regu = "^[ ]+$";
        var re = new RegExp(regu);
        return re.test(str);

    }

    // 进入
    // join = (that, states) => {
    //     var states = states.sort(that.getSortFun('desc', 'type'));
    //     var txt1 = ''
    //     let total = 0
    //     this.props.data.map((x, ind) => {
    //         total = x.chapterData.length + total
    //     })
    //     for (var i = 0; i < states.length; i++) {
    //         txt1 += '<li class="state_con"><div class="state_con_left"><img src="' + require("../assets/img/2.jpg") + '" /><span>' + states[i].name + '</span></div><div class="state_con_right">';
    //         txt1 += `<div style="width:${((states[i].type / total) * 100)}%;"></div>`
    //         txt1 += '</div></li>';
    //     }
    //     if (window.$('.progress_content')[0]) {
    //         window.$('.progress_content')[0].innerHTML = txt1;
    //     }
    // }


    addLine = (msg, user, type) => {
        if (type == 1) {
            var txt1 = "<div class='lt_first'><div class='left'><img src='" + require('../assets/img/toux.png') + "'> </div><div class='right'><div class='name'>" + user + "</div><div class='pContent'>" + msg + "</div></div></div>"
            window.$('.lt_content')[0].innerHTML += txt1;
        }
        else {
            window.$('.lt_content')[0].innerHTML += "<li>" + msg + "</li>";
        }
        window.$(".lt_content")[0].scrollTop = window.$(".lt_content")[0].scrollHeight;
    }

    changeOpenColor = () => {
        this.setState({
            isD: false
        })
        window.socket.emit("updatechatstate", 1);
    }

    changeCloseColor = () => {
        this.setState({
            isD: true
        })
        window.socket.emit("updatechatstate", 0);
    }

    render() {
        const { isD } = this.state
        { if (this.props.userId) { this.loaddata(); } }
        return (
            <div className="ss_container dis_play">
                <div className="lt_body">
                    <div className="lt_title">聊天室</div>
                    <div className="lt_content">
                        {/* <div class="lt_first">
                            <div class="left"><img src={require('../assets/img/toux.png')} /></div>
                            <div class="right">
                                <div class="name">李维新</div>
                                <div class="pContent">这个课程很棒，希望有更多的学生一起参与进来。跟我们，一起共同成长吧！</div>
                            </div>
                        </div>
                  */}
                    </div>
                    {isD ? <span className="closeSay">已关闭聊天室</span> : ""}
                    <div className="bottom">
                        <input type="text" placeholder="请输入内容..." className="sendInput"></input>
                        <button className="sendMessage" id="enter">发送(Enter)</button>
                    </div>
                </div>
                <div className="sc_opClose">
                    <div className={isD ? "sc_open" : "sc_open sc_open_active"} onClick={() => this.changeOpenColor()}>
                        打开聊天室
                </div>
                    <div className={isD ? "sc_close" : "sc_close sc_close_active"} onClick={() => this.changeCloseColor()}>
                        关闭
                </div>
                </div>
            </div>
        )
    }
}


export default studentChat
